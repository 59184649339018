import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import theme from "../../theme";
import CommonButton from "../button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  modalPaper: {
    position: "absolute",
  },
  container: {
    width: "400px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "50px 60px",
    textAlign: "center",
    "& .message-container": {
      fontSize: "22px",
      fontFamily: theme.fontFamily.MARKPROBOLD,
      marginBottom: "2rem",
    },
    "& .button-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

function ConfirmSwitchModal({ openModal, setConfirmation }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={Boolean(openModal)}
      onClose={() => {
        setConfirmation("no");
      }}
    >
      <div style={modalStyle} className={classes.modalPaper}>
        <div className={classes.container}>
          <div className="message-container">
            Are you sure you want to Activate OR Deactivate this account?
          </div>
          <div className="button-container">
            <CommonButton
              type="button"
              text={"Yes"}
              stylevariant="secondary1"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                setConfirmation("yes");
              }}
            />
            <CommonButton
              type="button"
              text={"No"}
              stylevariant="secondary2"
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                setConfirmation("no");
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmSwitchModal;
