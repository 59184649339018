import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  WithdrawApprovalStep1,
  WithdrawApprovalStep2,
  WithdrawApprovalStep3,
} from "../../components/withdrawApprovalSteps";
import { settlePaymentAction, getPaymentInfoAction } from "../dashboard/action";
import history from "../../history";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 0px",
  },
}));

function WithdrawApprovalRequest({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  const settlePaymentMethod = useCallback(
    (data, payment_id) => dispatch(settlePaymentAction(data, payment_id)),
    [dispatch]
  );

  const getPaymentInfoMethod = useCallback(
    (data) => dispatch(getPaymentInfoAction(data)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  useEffect(() => {
    if (match?.params?.payment_id)
      getPaymentInfoMethod(match.params.payment_id);
  }, [getPaymentInfoMethod, match]);

  useEffect(() => {
    const { success, paymentInfo } = dashboardReducer;
    if (success) {
      setStep(2);
    }
    if (paymentInfo?.data?.state === "SETTLED") {
      setStep(2);
    }
  }, [dashboardReducer]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Sanaam - Withdraw Approval Request"}</title>
        <meta
          property="og:title"
          content={"Sanaam - Withdraw Approval Request"}
        />
      </Helmet>
      {step === 1 ? (
        <WithdrawApprovalStep1
          onSubmit={(receipt) =>
            settlePaymentMethod({ receipt }, match?.params?.payment_id)
          }
          // payment_id={match?.params?.payment_id}
          showErrors={true}
        />
      ) : step === 2 ? (
        <WithdrawApprovalStep2 onSubmit={() => setStep(3)} />
      ) : step === 3 ? (
        <WithdrawApprovalStep3 onSubmit={() => history.push("/")} />
      ) : null}
    </div>
  );
}

export default WithdrawApprovalRequest;
