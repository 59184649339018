import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import CommonDropDown from "../dropdown";
import { getCleanMonth, moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  container: {
    width: "360px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "10px",
    padding: "40px 60px",
    maxHeight: "80vh",
    overflow: "auto",
    "&::-webkit-scrollbar-track": {
      marginLeft: "10px",
      background: theme.colors.Periwinkle,
      borderRadius: "0px 10px 10px 0px",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.DodgerBlue,
      borderRadius: "0px 10px 10px 0px",
      "& :hover": {
        background: theme.colors.Periwinkle,
      },
    },

    "&::-webkit-scrollbar": {
      width: 8,
    },
  },
  title: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subTitle: {
    color: theme.colors.DodgerBlue,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "20px",
  },
  block: { marginBottom: "20px" },
  supportText: {
    fontSize: "12px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    marginTop: "15px",
    "& .label": { position: "relative", top: -4, marginLeft: "5px" },
  },
  label: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  value: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  currency: {
    fontSize: "12px",
    position: "relative",
    bottom: "4px",
    marginRight: "5px",
  },
  amountContainer: {
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
    backgroundColor: theme.colors.Zumthor,
    padding: "20px",
    borderRadius: "10px",
  },
  amountLabel: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  amount: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
  buttonContainer: {
    // marginTop: "50px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
  cancelLink: {
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    color: theme.colors.DodgerBlue,
    textAlign: "center",
    "& span": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

var fileUploader;

function SalaryTransferStep0({ employees, datesToSettle, onSubmit, onCancel }) {
  const monthsToSettle = datesToSettle.map((date) => ({
    value: date,
    label: getCleanMonth(date),
  }));

  const classes = useStyles();
  const [selectedMonth, setSelectedMonth] = useState(monthsToSettle[0]);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.subText}>
          Are you sure you want to transfer the following employees' remaining
          balance?
        </div>
        <div className={classes.block}>
          <div className={classes.label} style={{marginBottom: "15px"}}>Employees Names</div>
          {employees.map((employee) => (
            <div key={employee.id} className={classes.value} style={{marginLeft: "15px", marginBottom: "5px"}}>
              {employee.first_name} {employee.last_name}
            </div>
          ))}
        </div>
        <div className={classes.block}>
          <div className={classes.label} style={{marginBottom: "15px"}}>Company Name</div>
            {
              employees.map(employee => employee.company?.name)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((company) => (
                  <div className={classes.value} style={{marginLeft: "15px", marginBottom: "5px"}}>
                    {company}
                  </div>
                ))
            }
        </div>
        <div className={classes.block}>
          <div className={classes.label}>For month</div>
          <div className={classes.value}>
            <div style={{ width: "180px", marginTop: "5px" }}>
              <CommonDropDown
                placeholder="Select Month"
                options={monthsToSettle}
                value={selectedMonth}
                onChange={(value) => {
                  setSelectedMonth(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <CommonButton
            type="button"
            text="Transfer"
            stylevariant="secondary1"
            disabled={!(selectedMonth && employees.length > 0)}
            onClick={() => onSubmit(selectedMonth.value)}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
          <div
            className={classes.cancelLink}
            onClick={() => {
              onCancel();
            }}
          >
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function SalaryTransferStep1({ employee, datesToSettle, onSubmit, onCancel }) {
  const monthsToSettle = datesToSettle.map((summary) => ({
    value: summary.date,
    balance: summary.remaining_salary,
    label: getCleanMonth(summary.date),
  }));

  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(monthsToSettle[0]);

  return (
    <div>
      <div className={classes.container}>
        {/* <div className={classes.title}>Transfer Remaining Balance</div> */}
        <div className={classes.subText}>
          Are you sure you want to transfer the following employee’s remaining
          balance?
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Employee Name</div>
          <div className={classes.value}>
            {employee?.first_name} {employee?.last_name}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Company Name</div>
          <div className={classes.value}>{employee?.company?.name}</div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>For month</div>
          <div className={classes.value}>
            <div style={{ width: "180px", marginTop: "5px" }}>
              <CommonDropDown
                placeholder="Select Month"
                options={monthsToSettle}
                value={selectedMonth}
                onChange={(value) => {
                  setSelectedMonth(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Salary</div>
          <div className={classes.value}>
            <span className={classes.currency}>
              {employee?.company?.currency}
            </span>
            {moneyValue(employee?.profile?.salary)}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Withdrawn</div>
          <div className={classes.value}>
            <span className={classes.currency}>
              {employee?.company?.currency}
            </span>
            {moneyValue(employee?.profile?.withdrawals)}
          </div>
        </div>
        {/* <div className={classes.block}>
          <div className={classes.label}>Leaves</div>
          <div className={classes.value}>
            <span className={classes.currency}>
              {employee?.company?.currency}
            </span>
            {employee?.profile?.leaves
              ? Math.round(employee?.profile?.leaves * 1000) / 1000
              : 0}
          </div>
        </div> */}
        {/* {employee?.deductions?.length
          ? employee.deductions.map((each) => (
              <div className={classes.block} key={each.id}>
                <div className={classes.label}>{each.name}</div>
                <div className={classes.value}>
                  <span className={classes.currency}>
                    {employee?.company?.currency}
                  </span>
                  {each.amount ? Math.round(each.amount * 1000) / 1000 : 0}
                </div>
              </div>
            ))
          : null} */}
        <div className={classes.amountContainer}>
          <div className={classes.amountLabel}>Remaining Balance</div>
          <div className={classes.amount}>
            <span className={classes.currency}>
              {employee?.company?.currency}
            </span>
            {moneyValue(selectedMonth?.balance)}
          </div>
        </div>
        <div className={classes.label} style={{ display: "none" }}>
          Upload transfer bank receipt, will be added to the company records
        </div>
        <div className={classes.supportText} style={{ display: "none" }}>
          <img src="/images/exclamation-mark-gray.png" alt="exclamation-mark" />
          <span className="label">Supported files: Pdf, doc, xlsx</span>
        </div>
        <div className={classes.buttonContainer}>
          <div style={{ display: "none" }}>
            <CommonButton
              stylevariant="secondary2"
              type="button"
              text={"Upload Bank Receipt"}
              onClick={() => {
                fileUploader.click();
              }}
              icon={"/images/upload.png"}
            />
            <input
              type="file"
              ref={(ref) => (fileUploader = ref)}
              style={{ display: "none" }}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              accept="image/*"
            />
            {file?.name ? (
              <span className={classes.fileContainer}>
                <span className={classes.fileName}>{file.name}</span>
                <img
                  src={"/images/cross.png"}
                  alt="cross"
                  className={classes.crossIcon}
                  onClick={(e) => {
                    setFile(null);
                  }}
                />
              </span>
            ) : null}
          </div>

          <CommonButton
            type="button"
            text="Transfer"
            stylevariant="secondary1"
            disabled={!(selectedMonth && selectedMonth?.balance > 0)}
            onClick={() => onSubmit(selectedMonth.value)}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
          <div
            className={classes.cancelLink}
            onClick={() => {
              onCancel();
            }}
          >
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function SalaryTransferStep2({ onSubmit, extraContent, settledMonth = null }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img src="/images/DoneIcon.svg" alt="DoneIcon" />
        </div>
        <div
          style={{
            color: theme.colors.Violet,
            fontSize: "24px",
            fontFamily: theme.fontFamily.MARKPROLIGHT,
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Remaining balance transfer confirmed
          {settledMonth ? ` for ${getCleanMonth(settledMonth)}` : ""}
        </div>
        <div className={classes.buttonContainer}>
          <CommonButton
            type="button"
            text={"Done"}
            stylevariant="secondary1"
            disabled={false}
            onClick={onSubmit}
            style={{ marginBottom: "20px" }}
          />
        </div>
        {extraContent}
      </div>
    </div>
  );
}

export { SalaryTransferStep0, SalaryTransferStep1, SalaryTransferStep2 };
