/* eslint-disable import/no-anonymous-default-export */
import coreApi from "../../lib/coreApi";

export default {
  onBoardCompanyInfo: (cid) => {
    let url = `/onboarding/${cid}`;
    let result = coreApi.GET(url);
    return result;
  },
  onBoardCompanyVerify: ({ cid, data }) => {
    let url = `/onboarding/${cid}`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  getFeesSettings: (country) => {
    let url = `/backoffice/feessettings?country=${country}`;
    let result = coreApi.GET(url);
    return result;
  },
};
