import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { getTransactionHistoryAction } from "../../containers/dashboard/action";
import theme from "../../theme";
import CommonTable from "../table";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  root: {},
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  Tab: {
    position: "relative",
    top: "31px",
    left: "-22px",
    cursor: "pointer",
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px",
      top: `50%`,
      left: `14%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
    },
  },
  currency: {
    fontSize: "8px",
    position: "relative",
    bottom: "3px",
    marginRight: "2px",
  },
  tube: {
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    textAlign: "center",
    padding: "5px 10px",
  },
}));

function TransactionHistory({ employeeDetail }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getTransactionHistoryMethod = useCallback(
    (data) => dispatch(getTransactionHistoryAction(data)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  useEffect(() => {
    if (employeeDetail?.id) {
      getTransactionHistoryMethod({
        page,
        page_size,
        company_id: employeeDetail?.company?.id,
        employee_id: employeeDetail.id,
      });
    }
  }, [employeeDetail, getTransactionHistoryMethod, page, page_size]);

  useEffect(() => {
    const { transactionHistory } = dashboardReducer;
    if (transactionHistory?.count) {
      setTotalPage(Math.ceil(transactionHistory.count / page_size));
    }
  }, [dashboardReducer]);

  return (
    <div className={classes.root}>
      <div className={classes.Tab}>
        <img src={"/images/Tab2.svg"} alt="withDrawTab"></img>
        <span className="tableTitle">Transaction History</span>
      </div>
      {!dashboardReducer?.loading ? (
        <>
          <CommonTable
            columns={[
              {
                Header: "Date",
                accessor: "created_at",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data) => {
                  return data ? moment(data).format("DD/MM/YYYY") : "N/A";
                },
              },
              {
                Header: "Time",
                accessor: "created_at",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data) => {
                  return data ? moment(data).format("h:mm a") : "N/A";
                },
              },
              {
                Header: "Transaction Id",
                accessor: "transaction_id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
              },
              {
                Header: "Transaction Type",
                accessor: "intent",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
              },
              {
                Header: "Amount",
                accessor: "amount",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div>
                      <span className={classes.currency}>
                        {record?.company?.currency}
                      </span>
                      {moneyValue(data)}
                    </div>
                  );
                },
              },
              {
                Header: "Withdraw Fee",
                accessor: "fee",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div>
                      <span className={classes.currency}>
                        {record?.company?.currency}
                      </span>
                      {moneyValue(data)}
                    </div>
                  );
                },
              },
              {
                Header: "Available Amount",
                accessor: "available_amount",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div style={{ fontFamily: theme.fontFamily.MARKPROHEAVY }}>
                      <span className={classes.currency}>
                        {record?.company?.currency}
                      </span>
                      {moneyValue(data)}
                    </div>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "state",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <span
                      className={classes.tube}
                      style={{
                        color:
                          data === "REJECTED"
                            ? "#FF3B59"
                            : data === "PENDING"
                            ? "#000000"
                            : "#22D559",
                        backgroundColor:
                          data === "REJECTED"
                            ? "#FDE2E5"
                            : data === "PENDING"
                            ? "#FFFF00"
                            : "#E4FDEC",
                      }}
                    >
                      {data}
                    </span>
                  );
                },
              },
            ]}
            data={dashboardReducer?.transactionHistory?.data}
          />
          {dashboardReducer?.transactionHistory?.data?.length ? (
            <div className={"pagination"}>
              <Pagination
                page={page}
                count={totalPage}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default TransactionHistory;
