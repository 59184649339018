/* eslint-disable import/no-anonymous-default-export */
import coreApi from "../../lib/coreApi";

export default {
  onBoardEmployeeInfo: ({ cid, eid }) => {
    let url = `/onboarding/${cid}/employees/${eid}`;
    let result = coreApi.GET(url);
    return result;
  },
  onBoardEmployeeVerify: ({ cid, eid, data }) => {
    let url = `/onboarding/${cid}/employees/${eid}`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  getDeductionList: () => {
    let url = `/admin/deductions`;
    let result = coreApi.GET(url);
    return result;
  },
};
