import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import theme from "../../theme";
import CommonButton from "../button";
import Loading from "../loading";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  container: {
    width: "360px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "40px 60px",
  },
  title: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subTitle: {
    color: theme.colors.DodgerBlue,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "20px",
  },
  block: { marginBottom: "20px" },
  supportText: {
    fontSize: "12px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    marginTop: "15px",
    "& .label": { position: "relative", top: -4, marginLeft: "5px" },
  },
  label: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  value: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  amountContainer: {
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
    backgroundColor: theme.colors.Zumthor,
    padding: "20px",
    borderRadius: "10px",
  },
  amountLabel: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  amount: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
  buttonContainer: {
    marginTop: "50px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
}));

var fileUploader;

function WithdrawApprovalStep1({ showErrors, onSubmit }) {
  const classes = useStyles();

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const [file, setFile] = useState(null);

  useEffect(() => {
    if (dashboardReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer]);

  return (
    <div>
      <Loading showLoader={dashboardReducer?.loading} />
      <div className={classes.container}>
        {showErrors && dashboardReducer?.error?.length ? (
          <div className={"error-container"}>
            {dashboardReducer.error.map((each, index) => (
              <div key={index}>{each}</div>
            ))}
          </div>
        ) : null}
        <div className={classes.title}>Confirm Withdraw Transfer</div>
        <div className={classes.subText}>
          The following employee has requested a withdraw:
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Employee Name</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.employee?.name
              ? dashboardReducer.paymentInfo.data.employee.name
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Company Name</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.company?.name
              ? dashboardReducer.paymentInfo.data.company.name
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Withdraw Amount</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.amount
              ? `${moneyValue(dashboardReducer.paymentInfo.data.amount)} ${
                  dashboardReducer?.paymentInfo?.data?.company?.currency
                }`
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Fees</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.fees
              ? `${moneyValue(dashboardReducer.paymentInfo.data.fees)}  ${
                  dashboardReducer?.paymentInfo?.data?.company?.currency
                }`
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Net Amount</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.net
              ? `${moneyValue(dashboardReducer.paymentInfo.data.net)} ${
                  dashboardReducer?.paymentInfo?.data?.company?.currency
                }`
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Total Amount</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.total
              ? `${moneyValue(dashboardReducer.paymentInfo.data.total)} ${
                  dashboardReducer?.paymentInfo?.data?.company?.currency
                }`
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Salary</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.employee?.profile?.salary
              ? `${moneyValue(
                  dashboardReducer.paymentInfo.data.employee.profile.salary
                )} ${dashboardReducer?.paymentInfo?.data?.company?.currency}`
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Date</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.created_at
              ? moment(dashboardReducer.paymentInfo.data.created_at).format(
                  "DD/MM/YYYY"
                )
              : "N/A"}
          </div>
        </div>
        <div className={classes.subTitle}>Bank Account Information</div>
        <div className={classes.block}>
          <div className={classes.label}>Account Holder Name</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.employee?.banks?.length
              ? dashboardReducer.paymentInfo.data.employee.banks.find(
                  (each) => each.is_default
                )?.account_holder
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Bank Name</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.employee?.banks?.length
              ? dashboardReducer.paymentInfo.data.employee.banks.find(
                  (each) => each.is_default
                )?.name
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>Account Number</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.employee?.banks?.length
              ? dashboardReducer.paymentInfo.data.employee.banks.find(
                  (each) => each.is_default
                )?.number
              : "N/A"}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>IBAN</div>
          <div className={classes.value}>
            {dashboardReducer?.paymentInfo?.data?.employee?.banks?.length
              ? dashboardReducer.paymentInfo.data.employee.banks.find(
                  (each) => each.is_default
                )?.iban
              : "N/A"}
          </div>
        </div>
        <div className={classes.amountContainer}>
          <div className={classes.amountLabel}>Withdraw Amount</div>
          <div className={classes.amount}>
            {dashboardReducer?.paymentInfo?.data?.total
              ? `${moneyValue(dashboardReducer.paymentInfo.data.total)} ${
                  dashboardReducer?.paymentInfo?.data?.company?.currency
                }`
              : "N/A"}
          </div>
        </div>
        <div className={classes.label}>
          Upload transfer bank receipt, will be added to the employee’s records
        </div>
        <div className={classes.supportText}>
          <img src="/images/exclamation-mark-gray.png" alt="exclamation-mark" />
          <span className="label">Supported files: JPEG, PNG</span>
        </div>
        <div className={classes.buttonContainer}>
          <div>
            <CommonButton
              stylevariant="secondary2"
              type="button"
              text={"Upload Bank Receipt"}
              onClick={() => {
                fileUploader.click();
              }}
              icon={"/images/upload.png"}
            />
            <input
              type="file"
              ref={(ref) => (fileUploader = ref)}
              style={{ display: "none" }}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              accept="image/*"
            />
            {file?.name ? (
              <span className={classes.fileContainer}>
                <span className={classes.fileName}>{file.name}</span>
                <img
                  src={"/images/cross.png"}
                  alt="cross"
                  className={classes.crossIcon}
                  onClick={(e) => {
                    setFile(null);
                  }}
                />
              </span>
            ) : null}
          </div>

          <CommonButton
            type="button"
            text={"Confirm Transfer"}
            stylevariant="secondary1"
            disabled={
              !dashboardReducer?.paymentInfo?.data?.employee?.name ||
              !file?.name
            }
            onClick={() => {
              if (file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  onSubmit(reader.result);
                };
                reader.onerror = function (error) {
                  console.log("Error: ", error);
                };
              } else {
                onSubmit("");
              }
            }}
            style={{ marginTop: "40px", marginBottom: "20px" }}
          />
        </div>
      </div>
    </div>
  );
}

function WithdrawApprovalStep2({ onSubmit, extraContent }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img src="/images/DoneIcon.svg" alt="DoneIcon" />
        </div>
        <div
          style={{
            color: theme.colors.Violet,
            fontSize: "24px",
            fontFamily: theme.fontFamily.MARKPROLIGHT,
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Withdraw request confirmed
        </div>
        <div className={classes.buttonContainer}>
          <CommonButton
            type="button"
            text={"Done"}
            stylevariant="secondary1"
            disabled={false}
            onClick={onSubmit}
            style={{ marginBottom: "20px" }}
          />
        </div>
        {extraContent}
      </div>
    </div>
  );
}

function WithdrawApprovalStep3({ onSubmit }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div
        style={{
          color: theme.colors.Violet,
          fontSize: "24px",
          fontFamily: theme.fontFamily.MARKPROLIGHT,
          marginBottom: "20px",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        You can get back to this request through the link shared in your email.
      </div>
      <div className={classes.buttonContainer}>
        <CommonButton
          type="button"
          text={"Done"}
          stylevariant="secondary1"
          disabled={false}
          onClick={onSubmit}
          style={{ marginBottom: "20px" }}
        />
      </div>
    </div>
  );
}

export { WithdrawApprovalStep1, WithdrawApprovalStep2, WithdrawApprovalStep3 };
