import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Redirect } from "react-router-dom";
import theme from "../../theme";
import CommonTable from "../table";
import CommonButton from "../button";
import { getCountriesFeesListAction } from "../../containers/dashboard/action";
import Loading from "../loading";
import FormModal from "./modal";
import {
  getCountriesAction,
  getCurrenciesAction,
} from "../../containers/login/action";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  Tab: {
    position: "relative",
    top: "10px",
    left: "-22px",
    cursor: "pointer",
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px 25px",
      top: `50%`,
      left: `15%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
    },
  },
  tableContainer: {
    marginTop: "30px",
  },
  currency: {
    fontSize: "8px",
    position: "relative",
    bottom: "3px",
    marginRight: "2px",
  },
  successTube: {
    color: "#22D559",
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    backgroundColor: "#E4FDEC",
    textAlign: "center",
    padding: "2px 8px",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  editLabel: {
    cursor: "pointer",
    color: theme.colors.DodgerBlue,
    marginRight: "10px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    display: "flex",
    alignItems: "center",
  },
  deleteLabel: {
    cursor: "pointer",
    color: theme.colors.Pomegranate,
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

// let data = [
//   {
//     id: "CA",
//     name: "Canada",
//     currency: ["canadian dollar", "dollar"],
//   },
//   {
//     id: "AE",
//     name: "United Arab Emirates",
//     currency: ["UAE Dirham"],
//   },
//   {
//     id: "CU",
//     name: "Cuban",
//     currency: ["Cuban Peso"],
//   },
//   {
//     id: "PH",
//     name: "Phillipines",
//     currency: ["phillipines peso"],
//   },
// ];
function Countries() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCountriesListMethod = useCallback(
    (data) => dispatch(getCountriesFeesListAction(data)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [groupedCountryFeesList, setGrouedCountryFeesList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  const getCountriesMethod = useCallback(
    () => dispatch(getCountriesAction()),
    [dispatch]
  );
  const getCurrenciesMethod = useCallback(
    () => dispatch(getCurrenciesAction()),
    [dispatch]
  );

  useEffect(() => {
    if (!openModal) {
      getCountriesListMethod({ page, page_size });
      getCountriesMethod();
      getCurrenciesMethod();
    }
  }, [
    openModal,
    getCountriesMethod,
    getCurrenciesMethod,
    getCountriesListMethod,
    page,
    page_size,
  ]);

  useEffect(() => {
    const {
      countryFeesList,
      createCountryFeesSuccess,
      updateCountryFeesSuccess,
    } = dashboardReducer;
    if (createCountryFeesSuccess || updateCountryFeesSuccess) {
      getCountriesListMethod({ page, page_size });
    }
    if (countryFeesList?.count) {
      setTotalPage(Math.ceil(countryFeesList.count / page_size));
    }
  }, [dashboardReducer, page, page_size, getCountriesListMethod]);

  useEffect(() => {
    if (
      dashboardReducer?.error?.length ||
      dashboardReducer?.deleteDeductionError?.length
    ) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    } else if (dashboardReducer?.countryFeesList?.data?.length) {
      let countryFees = dashboardReducer?.countryFeesList?.data;
      let countryNameFees = countryFees.map((fees) => {
        // eslint-disable-next-line
        let country = loginReducer?.countryOptions?.find((item) => {
          if (item.id === fees.country) {
            return true;
          }
        });
        return { ...fees, countryName: country?.name };
      });
      let groupedCountryFeesList = groupBy(countryNameFees, "country");
      let list = [];
      for (const key in groupedCountryFeesList) {
        // let obj = {};
        // key = groupedCountryFeesList[key];
        list.push({ key: groupedCountryFeesList[key] });
      }
      setGrouedCountryFeesList(list);
    }
  }, [dashboardReducer, loginReducer]);

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );
  return JSON.parse(localStorage.getItem("user"))?.is_super ? (
    <div className={classes.root}>
      <Loading
        showLoader={dashboardReducer?.loading || loginReducer?.loading}
      />
      <div className={classes.headerContainer}>
        <div className="title">Countries</div>
        <div>
          <CommonButton
            type="button"
            text={"Set Up Country"}
            stylevariant="secondary4"
            onClick={() => {
              setOpenModal(true);
              setEditData(null);
            }}
            style={{ padding: "5px 40px" }}
            fontSize="18px"
            fontFamily={theme.fontFamily.MARKPROHEAVY}
          />
        </div>
      </div>
      {!openModal && dashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {dashboardReducer.error.map((each, index) => (
            <div key={index}>{each}</div>
          ))}
        </div>
      ) : !openModal && dashboardReducer?.deleteDeductionError?.length ? (
        <div className={"error-container"}>
          {dashboardReducer.deleteDeductionError.map((each, index) => (
            <div key={index}>{each}</div>
          ))}
        </div>
      ) : null}
      {!dashboardReducer?.loading ? (
        <>
          <div className={classes.tableContainer}>
            <CommonTable
              columns={[
                {
                  Header: "Name",
                  accessor: "key",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    return <span>{data[0].countryName}</span>;
                  },
                },
                {
                  Header: "Currencies",
                  accessor: "key",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    let currencies = [];
                    data.forEach((el) => {
                      currencies.push(el.currency);
                    });
                    let element = currencies.join(",");
                    return <span>{element}</span>;
                  },
                },
                {
                  Header: "",
                  accessor: "key",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    return (
                      <div
                        className={classes.editLabel}
                        onClick={() => {
                          setOpenModal(true);
                          setEditData(data);
                        }}
                      >
                        <img
                          src={"/images/editIcon.svg"}
                          style={{ marginRight: "8px", marginBottom: "3px" }}
                          alt="icon"
                        />{" "}
                        Edit Country
                      </div>
                    );
                  },
                },
              ]}
              data={groupedCountryFeesList}
            />

            {dashboardReducer?.countryFeesList?.data?.length ? (
              <div className={"pagination"}>
                <Pagination
                  page={page}
                  count={totalPage}
                  showFirstButton
                  showLastButton
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <FormModal
        openModal={openModal}
        editData={editData}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
      />
    </div>
  ) : (
    <Redirect to="/" />
  );
}

export default Countries;
