import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import CommonDropDown from "../dropdown";
import CommonInput from "../input";
import { getCurrenciesAction } from "../../containers/login/action";
import {
  createExchangeAction,
  updateExchangeAction,
  getExchangeInfoAction,
} from "../../containers/dashboard/action";
let currencyOptions = [];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  modalPaper: {
    position: "absolute",
  },
  container: {
    width: "350px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "50px 60px",
    "& .block": {
      marginBottom: "15px",
      "& .label": { fontSize: "18px", marginBottom: "5px" },
      "& .value": {
        fontSize: "18px",
        fontFamily: theme.fontFamily.MARKPROBOLD,
      },
    },
    "& .textArea": {
      width: "94%",
      backgroundColor: theme.colors.Zumthor,
      color: theme.colors.Violet,
      fontSize: "18px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      borderColor: theme.colors.Zumthor,
      borderRadius: "5px",
      padding: "10px",
      "&:hover": {
        borderColor: theme.colors.DodgerBlue,
        outline: "none",
      },
      "&:focus-visible": {
        borderColor: theme.colors.DodgerBlue,
        outline: "none",
      },
    },
    "& .imageContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
    "& .approvalMsg": {
      color: theme.colors.Violet,
      fontSize: "24px",
      fontFamily: theme.fontFamily.MARKPROLIGHT,
      marginBottom: "40px",
    },
  },
}));

function LeaveModal({ openModal, editId, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCurrenciesMethod = useCallback(
    (data) => dispatch(getCurrenciesAction(data)),
    [dispatch]
  );

  const createExchangeMethod = useCallback(
    (data) => dispatch(createExchangeAction(data)),
    [dispatch]
  );

  const updateExchangeMethod = useCallback(
    (data, exchange_id) => dispatch(updateExchangeAction(data, exchange_id)),
    [dispatch]
  );

  const getExchangeInfoMethod = useCallback(
    (exchange_id) => dispatch(getExchangeInfoAction(exchange_id)),
    [dispatch]
  );

  const [modalStyle] = useState(getModalStyle);

  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [rate, setRate] = useState(0);

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  useEffect(() => {
    if (editId) getExchangeInfoMethod(editId);
  }, [editId, getExchangeInfoMethod]);

  useEffect(() => {
    if (loginReducer?.currencyOptions?.length) {
      for (let i = 0; i < loginReducer?.currencyOptions.length; i++) {
        const each = loginReducer?.currencyOptions[i];
        if (currencyOptions.findIndex((rec) => rec.value === each.id) < 0)
          currencyOptions.push({ value: each.id, label: each.name });
      }
    }
  }, [loginReducer]);

  useEffect(() => {
    const { createExchangeSuccess, updateExchangeSuccess, exchangeInfo } =
      dashboardReducer;
    if (createExchangeSuccess || updateExchangeSuccess) {
      onClose();
      setRate(0);
      setSelectedSource(null);
      setSelectedTarget(null);
    }
    if (exchangeInfo && editId) {
      if (Number(exchangeInfo?.rate) >= 0) {
        setRate(exchangeInfo.rate);
      }
      if (exchangeInfo?.source) {
        setSelectedSource(
          currencyOptions.find((each) => each.value === exchangeInfo.source)
        );
      }
      if (exchangeInfo?.target) {
        setSelectedTarget(
          currencyOptions.find((each) => each.value === exchangeInfo.target)
        );
      }
    }
  }, [dashboardReducer, onClose, editId]);

  useEffect(() => {
    if (openModal) {
      getCurrenciesMethod();
    }
  }, [openModal, getCurrenciesMethod]);

  useEffect(() => {
    if (
      dashboardReducer?.createExchangeError?.length ||
      dashboardReducer?.updateExchangeError?.length
    ) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer]);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        onClose();
        setRate(0);
        setSelectedSource(null);
        setSelectedTarget(null);
      }}
    >
      <div style={modalStyle} className={classes.modalPaper}>
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (editId)
              updateExchangeMethod(
                {
                  source: selectedSource?.value,
                  target: selectedTarget?.value,
                  rate: rate,
                },
                editId
              );
            else
              createExchangeMethod({
                source: selectedSource?.value,
                target: selectedTarget?.value,
                rate: rate,
              });
          }}
        >
          <div className={classes.container}>
            {dashboardReducer?.createExchangeError?.length ? (
              <div className={"error-container"}>
                {dashboardReducer.createExchangeError.map((each, index) => (
                  <div key={index}>{each}</div>
                ))}
              </div>
            ) : dashboardReducer?.updateExchangeError?.length ? (
              <div className={"error-container"}>
                {dashboardReducer.updateExchangeError.map((each, index) => (
                  <div key={index}>{each}</div>
                ))}
              </div>
            ) : null}
            <div className="block">
              <div className="label">Source</div>
              <div className="value">
                <CommonDropDown
                  borderRadius={"4px"}
                  placeholder={"Select Source"}
                  options={currencyOptions}
                  value={selectedSource}
                  onChange={(value) => {
                    setSelectedSource(value);
                  }}
                />
              </div>
            </div>

            <div className="block">
              <div className="label">Target</div>
              <div className="value">
                <CommonDropDown
                  borderRadius={"4px"}
                  placeholder={"Select Target"}
                  options={currencyOptions}
                  value={selectedTarget}
                  onChange={(value) => {
                    setSelectedTarget(value);
                  }}
                />
              </div>
            </div>

            <div className="block">
              <div className="label">Rate</div>
              <div className="value">
                <CommonInput
                  placeholder={`Enter Rate`}
                  type="number"
                  value={rate}
                  allowDecimal={3}
                  onlyPositive={true}
                  min={0}
                  max={100000}
                  onChange={(e) => {
                    setRate(e.target.value);
                  }}
                />
              </div>
            </div>

            <div>
              <CommonButton
                type="submit"
                text={editId ? "Update" : "Create"}
                stylevariant="secondary1"
                disabled={
                  !(rate >= 0) ||
                  !selectedSource?.value ||
                  !selectedTarget?.value ||
                  (selectedSource?.value &&
                    selectedTarget?.value &&
                    selectedSource?.value === selectedTarget?.value)
                }
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />

              <CommonButton
                type="button"
                text={"Cancel"}
                stylevariant="secondary2"
                onClick={() => {
                  setRate(0);
                  setSelectedSource(null);
                  setSelectedTarget(null);
                  onClose();
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default LeaveModal;
