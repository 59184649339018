import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
  },
  labelText: {
    fontSize: "16px",
    color: theme.colors.Violet,
    marginBottom: "5px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
}));

function CommonFormDropDown({
  label,
  error,
  helperText,
  placeholder,
  ...props
}) {
  const classes = useStyles();
  const customStyle = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: theme.colors.Violet,
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: theme.colors.Violet,
        opacity: 0.5,
        fontSize: "14px",
        fontFamily: theme.fontFamily.MARKPRO,
      };
    },
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${
        error ? theme.colors.Pomegranate : theme.colors.Zumthor
      }`,
      borderColor: error ? theme.colors.Pomegranate : theme.colors.Zumthor,
      borderRadius: "4px",
      backgroundColor: error ? theme.colors.White : theme.colors.Zumthor,
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      boxShadow: 0,
      "&:hover": {
        border: `1px solid ${
          error ? theme.colors.Pomegranate : theme.colors.DodgerBlue
        }`,
        borderColor: error ? theme.colors.Pomegranate : theme.colors.DodgerBlue,
        boxShadow: 0,
      },
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: theme.colors.Violet,
        fontSize: "14px",
        fontFamily: theme.fontFamily.MARKPRO,
      };
    },
  };

  return (
    <div className={classes.root}>
      {label && (
        <div className={classes.labelText}>
          {label.charAt(0).toLocaleUpperCase() +
            label.slice(1, label.length).toLocaleLowerCase()}
        </div>
      )}
      <Select
        placeholder={
          placeholder
            ? placeholder.charAt(0).toLocaleUpperCase() +
              placeholder.slice(1, placeholder.length).toLocaleLowerCase()
            : null
        }
        {...props}
        defaultValue={props?.value}
        styles={customStyle}
      />
      {error && helperText ? (
        <div className="error-message">
          {helperText.charAt(0).toLocaleUpperCase() +
            helperText.slice(1, helperText.length).toLocaleLowerCase()}
        </div>
      ) : null}
    </div>
  );
}

export default CommonFormDropDown;
