export const ONBOARD_COMPANY_INFO_ACTION = "ONBOARD_COMPANY_INFO_ACTION";
export const ONBOARD_COMPANY_INFO_LOADING = "ONBOARD_COMPANY_INFO_LOADING";
export const ONBOARD_COMPANY_INFO_SUCCESS = "ONBOARD_COMPANY_INFO_SUCCESS";
export const ONBOARD_COMPANY_INFO_ERROR = "ONBOARD_COMPANY_INFO_ERROR";

export const ONBOARD_COMPANY_VERIFY_ACTION = "ONBOARD_COMPANY_VERIFY_ACTION";
export const ONBOARD_COMPANY_VERIFY_LOADING = "ONBOARD_COMPANY_VERIFY_LOADING";
export const ONBOARD_COMPANY_VERIFY_SUCCESS = "ONBOARD_COMPANY_VERIFY_SUCCESS";
export const ONBOARD_COMPANY_VERIFY_ERROR = "ONBOARD_COMPANY_VERIFY_ERROR";

export const GET_FEES_SETTINGS_ACTION = "GET_FEES_SETTINGS_ACTION";
export const GET_FEES_SETTINGS_LOADING = "GET_FEES_SETTINGS_LOADING";
export const GET_FEES_SETTINGS_SUCCESS = "GET_FEES_SETTINGS_SUCCESS";
export const GET_FEES_SETTINGS_ERROR = "GET_FEES_SETTINGS_ERROR";
