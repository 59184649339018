import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Redirect } from "react-router-dom";
import theme from "../../theme";
import CommonTable from "../table";
import CommonButton from "../button";
import {
  getExchangeListAction,
  deleteExchangeAction,
} from "../../containers/dashboard/action";
import Loading from "../loading";
import FormModal from "./modal";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  Tab: {
    position: "relative",
    top: "10px",
    left: "-22px",
    cursor: "pointer",
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px 25px",
      top: `50%`,
      left: `15%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
    },
  },
  tableContainer: {
    marginTop: "30px",
  },
  currency: {
    fontSize: "8px",
    position: "relative",
    bottom: "3px",
    marginRight: "2px",
  },
  successTube: {
    color: "#22D559",
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    backgroundColor: "#E4FDEC",
    textAlign: "center",
    padding: "2px 8px",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  editLabel: {
    cursor: "pointer",
    color: theme.colors.DodgerBlue,
    marginRight: "10px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
  deleteLabel: {
    cursor: "pointer",
    color: theme.colors.Pomegranate,
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
}));

function Exchanges() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getExchangeListMethod = useCallback(
    (data) => dispatch(getExchangeListAction(data)),
    [dispatch]
  );

  const deleteExchangeMethod = useCallback(
    (exchange_id) => dispatch(deleteExchangeAction(exchange_id)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [openModal, setOpenModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  useEffect(() => {
    if (!openModal) {
      getExchangeListMethod({ page, page_size });
    }
  }, [openModal, getExchangeListMethod, page, page_size]);

  useEffect(() => {
    const {
      exchangeList,
      createExchangeSuccess,
      updateExchangeSuccess,
      deleteExchangeSuccess,
    } = dashboardReducer;
    if (
      createExchangeSuccess ||
      updateExchangeSuccess ||
      deleteExchangeSuccess
    ) {
      getExchangeListMethod({ page, page_size });
    }
    if (exchangeList?.count) {
      setTotalPage(Math.ceil(exchangeList.count / page_size));
    }
  }, [dashboardReducer, page, page_size, getExchangeListMethod]);

  useEffect(() => {
    if (
      dashboardReducer?.error?.length ||
      dashboardReducer?.deleteExchangeError?.length
    ) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer]);

  return JSON.parse(localStorage.getItem("user"))?.is_super ? (
    <div className={classes.root}>
      <Loading
        showLoader={dashboardReducer?.loading || loginReducer?.loading}
      />
      <div className={classes.headerContainer}>
        <div className="title">Exchanges</div>
        <div>
          <CommonButton
            type="button"
            text="Add Exchange"
            stylevariant="primary"
            onClick={() => {
              setOpenModal(true);
              setEditId(null);
            }}
            style={{ padding: "5px 40px" }}
            fontSize="18px"
            fontFamily={theme.fontFamily.MARKPROHEAVY}
          />
        </div>
      </div>
      {!openModal && dashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {dashboardReducer.error.map((each, index) => (
            <div key={index}>{each}</div>
          ))}
        </div>
      ) : !openModal && dashboardReducer?.deleteExchangeError?.length ? (
        <div className={"error-container"}>
          {dashboardReducer.deleteExchangeError.map((each, index) => (
            <div key={index}>{each}</div>
          ))}
        </div>
      ) : null}
      {!dashboardReducer?.loading ? (
        <>
          <div className={classes.tableContainer}>
            <CommonTable
              columns={[
                {
                  Header: "Source",
                  accessor: "source",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                },
                {
                  Header: "Target",
                  accessor: "target",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                },
                {
                  Header: "Rate",
                  accessor: "rate",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                },
                {
                  Header: "Action",
                  accessor: "id",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    return (
                      <div className={classes.flexContainer}>
                        <div
                          className={classes.editLabel}
                          onClick={() => {
                            setOpenModal(true);
                            setEditId(data);
                          }}
                        >
                          Edit
                        </div>
                        <div
                          className={classes.deleteLabel}
                          onClick={() => {
                            deleteExchangeMethod(data);
                          }}
                          style={{ display: "none" }}
                        >
                          Delete
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              data={dashboardReducer?.exchangeList?.data}
            />

            {dashboardReducer?.exchangeList?.data?.length ? (
              <div className={"pagination"}>
                <Pagination
                  page={page}
                  count={totalPage}
                  showFirstButton
                  showLastButton
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <FormModal
        openModal={openModal}
        editId={editId}
        onClose={() => {
          setOpenModal(false);
          setEditId(null);
        }}
      />
    </div>
  ) : (
    <Redirect to="/" />
  );
}

export default Exchanges;
