import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAction } from "../login/action";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";
import theme from "../../theme";
import history from "../../history";
import { Constants } from "../../lib/constant";

const useStyles = makeStyles((innertheme) => ({
  root: {
    margin: "50px 0px",
    [innertheme.breakpoints.up("sm")]: {
      margin: "0px",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
  },
  logo: { width: "100px", height: "76px" },
  welcomeText: {
    marginTop: "10px",
    textAlign: "center",
    fontSize: "28px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
  },
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "80%",
    [innertheme.breakpoints.up("sm")]: {
      width: "370px",
    },
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  bottomContainer: { color: theme.colors.Violet, fontSize: "16px" },
  link: {
    margin: "0px 10px",
    color: theme.colors.Violet,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  copyright: { fontFamily: theme.fontFamily.MARKPRO, textAlign: "center" },
}));

function ResetPassword({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const resetPasswordMethod = (data) => dispatch(resetPasswordAction(data));

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [methodCalled, setMethodCalled] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const { resetPassSuccess } = loginReducer;
    if (
      resetPassSuccess &&
      (resetPassSuccess?.is_admin || resetPassSuccess?.is_super)
    ) {
      history.push("/");
    }
  }, [loginReducer]);

  useEffect(() => {
    if (loginReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [loginReducer]);

  return (
    <Container>
      <div className={classes.root}>
        <Helmet>
          <title>{"Sanaam - Reset password"}</title>
          <meta property="og:title" content={"Sanaam - Reset password"} />
        </Helmet>
        <div className={classes.flexContainer}>
          <img className={classes.logo} src="/images/logo.svg" alt="logo"></img>
        </div>
        <div className={`${classes.flexContainer} ${classes.welcomeText}`}>
          Welcome to Sanaam!
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.formContainer}>
            {methodCalled ? (
              loginReducer?.error?.length ? (
                Array.isArray(loginReducer.error) ? (
                  <div className={"error-container"}>
                    {loginReducer.error.map((each, index) => (
                      <div key={index}>
                        {each === "Resource not found."
                          ? "Token does not exist or has expired."
                          : each}
                      </div>
                    ))}
                  </div>
                ) : null
              ) : loginReducer?.resetPassSuccess?.is_staff ? (
                <div className={"success-container"}>
                  {"Password successfully changed."}
                </div>
              ) : null
            ) : null}
            <div className={classes.formTitle}>Reset password</div>
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsValidated(true);
                if (
                  password &&
                  confirmPassword &&
                  password === confirmPassword &&
                  password.match(Constants.password_regex)
                ) {
                  setMethodCalled(true);
                  resetPasswordMethod({
                    tid: match?.params?.tid,
                    uid: match?.params?.uid,
                    password,
                  });
                }
              }}
            >
              <div style={{ position: "relative" }}>
                <CommonInput
                  label="Password"
                  placeholder="Enter password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  error={
                    isValidated &&
                    (!password ||
                      (password && !password.match(Constants.password_regex)))
                  }
                  helperText={
                    isValidated &&
                    ((!password && "Password may not be blank.") ||
                      (password &&
                        !password.match(Constants.password_regex) &&
                        "Password is invalid."))
                  }
                  disabled={loginReducer.loading}
                  onMouseEnter={() => {
                    setHover(true);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                  }}
                />
                {hover ? (
                  <div className={"tool_tip"}>
                    - Password must be at least 1 character and max 48
                    characters.
                    <br />- Must not contain any spaces in it.
                  </div>
                ) : null}
              </div>
              <CommonInput
                label="Confirm password"
                placeholder="Enter confirm password"
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                error={
                  (isValidated && !confirmPassword) ||
                  (password && confirmPassword && password !== confirmPassword)
                }
                helperText={
                  isValidated && !confirmPassword
                    ? "Confirm password may not be blank."
                    : password &&
                      confirmPassword &&
                      password !== confirmPassword
                    ? "Please enter the same password."
                    : ""
                }
              />
              <CommonButton
                type="submit"
                text="Submit"
                stylevariant="secondary1"
                style={{ marginTop: "10px" }}
                disabled={loginReducer.loading}
                loading={loginReducer.loading}
              />
            </form>
          </div>
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.bottomContainer}>
            <div className={classes.flexContainer}>
              <a className={classes.link} href={window.location.origin}>
                About
              </a>
              <a className={classes.link} href={window.location.origin}>
                Contact us
              </a>
            </div>
            <div className={classes.copyright}>
              Copyright © 2021 Sanaam. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ResetPassword;
