/* eslint-disable import/no-anonymous-default-export */
import apiInstance from "./axiosConfig";

export default {
  GET: async (link) => {
    let { data } = await apiInstance.get(link);
    return data;
  },

  POST: async (link, apiData) => {
    let { data } = await apiInstance.post(link, apiData);
    return data;
  },

  PUT: async (link, apiData) => {
    let { data } = await apiInstance.put(link, apiData);
    return data;
  },

  PATCH: async (link, apiData) => {
    let { data } = await apiInstance.patch(link, apiData);
    return data;
  },

  DELETE: async (link) => {
    let { data } = await apiInstance.delete(link);
    return data;
  },

  FILE_UPLOAD: async (link, apiData) => {
    let formData = new FormData();

    for (var key in apiData) {
      if (apiData.hasOwnProperty(key)) {
        if (key === "files") {
          for (let i = 0; i < apiData[key].length; i++) {
            const file = apiData[key][i];
            formData.append(key, file);
          }
        }
        formData.append(key, apiData[key]);
      }
    }

    let { data } = await apiInstance.post(link, formData);
    return data;
  },
};
