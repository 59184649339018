import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 999999,
    color: "#fff",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    color: theme.colors.DodgerBlue,
  },
}));

function Loading({ showLoader }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={showLoader}>
      <CircularProgress
        className={classes.circularProgress}
        thickness={2}
        size={100}
      />
    </Backdrop>
  );
}

export default Loading;
