import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../theme";
import CommonTable from "../table";
import {downloadFile, getCleanMonth, getIntentDisplay, moneyValue} from "../../utils";
import CommonButton from "../button";
import {getPaymentDownloadAction, resetPaymentDownloadAction} from "../../containers/dashboard/action";

const useStyles = makeStyles(() => ({
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  withdrawAction: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "19px",
      width: "19px",
      filter: "brightness(0) invert(0)",
      cursor: "pointer",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  srOnly: {
    position: "absolute", width: "1px", height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: 0
  }
}));

function TransactionsHistoryTable({
  id,
  setId,
  data,
  setTransactionHistoryFilters,
  transactionHistoryFilters,
  page,
  setPage,
  totalPage,
  paymentsPage,
  setPaymentsPage,
  totalPaymentsPages,
  SetTotalPaymentsPages,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState()

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const getPaymentDownloadMethod = useCallback(
    (company) => dispatch(getPaymentDownloadAction(company)),
    [dispatch]
  );

  const resetPaymentDownloadMethod = useCallback(
    () => dispatch(resetPaymentDownloadAction()),
    [dispatch]
  );

  useEffect(() => {
    if (dashboardReducer?.csv && dashboardReducer?.filename) {
      downloadFile(dashboardReducer.csv, dashboardReducer.filename)
      resetPaymentDownloadMethod()
    }
  }, [
    dashboardReducer,
  ]);

  useEffect(() => {
    if (id >= 0 && document.getElementById(`expand_icon_${id}`))
      document.getElementById(`expand_icon_${id}`).scrollIntoView();
  }, [id]);

  useEffect(() => {
    if (transactionHistoryFilters?.search)  {
      setSearchTerm(transactionHistoryFilters.search)
    }
  }, [transactionHistoryFilters]);

  const onSearchCancel = () => {
    if (transactionHistoryFilters?.search) {
      setTransactionHistoryFilters(current => {
        if (current)  {
          const {search, ...rest} = current;
          return rest;
        }
      });
    }
  }

  return (
    <>
      <CommonTable
        columns={[
          {
            Header: "Company Name",
            accessor: "name",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
          },
          {
            Header: "Employees with transactions",
            accessor: "total_transactions_employees",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data) => {
              return (
                <div className={classes.salary}>
                  {data}
                </div>
              );
            },
          },
          {
            Header: "Total Net Salaries",
            accessor: "net_salaries",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, row) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">{row?.currency}</span>{" "}
                  {moneyValue(data)}
                </div>
              );
            },
          },
          {
            Header: "Total Withdrawn",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, row) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">{row?.currency}</span>
                  {moneyValue(row?.withdrawals)}
                </div>
              );
            },
          },
          {
            Header: "Remaining Balance",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, row, index) => {
              return (
                <div
                  className={classes.withdrawAction}
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    className={classes.salary}
                    style={{
                      fontFamily: theme.fontFamily.MARKPROHEAVY,
                    }}
                  >
                    <span className="currency">{row?.currency}</span>{" "}
                    {moneyValue(row?.balance)}
                  </div>

                  <div>
                    <CommonButton
                      type="button"
                      text="Download"
                      stylevariant="primary"
                      onClick={() => {getPaymentDownloadMethod(row.id)}}
                      style={{ padding: "5px 15px" }}
                      fontSize="12px"
                      fontFamily={theme.fontFamily.MARKPROHEAVY}
                    />
                  </div>

                  <div>
                    <img
                      id={`expand_icon_${index}`}
                      src="/images/chevron_dropdown_date.png"
                      alt="chevron_dropdown_date"
                      onClick={() => {
                        setId((temp) => (temp === index ? null : index));
                        setPaymentsPage(1);
                        SetTotalPaymentsPages(1);
                      }}
                      style={{
                        transform: id === index ? "rotate(180deg)" : "",
                      }}
                    ></img>
                  </div>
                </div>
              );
            },
          },
        ]}
        data={data}
        showSubTableFor={id}
        subTableContent={() => (
          <CommonTable
            search={{
              term: searchTerm,
              placeholder: "Search by name, email, intent or state",
              onChange: (e) => setSearchTerm(e.target.value),
              onSubmit: () => setTransactionHistoryFilters({...transactionHistoryFilters, search: searchTerm}),
              onCancel: () => onSearchCancel()
            }}
            columns={[
              {
                Header: "Employee Name",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div>
                      {row?.employee.name}
                      <span className="inactive-label">
                        {row?.employee.is_active ? "" : " (Inactive)"}
                      </span>
                    </div>
                  );
                },
              },
              {
                Header: "Intent",
                accessor: "intent",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data) => getIntentDisplay(data),
              },
              {
                Header: "State",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row, index) => {
                  return (
                    <div
                      style={{
                        fontFamily: theme.fontFamily.MARKPROHEAVY,
                        flex: 0.5,
                      }}
                    >
                      {row?.state}
                    </div>
                  );
                },
              },
              {
                Header: "Created At",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row, index) => {
                  return (
                    <div>
                      {
                        row?.intent === 'TRANSFER'
                          ? (
                            <>
                              <span className={classes.srOnly}>{new Date(row?.created_at).toLocaleDateString()}</span>
                              <span>{getCleanMonth(new Date(row?.created_at))}</span>
                            </>
                          )
                          : new Date(row?.created_at).toLocaleDateString()
                      }
                    </div>
                  );
                },
              },
              {
                Header: "Settled At",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row, index) => {
                  return (
                    <div
                      style={{
                        fontFamily: theme.fontFamily.MARKPROHEAVY,
                        flex: 0.5,
                      }}
                    >
                      {row?.settled_at ? new Date(row?.settled_at).toLocaleDateString() : ''}
                    </div>
                  );
                },
              },
              {
                Header: "Declined At",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row, index) => {
                  return (
                    <div
                      style={{
                        fontFamily: theme.fontFamily.MARKPROHEAVY,
                        flex: 0.5,
                      }}
                    >
                      {row?.declined_at ? new Date(row?.declined_at).toLocaleDateString() : ''}
                    </div>
                  );
                },
              },
              {
                Header: "Net",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>
                      {moneyValue(row?.net)}
                    </div>
                  );
                },
              },
              {
                Header: "Fees",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{row?.company?.currency}</span>
                      {moneyValue(row?.fees)}
                    </div>
                  );
                },
              },
              {
                Header: "Total",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, row) => {
                  return (
                    <div
                      className={classes.salary}
                      style={{
                        fontFamily: theme.fontFamily.MARKPROHEAVY,
                        flex: 0.5,
                      }}
                    >
                      <span className="currency">{row?.company?.currency}</span>
                      {moneyValue(row?.total)}
                    </div>
                  );
                },
              },
            ]}
            data={
              dashboardReducer?.paymentList?.data?.length
                ? dashboardReducer.paymentList.data
                : []
            }
            subTable={true}
          />
        )}
        subTableContentPagination={() =>
          dashboardReducer?.paymentList?.data?.length ? (
            <div className={"pagination"} style={{ marginBottom: "1rem" }}>
              <Pagination
                page={paymentsPage}
                count={totalPaymentsPages}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setPaymentsPage(value);
                }}
              />
            </div>
          ) : null
        }
      />
      {data?.length ? (
        <div className={"pagination"}>
          <Pagination
            page={page}
            count={totalPage}
            showFirstButton
            showLastButton
            onChange={(event, value) => {
              setPage(value);
              setId(null);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default TransactionsHistoryTable;
