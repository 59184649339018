import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import CommonSwitch from "../switch";
import theme from "../../theme";
import EmployeeList from "./employeeList";
import CommonDropDown from "../dropdown";
import { getEmployeeListAction } from "../../containers/dashboard/action";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  detailContainer: {
    marginTop: "30px",
    "& .block": {
      cursor: "pointer",
      background: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "15px",
      padding: "30px",
      display: "flex",
      marginBottom: "15px",
      "& .left": {
        flex: "0.6",
        display: "flex",
        "& .logoContainer": {
          height: "85px",
          width: "85px",
          borderRadius: "50%",
          border: `1px solid ${theme.colors.Zumthor}`,
          backgroundColor: theme.colors.White,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          },
        },
        "& .comInfoContainer": {
          marginLeft: "25px",
          color: theme.colors.Violet,
          width: "60%",
          "& .comName": {
            fontSize: "22px",
            fontFamily: theme.fontFamily.MARKPROBOLD,
            marginBottom: "10px",
          },
          "& .flexContainer": {
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontFamily: theme.fontFamily.MARKPRO,
            "& .changeLink": {
              color: theme.colors.DodgerBlue,
              fontFamily: theme.fontFamily.MARKPRO,
              marginLeft: "5px",
              display: "none",
            },
            "& .label": { fontFamily: theme.fontFamily.MARKPRO, flex: 0.5 },
            "& .val": {
              fontFamily: theme.fontFamily.MARKPROBOLD,
              flex: 0.5,
            },
          },
        },
      },
      "& .right": {
        flex: "0.4",
        display: "flex",
        // alignItems: "center",
        justifyContent: "space-between",
        "& .empNumberContainer": {
          color: theme.colors.Violet,
          "& .label": {
            fontSize: "12px",
            fontFamily: theme.fontFamily.MARKPRO,
          },
          "& .val": {
            fontSize: "16px",
            fontFamily: theme.fontFamily.MARKPROHEAVY,
            "& .currency": {
              fontSize: "10px",
              position: "relative",
              bottom: "4px",
              marginRight: "2px",
            },
          },
        },
        "& .borderContainer": {
          border: `1px solid ${theme.colors.Zumthor}`,
          height: "215px",
          margin: "0px 10px",
        },
      },
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
    float: "right",
    "& .title": {
      color: theme.colors.DodgerBlue,
      fontSize: "16px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      marginRight: 20,
    },
  },
}));

function CompanyEmployeeDetail({ setSearchEmployee, updateEmployeeMethod }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getEmployeeListMethod = useCallback(
    (data) => dispatch(getEmployeeListAction(data)),
    [dispatch]
  );

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  useEffect(() => {
    if (loginReducer?.companyInformation?.id) {
      getEmployeeListMethod({
        page,
        page_size,
        company_id: loginReducer.companyInformation.id,
      });
    }
  }, [loginReducer, getEmployeeListMethod, page, page_size]);

  useEffect(() => {
    if (dashboardReducer?.employeeList?.count) {
      setTotalPage(Math.ceil(dashboardReducer.employeeList.count / page_size));
    }
  }, [dashboardReducer]);

  return (
    <div className={classes.detailContainer}>
      <div className="block">
        <div className="left">
          <div className="logoContainer">
            <img
              src={
                loginReducer?.companyInformation?.image
                  ? loginReducer.companyInformation.image
                  : "/images/no-image-found.png"
              }
              alt="comLogo"
            ></img>
          </div>
          <div className="comInfoContainer">
            <div className="comName">
              {loginReducer?.companyInformation?.name}
            </div>
            {loginReducer?.companyInformation?.admins?.length ? (
              <>
                <div className="flexContainer">
                  <div className="label">Admin:</div>
                  <div className="val">
                    <span>
                      {loginReducer.companyInformation.admins[0].first_name}{" "}
                      {loginReducer.companyInformation.admins[0].last_name}
                    </span>
                    <a href="#" className="changeLink">
                      Change
                    </a>
                  </div>
                </div>

                <div className="flexContainer" style={{ marginTop: "10px" }}>
                  <div className="label">Email:</div>
                  <div className="val">
                    <span>
                      {loginReducer.companyInformation.admins[0].email}
                    </span>
                  </div>
                </div>

                <div className="flexContainer" style={{ marginTop: "10px" }}>
                  <div className="label">Phone Number:</div>
                  <div className="val">
                    {loginReducer.companyInformation.admins[0]?.profile?.phone}
                  </div>
                </div>
              </>
            ) : null}
            <div className="flexContainer" style={{ marginTop: "10px" }}>
              <div className="label">Country:</div>
              <div className="val">
                {loginReducer.companyInformation?.country &&
                loginReducer?.countryOptions?.length
                  ? loginReducer.countryOptions.find(
                      (each) =>
                        each.id === loginReducer.companyInformation.country
                    ).name
                  : ""}
              </div>
            </div>
            <div className="flexContainer" style={{ marginTop: "10px" }}>
              <div className="label">Currency:</div>
              <div className="val">
                {loginReducer.companyInformation?.currency &&
                loginReducer?.currencyOptions?.length
                  ? loginReducer.currencyOptions.find(
                      (each) =>
                        each.id === loginReducer.companyInformation.currency
                    ).name
                  : ""}
              </div>
            </div>
            <div className="flexContainer" style={{ marginTop: "10px" }}>
              <div className="label">Time zone:</div>
              <div className="val">
                {loginReducer.companyInformation?.timezone &&
                loginReducer?.timeZoneOptions?.length
                  ? loginReducer.timeZoneOptions.find(
                      (each) =>
                        each.id === loginReducer.companyInformation.timezone
                    ).name
                  : ""}
              </div>
            </div>
            <div className="flexContainer" style={{ display: "none" }}>
              <div className="label">Account:</div>
              <div className="val">
                <span style={{ fontSize: "12px" }}>Activated</span>
                <CommonSwitch name="checkedA" checked={true} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div>
            <div
              className="empNumberContainer"
              style={{ marginBottom: "20px" }}
            >
              <div className="label">Total Number of Employees</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {loginReducer.companyInformation?.total_employees}
              </div>
            </div>
            <div
              className="empNumberContainer"
              style={{ marginBottom: "20px" }}
            >
              <div className="label">Total Number of Registered Employees</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {loginReducer.companyInformation?.registered_employees}
              </div>
            </div>
            <div className="empNumberContainer">
              <div className="label">Total Monthly Net Salaries</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>
                  {loginReducer.companyInformation?.currency}
                </span>
                {moneyValue(loginReducer.companyInformation?.net_salaries)}
              </div>
            </div>
          </div>
          <div className="borderContainer" />
          <div>
            <div
              className="empNumberContainer"
              style={{ marginBottom: "20px" }}
            >
              <div className="label">
                Days designated as <br />
                Work Days
              </div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {loginReducer.companyInformation?.days}
                {loginReducer.companyInformation?.formula
                  ? loginReducer.companyInformation.formula === "CALENDAR-DAYS"
                    ? "Calendar Days"
                    : loginReducer.companyInformation.formula ===
                      "BUSINESS-DAYS"
                    ? "Working Days"
                    : ""
                  : ""}
              </div>
            </div>
            {loginReducer?.companyInformation?.workdays?.length ? (
              <div className="empNumberContainer">
                <div className="label">Working Days</div>
                <div className="val" style={{ padding: "10px 0px" }}>
                  {loginReducer.companyInformation.workdays.join(", ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.filterContainer}>
        <div className="title">Search by</div>
        <div
          style={{ width: "200px" }}
          onClick={(e) => {
            e.preventDefault();
            setSearchEmployee(true);
          }}
        >
          <CommonDropDown placeholder={"Search Employee"} options={[]} />
        </div>
      </div>
      {!dashboardReducer?.loading ? (
        <div className="tableContainer">
          <EmployeeList
            data={dashboardReducer?.employeeList?.data}
            companyInformation={loginReducer?.companyInformation}
            page={page}
            setPage={setPage}
            totalPage={totalPage}
            updateEmployeeMethod={updateEmployeeMethod}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CompanyEmployeeDetail;
