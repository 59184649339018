import createReducer from "../../reducers/createReducer";
import {
  ONBOARD_COMPANY_INFO_LOADING,
  ONBOARD_COMPANY_INFO_SUCCESS,
  ONBOARD_COMPANY_INFO_ERROR,
  ONBOARD_COMPANY_VERIFY_LOADING,
  ONBOARD_COMPANY_VERIFY_SUCCESS,
  ONBOARD_COMPANY_VERIFY_ERROR,
  GET_FEES_SETTINGS_LOADING,
  GET_FEES_SETTINGS_SUCCESS,
  GET_FEES_SETTINGS_ERROR,
} from "./types";

let initialState = {
  loading: false,
  onBoardCompanyInfo: null,
  feesSettings: null,
  success: null,
  error: null,
};

export const onBoardCompanyReducer = createReducer(initialState, {
  [ONBOARD_COMPANY_INFO_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      onBoardCompanyInfo: null,
      success: null,
      error: null,
    });
  },
  [ONBOARD_COMPANY_INFO_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardCompanyInfo: action.payload,
      error: null,
    });
  },
  [ONBOARD_COMPANY_INFO_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardCompanyInfo: null,
      error: action.payload,
    });
  },
  [ONBOARD_COMPANY_VERIFY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [ONBOARD_COMPANY_VERIFY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [ONBOARD_COMPANY_VERIFY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [GET_FEES_SETTINGS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      feesSettings: null,
      error: null,
    });
  },
  [GET_FEES_SETTINGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      feesSettings: action.payload,
      error: null,
    });
  },
  [GET_FEES_SETTINGS_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      feesSettings: null,
      error: action.payload,
    });
  },
});
