import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonButton from "../../components/button";
import theme from "../../theme";
import history from "../../history";

const useStyles = makeStyles((innertheme) => ({
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px 30px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "70%",
    [innertheme.breakpoints.up("sm")]: {
      width: "400px",
      padding: "40px 60px",
    },
  },
  bingoImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "40px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
    marginBottom: "10px",
  },
}));

function CompanyRegistrationStep4() {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <div className={classes.bingoImage}>
        <img src={"/images/success_bingo.svg"} alt="success_bingo" />
      </div>
      <div className={classes.subText} style={{ textAlign: "center" }}>
        Onboarding successful!
        <br /> Email is sent to the Company Admin.
      </div>
      <div style={{ marginTop: "50px" }}>
        <CommonButton
          type="button"
          text={"Done"}
          stylevariant="secondary1"
          onClick={() => {
            history.push("/");
          }}
        />
      </div>
    </div>
  );
}

export default CompanyRegistrationStep4;
