import React, { useState } from "react";
import { Modal, TextareaAutosize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../../components/button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  modalPaper: {
    position: "absolute",
  },
  container: {
    width: "350px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "50px 60px",
    "& .title": { fontSize: "18px", marginBottom: "30px" },
    "& .block": {
      marginBottom: "15px",
      "& .label": { fontSize: "18px" },
      "& .value": {
        fontSize: "18px",
        fontFamily: theme.fontFamily.MARKPROBOLD,
      },
    },
    "& .textArea": {
      width: "94%",
      color: theme.colors.Violet,
      fontSize: "18px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      borderColor: "#C5DAFE",
      borderRadius: "5px",
      padding: "10px",
      "&:focus-visible": {
        borderColor: theme.colors.DodgerBlue,
        outline: "none",
      },
    },
    "& .imageContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
    "& .approvalMsg": {
      color: theme.colors.Violet,
      fontSize: "24px",
      fontFamily: theme.fontFamily.MARKPROLIGHT,
      marginBottom: "40px",
    },
  },
}));

function AbsenseModal({ openModal, onClose, onSubmit, setTableData }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [notes, setNotes] = useState("");
  const [step, setStep] = useState(1);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        onClose();
        setStep(1);
        setNotes("");
      }}
    >
      <div style={modalStyle} className={classes.modalPaper}>
        <div className={classes.container}>
          {step === 1 ? (
            <>
              <div className="title">
                Are you sure you want to confirm the following employee’s
                absense for today?
              </div>
              <div className="block">
                <div className="label">Employee Name</div>
                <div className="value">Omar Bassem</div>
              </div>
              <div className="block">
                <div className="label">Company Name</div>
                <div className="value">Sitech</div>
              </div>
              <div className="block">
                <div className="label">Attendance Date</div>
                <div className="value">23/06/2021</div>
              </div>
              <div style={{ margin: "20px 0px" }}>
                <TextareaAutosize
                  className="textArea"
                  minRows={5}
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                    setTableData((temp) =>
                      temp.map((each) => {
                        if (each.id === openModal) {
                          each.notes = e.target.value;
                        }
                        return each;
                      })
                    );
                  }}
                  placeholder="Add Notes"
                />
              </div>
            </>
          ) : (
            <>
              <div className="imageContainer">
                <img src="/images/DoneIcon.svg" alt="DoneIcon" />
              </div>
              <div className="approvalMsg">Overtime request is approved</div>
            </>
          )}
          <div>
            <CommonButton
              type="button"
              text={"Save"}
              stylevariant="secondary1"
              disabled={step === 1 && !notes}
              onClick={() => {
                if (step === 1) {
                  setStep(2);
                  onSubmit();
                } else {
                  setStep(1);
                  setNotes("");
                  onClose();
                }
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AbsenseModal;
