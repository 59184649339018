import { put, takeLatest, call } from "redux-saga/effects";
import {
  ONBOARD_EMPLOYEE_INFO_ACTION,
  ONBOARD_EMPLOYEE_INFO_LOADING,
  ONBOARD_EMPLOYEE_INFO_SUCCESS,
  ONBOARD_EMPLOYEE_INFO_ERROR,
  ONBOARD_EMPLOYEE_VERIFY_ACTION,
  ONBOARD_EMPLOYEE_VERIFY_LOADING,
  ONBOARD_EMPLOYEE_VERIFY_SUCCESS,
  ONBOARD_EMPLOYEE_VERIFY_ERROR,
  DEDUCTION_LIST_ACTION,
  DEDUCTION_LIST_LOADING,
  DEDUCTION_LIST_SUCCESS,
  DEDUCTION_LIST_ERROR,
} from "./types";
import Api from "./api";
import { Constants } from "../../lib/constant";

function* onBoardEmployeeInfoSaga(data) {
  yield put({ type: ONBOARD_EMPLOYEE_INFO_LOADING });
  try {
    let res = yield call(Api.onBoardEmployeeInfo, data.payload);

    if (res && res.message) {
      yield put({ type: ONBOARD_EMPLOYEE_INFO_ERROR, payload: res.message });
    } else if (res && res.data) {
      yield put({ type: ONBOARD_EMPLOYEE_INFO_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: ONBOARD_EMPLOYEE_INFO_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: ONBOARD_EMPLOYEE_INFO_ERROR, payload: error.message });
  }
}

function* onBoardEmployeeVerifySaga(data) {
  yield put({ type: ONBOARD_EMPLOYEE_VERIFY_LOADING });
  try {
    let res = yield call(Api.onBoardEmployeeVerify, data.payload);

    if (res && res.message) {
      yield put({ type: ONBOARD_EMPLOYEE_VERIFY_ERROR, payload: res.message });
    } else if (res && res.data) {
      yield put({ type: ONBOARD_EMPLOYEE_VERIFY_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: ONBOARD_EMPLOYEE_VERIFY_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: ONBOARD_EMPLOYEE_VERIFY_ERROR, payload: error.message });
  }
}

function* getDeductionListSaga() {
  yield put({ type: DEDUCTION_LIST_LOADING });
  try {
    let res = yield call(Api.getDeductionList);

    if (res && res.message) {
      yield put({ type: DEDUCTION_LIST_ERROR, payload: res.message });
    } else if (res && res.data) {
      yield put({ type: DEDUCTION_LIST_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: DEDUCTION_LIST_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: DEDUCTION_LIST_ERROR, payload: error.message });
  }
}

function* watchEmployeeRegistrationAsync() {
  yield takeLatest(ONBOARD_EMPLOYEE_INFO_ACTION, onBoardEmployeeInfoSaga);
  yield takeLatest(ONBOARD_EMPLOYEE_VERIFY_ACTION, onBoardEmployeeVerifySaga);
  yield takeLatest(DEDUCTION_LIST_ACTION, getDeductionListSaga);
}

export default watchEmployeeRegistrationAsync;
