import {
  ONBOARD_EMPLOYEES_ACTION,
  INVITE_EMPLOYEES_ACTION,
  UPDATE_EMPLOYEE_ACTION,
  OVERTIME_LIST_ACTION,
  GET_OVERTIME_ACTION,
  APPROVE_OVERTIME_ACTION,
  REJECT_OVERTIME_ACTION,
  LEAVE_LIST_ACTION,
  CREATE_LEAVE_ACTION, CREATE_PAYSTACK_PROFILE_ACTION,
} from "./types";

export const onBoardEmployeesAction = (data) => ({
  type: ONBOARD_EMPLOYEES_ACTION,
  payload: data,
});

export const inviteEmployeesAction = (data) => ({
  type: INVITE_EMPLOYEES_ACTION,
  payload: data,
});

export const updateEmployeeAction = (data, employee_id) => ({
  type: UPDATE_EMPLOYEE_ACTION,
  payload: data,
  employee_id,
});

export const createPayStackProfileAction = (data) => ({
  type: CREATE_PAYSTACK_PROFILE_ACTION,
  payload: data,
});

export const getOvertimeListAction = (data) => ({
  type: OVERTIME_LIST_ACTION,
  payload: data,
});

export const getOvertimeInfoAction = (overtime_id) => ({
  type: GET_OVERTIME_ACTION,
  overtime_id: overtime_id,
});

export const approveOvertimeAction = (overtime_id, data) => ({
  type: APPROVE_OVERTIME_ACTION,
  overtime_id: overtime_id,
  payload: data,
});

export const rejectOvertimeAction = (overtime_id, data) => ({
  type: REJECT_OVERTIME_ACTION,
  overtime_id: overtime_id,
  payload: data,
});

export const getLeaveListAction = (data) => ({
  type: LEAVE_LIST_ACTION,
  payload: data,
});

export const createLeaveAction = (data) => ({
  type: CREATE_LEAVE_ACTION,
  payload: data,
});
