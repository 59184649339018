import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import theme from "../../theme";
import history from "../../history";

const useStyles = makeStyles(() => ({
  root: {
    width: "230px",
    backgroundColor: theme.colors.Zumthor,
    height: "calc(100vh - 145px)",
    padding: "30px 0px",
    position: "fixed",
  },
  tab: {
    paddingLeft: "35px",
    color: theme.colors.DodgerBlueLight,
    "&.Mui-selected": {
      backgroundColor: theme.colors.DodgerBlueLight,
      color: theme.colors.White,
    },
    "& .MuiListItemIcon-root": { minWidth: "35px" },
    "& .MuiListItemText-primary": {
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
    },
  },
  soonText: {
    textTransform: "uppercase",
    position: "absolute",
    backgroundColor: theme.colors.Violet,
    color: theme.colors.MintGreen,
    padding: "1px 2px",
    left: 130,
    top: 15,
    fontSize: "7px",
    borderRadius: "2px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    opacity: 1,
  },
}));

function Sidebar({ baseURL, tabs, activeTab }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List>
        {tabs.map((each, index) => (
          <ListItem
            button
            key={index}
            className={classes.tab}
            selected={activeTab === each.name}
            onClick={() => {
              history.push(`${baseURL}/${each.name}`);
            }}
            disabled={!each.isActive}
          >
            <ListItemIcon>
              <img
                src={`/images/${each.icon}`}
                alt={each.icon}
                style={{
                  filter:
                    activeTab === each.name ? "brightness(0) invert(1)" : "",
                  height: "25px",
                  width: "25px",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={each.name}
              style={{ opacity: each.comingSoon ? 0.24 : 1 }}
            />
            {each.comingSoon ? (
              <span className={classes.soonText}>Soon</span>
            ) : null}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
