import React, { useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
  },
  labelText: {
    fontSize: "16px",
    color: theme.colors.Violet,
    marginBottom: "5px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
}));

const CustomTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      fontSize: "14px",
      fontFamily: theme.fontFamily.MARKPRO,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.colors.Zumthor,
      "&.Mui-disabled": {
        backgroundColor: theme.colors.Gallery,
      },
      "&.Mui-focused": {
        backgroundColor: theme.colors.White,
      },
      "&.Mui-error": {
        backgroundColor: theme.colors.White,
      },
      "& fieldset": {
        borderColor: theme.colors.Zumthor,
      },
      "&:hover fieldset": {
        borderColor: theme.colors.DodgerBlueLight,
      },
      "&.Mui-disabled fieldset": {
        borderColor: theme.colors.Gallery,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.colors.DodgerBlueLight,
      },
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
})(TextField);

function CommonInput({
  label,
  onChange,
  allowDecimal,
  min,
  max,
  onlyPositive,
  placeholder,
  helperText,
  ...props
}) {
  const classes = useStyles();
  const inputEl = useRef(null);

  useEffect(() => {
    if (props.error && helperText && inputEl?.current) {
      inputEl.current.scrollIntoView(false);
    }
  }, [props, helperText]);

  return (
    <div className={classes.root}>
      {label && (
        <div className={classes.labelText}>
          {label.charAt(0).toLocaleUpperCase() +
            label.slice(1, label.length).toLocaleLowerCase()}
        </div>
      )}
      <CustomTextField
        autoComplete={props?.type === "password" ? "new-password" : "off"}
        label=""
        variant="outlined"
        placeholder={
          placeholder
            ? placeholder.charAt(0).toLocaleUpperCase() +
              placeholder.slice(1, placeholder.length).toLocaleLowerCase()
            : null
        }
        fullWidth
        ref={inputEl}
        focused={props.error && helperText ? true : false}
        helperText={
          helperText
            ? helperText.charAt(0).toLocaleUpperCase() +
              helperText.slice(1, helperText.length).toLocaleLowerCase()
            : null
        }
        onKeyDown={(e) => {
          if (
            props.type &&
            props.type === "number" &&
            (e.key === "e" ||
              e.key === "-" ||
              e.key === "+" ||
              (e.key === "." && !allowDecimal))
          ) {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          let e = { ...event };
          if (allowDecimal) {
            if (
              !e.target.value ||
              (e.target.value &&
                (!onlyPositive || Number(e.target.value) >= 0) &&
                (min === null ||
                  min === undefined ||
                  min === "" ||
                  Number(e.target.value) >= min) &&
                (max === null ||
                  max === undefined ||
                  max === "" ||
                  Number(e.target.value) <= max))
            ) {
              if (e.target.value.indexOf(".") >= 0) {
                const decimals = e.target.value.substring(
                  e.target.value.lastIndexOf(".") + 1,
                  e.target.value.length
                );
                if (e.target.value.indexOf(".") === 0) {
                  e.target.value = "0" + e.target.value;
                }
                if (decimals?.length > allowDecimal) {
                  e.target.value = e.target.value.substring(
                    0,
                    e.target.value.lastIndexOf(".") + 1 + allowDecimal
                  );
                }
              }
              onChange(e);
            }
          } else if (props?.type === "number") {
            if (
              !e.target.value ||
              (e.target.value &&
                (min === null ||
                  min === undefined ||
                  min === "" ||
                  Number(e.target.value) >= min) &&
                (max === null ||
                  max === undefined ||
                  max === "" ||
                  Number(e.target.value) <= max))
            ) {
              e.target.value = parseInt(e.target.value, 10);
              onChange(e);
            }
          } else {
            onChange(e);
          }
        }}
        {...props}
      />
    </div>
  );
}

export default CommonInput;
