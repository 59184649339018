import {
  ONBOARD_EMPLOYEE_INFO_ACTION,
  ONBOARD_EMPLOYEE_VERIFY_ACTION,
  DEDUCTION_LIST_ACTION,
} from "./types";

export const onBoardEmployeeInfoAction = (data) => ({
  type: ONBOARD_EMPLOYEE_INFO_ACTION,
  payload: data,
});

export const onBoardEmployeeVerifyAction = (data) => ({
  type: ONBOARD_EMPLOYEE_VERIFY_ACTION,
  payload: data,
});

export const getDeductionListAction = () => ({
  type: DEDUCTION_LIST_ACTION,
});
