/* eslint-disable import/no-anonymous-default-export */
import coreApi from "../../lib/coreApi";

export default {
  onBoardEmployees: (data) => {
    let url = `/admin/employees/onboard`;
    let result = coreApi.POST(url, data);
    return result;
  },
  inviteEmployees: (data) => {
    let url = `/admin/employees/invite`;
    let result = coreApi.POST(url, data);
    return result;
  },
  updateEmployee: (data, employee_id) => {
    let url = `${
      window.location.pathname.includes("backoffice")
        ? "/backoffice"
        : window.location.pathname.includes("company")
        ? "/admin"
        : ""
    }/employees/${employee_id}`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  createPayStackProfile: (employee_id, data) => {
    let url = `${
      window.location.pathname.includes("backoffice")
        ? "/backoffice"
        : window.location.pathname.includes("company")
        ? "/admin"
        : ""
    }/employees/${employee_id}/paystack`;
    return coreApi.POST(url, data);
  },
  getOvertimeList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/overtimes${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getOvertimeInfo: (overtime_id) => {
    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/overtimes/${overtime_id}`;
    let result = coreApi.GET(url);
    return result;
  },
  approveOvertime: (overtime_id, data) => {
    let url = `/admin/overtimes/${overtime_id}/approve`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  rejectOvertime: (overtime_id, data) => {
    let url = `/admin/overtimes/${overtime_id}/reject`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  getLeaveList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `/admin/leaves${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  createLeave: (data) => {
    let url = `/admin/leaves`;
    let result = coreApi.POST(url, data);
    return result;
  },
};
