import React from "react";
import MainRouter from "./router";
import { GlobalStyle } from "./styles/commonStyle";

const App = () => {
  return (
    <div className="App">
      <GlobalStyle></GlobalStyle>
      <MainRouter />
    </div>
  );
};


export default App;
