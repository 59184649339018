export const ONBOARD_EMPLOYEE_INFO_ACTION = "ONBOARD_EMPLOYEE_INFO_ACTION";
export const ONBOARD_EMPLOYEE_INFO_LOADING = "ONBOARD_EMPLOYEE_INFO_LOADING";
export const ONBOARD_EMPLOYEE_INFO_SUCCESS = "ONBOARD_EMPLOYEE_INFO_SUCCESS";
export const ONBOARD_EMPLOYEE_INFO_ERROR = "ONBOARD_EMPLOYEE_INFO_ERROR";

export const ONBOARD_EMPLOYEE_VERIFY_ACTION = "ONBOARD_EMPLOYEE_VERIFY_ACTION";
export const ONBOARD_EMPLOYEE_VERIFY_LOADING =
  "ONBOARD_EMPLOYEE_VERIFY_LOADING";
export const ONBOARD_EMPLOYEE_VERIFY_SUCCESS =
  "ONBOARD_EMPLOYEE_VERIFY_SUCCESS";
export const ONBOARD_EMPLOYEE_VERIFY_ERROR = "ONBOARD_EMPLOYEE_VERIFY_ERROR";

export const DEDUCTION_LIST_ACTION = "DEDUCTION_LIST_ACTION";
export const DEDUCTION_LIST_LOADING = "DEDUCTION_LIST_LOADING";
export const DEDUCTION_LIST_SUCCESS = "DEDUCTION_LIST_SUCCESS";
export const DEDUCTION_LIST_ERROR = "DEDUCTION_LIST_ERROR";
