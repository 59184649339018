/* eslint-disable import/no-anonymous-default-export */

export default {
  colors: {
    White: '#FFFFFF',
    Black: '#000000',
    DodgerBlue: '#3E83FB',
    Violet: '#150E4A',
    DodgerBlueLight: '#3E83FA',
    Zumthor: '#ECF3FF',
    MintGreen: '#78F49E',
    MintGreenLight: '#79F59F',
    Periwinkle: '#C5DAFE',
    Monza: '#B00020',
    Cinderella: '#FDE2E5',
    RadicalRed: '#FF3B59',
    Porcelain: '#F5F6F7',
    Gallery: '#EAEAEA',
    Pomegranate: '#F44336',
  },
  fontFamily: {
    MARKPRO: 'MARKPRO',
    MARKPROBLACK: 'MARKPROBLACK',
    MARKPROBOLD: 'MARKPROBOLD',
    MARKPROBOOK: 'MARKPROBOOK',
    MARKPROEXTRALIGHT: 'MARKPROEXTRALIGHT',
    MARKPROHEAVY: 'MARKPROHEAVY',
    MARKPROLIGHT: 'MARKPROLIGHT',
    MARKPROMEDIUM: 'MARKPROMEDIUM',
  },
};
