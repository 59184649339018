import React, { useState, useEffect } from "react";
import CommonFormDropDown from "../formdropdown";
import { useSelector } from "react-redux";
import CommonInput from "../input";
import FeeRange from "./FeeRange";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import theme from "../../theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  Tab: {
    position: "relative",
    top: "10px",
    left: "-22px",
    cursor: "pointer",
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px 25px",
      top: `50%`,
      left: `15%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
    },
  },
  tableContainer: {
    marginTop: "30px",
  },
  currency: {
    fontSize: "8px",
    position: "relative",
    bottom: "3px",
    marginRight: "2px",
  },
  successTube: {
    color: "#22D559",
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    backgroundColor: "#E4FDEC",
    textAlign: "center",
    padding: "2px 8px",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  addLinkBlock: {
    cursor: "pointer",
    color: theme.colors.DodgerBlue,
    marginBottom: "30px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    display: "flex",
    alignItems: "center",
  },
  header: {
    fontSize: "16px",
    color: theme.colors.Violet,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  currencyName: {
    fontSize: "20px",
    color: theme.colors.DodgerBlue,
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "30px",
  },
}));

function CurrencyComponent({
  currency,
  setCurrency,
  isValidated,
  currencyTabNumber,
  editData,
  currencyOptions,
}) {
  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });
  const classes = useStyles();
  let [currencyData, setCurrencyData] = useState("");
  let [maxWithdrawalData, setMaxWithdrwalData] = useState("");
  let [feeRangeTabNumber, setFeeRangeTabNumber] = useState(1);
  let [feeRange, setFeeRange] = useState([]);
  let [editFeeRangeData, setEditFeeRangeData] = useState(null);
  useEffect(() => {
    // console.log(editData);
    let returnData = [...currency];
    let k = currencyTabNumber;
    if (currencyData !== "") {
      let value = { ...returnData[currencyTabNumber], currency: currencyData };
      returnData[k] = value;
      setCurrency(returnData);
    }
    if (maxWithdrawalData !== "") {
      returnData[currencyTabNumber] = {
        ...returnData[currencyTabNumber],
        maxWithdrawal: maxWithdrawalData,
      };
      setCurrency(returnData);
    }

    returnData[currencyTabNumber] = {
      ...returnData[currencyTabNumber],
      feeRange: feeRange,
    };
    // setCurrency(returnData);
    returnData[currencyTabNumber] = {
      ...returnData[currencyTabNumber],
      feeRangeTabs: feeRangeTabNumber,
    };
    setCurrency(returnData);
    // eslint-disable-next-line
  }, [currencyData, maxWithdrawalData, feeRange, feeRangeTabNumber]);

  useEffect(() => {
    if (editData && editData[currencyTabNumber]) {
      setFeeRangeTabNumber(editData[currencyTabNumber]?.feesrange.length);
      setMaxWithdrwalData(editData[currencyTabNumber]?.max_withdraw);
      setEditFeeRangeData(editData[currencyTabNumber]?.feesrange);
      // eslint-disable-next-line
      let currency = loginReducer?.currencyOptions.find((item) => {
        if (item.id === editData[currencyTabNumber]?.currency) {
          return true;
        }
      });
      setCurrencyData({ label: currency?.name, value: currency?.id });
      setFeeRange(editData[currencyTabNumber]?.feesrange);
    }
  }, [editData, loginReducer, currencyTabNumber]);

  const removeMaxWithdrawal = () => {
    let data = [...currency];
    if (data?.[currencyTabNumber].maxWithdrawal) {
      delete data?.[currencyTabNumber].maxWithdrawal;
      setCurrency(data);
    }
  };

  return (
    <div>
      <div className="block">
        <div className={classes.header}>CURRENCY {currencyTabNumber + 1}</div>
        {editData && editData[currencyTabNumber] ? (
          <div className={classes.currencyName}>{currencyData.label}</div>
        ) : (
          <CommonFormDropDown
            label="Currency"
            placeholder={"Select Currency"}
            options={currencyOptions.map((each) => {
              return { label: each.name, value: each.id };
            })}
            value={currencyData}
            onChange={(val) => {
              setCurrencyData(val);
            }}
            error={isValidated && !currencyData}
            helperText={
              isValidated && !currencyData && "Currency may not be blank."
            }
          />
        )}
        {[...Array(feeRangeTabNumber)].map((e, i) => (
          <FeeRange
            key={i}
            feeRange={feeRange}
            setFeeRange={setFeeRange}
            feeRangeTabNumber={i}
            isValidated={isValidated}
            editFeeRangeData={editFeeRangeData}
          />
        ))}
        <div
          className={classes.addLinkBlock}
          onClick={() => {
            setFeeRangeTabNumber(feeRangeTabNumber + 1);
          }}
        >
          <AddOutlinedIcon size="small" />
          <span> Add Another Fee Range</span>
        </div>
        <div className="block">
          <CommonInput
            placeholder={`Enter Maximum Withdrawal`}
            label="Maximum withdrawal"
            type="number"
            allowDecimal={3}
            onlyPositive={true}
            value={maxWithdrawalData}
            onChange={(e) => {
              setMaxWithdrwalData(e.target.value);
              if (e.target.value === "") {
                removeMaxWithdrawal();
              }
            }}
            error={isValidated && maxWithdrawalData === ""}
            helperText={
              isValidated &&
              maxWithdrawalData === "" &&
              "Maximum may not be blank."
            }
            onWheelCapture={(e) => {
              e.target.blur();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CurrencyComponent;
