import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import theme from "../../theme";
import CommonTable from "../table";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  withdrawAction: {
    display: "flex",
    alignItems: "center",
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
}));

function OvertimeRequestsTable({ data, page, setPage, totalPage }) {
  const classes = useStyles();

  return (
    <>
      <CommonTable
        columns={[
          {
            Header: "Employee Name",
            accessor: "employee",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data) => {
              return (
                <div>
                  {data?.name}{" "}
                  <span className="inactive-label">
                    {data?.is_active ? "" : " (Inactive)"}
                  </span>
                </div>
              );
            },
          },
          {
            Header: "Company Name",
            accessor: "company",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data) => {
              return <div>{data?.name}</div>;
            },
          },
          {
            Header: "Hours Performed",
            accessor: "duration",
            HeaderCell: (data) => {
              return (
                <div className={classes.headerName}>{data.substr(0, 5)}</div>
              );
            },
            Cell: (data) => {
              return (
                <div>
                  {data?.length
                    ? `${
                        data.substr(0, 2)?.length
                          ? `${data.substr(0, 2)}hr`
                          : ""
                      } ${
                        data.substr(3, 2)?.length
                          ? `${data.substr(3, 2)}min`
                          : ""
                      }`
                    : ""}
                </div>
              );
            },
          },
          {
            Header: "Date of Overtime",
            accessor: "date",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
          },
          {
            Header: "Approval time",
            accessor: "approved_at",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data) => {
              return moment(data).format("DD/MM/YYYY, h:mm a");
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">{record?.company?.currency}</span>{" "}
                  {moneyValue(data)}
                </div>
              );
            },
          },
        ]}
        data={data ? data : []}
      />
      {data?.length ? (
        <div className={"pagination"}>
          <Pagination
            page={page}
            count={totalPage}
            showFirstButton
            showLastButton
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default OvertimeRequestsTable;
