/* eslint-disable import/no-anonymous-default-export */
import coreApi from "../../lib/coreApi";

export default {
  getPaymentList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/payments${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getPaymentDownload: (data) => {
    let url = '/backoffice/payments/download';
    let result = coreApi.POST(url, data);
    return result;
  },
  getPaymentInfo: (payment_id) => {
    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/payments/${payment_id}`;
    let result = coreApi.GET(url);
    return result;
  },
  settlePayment: (data, payment_id) => {
    let url = `/backoffice/payments/${payment_id}/settle`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  getMonthsToSettle: (employee_id) => {
    let url = `/backoffice/employees/${employee_id}/settle`;
    let result = coreApi.GET(url);
    return result;
  },
  getBulkMonthsToSettle: (country) => {
    let url = `/backoffice/employees/settle?country=${country}`;
    return coreApi.GET(url);
  },
  settleSalary: (employee_id, date) => {
    let url = `/backoffice/employees/${employee_id}/settle`;
    let data = date ? { date } : {};
    let result = coreApi.POST(url, data);
    return result;
  },
  bulkSettleSalary: (employees_ids, date) => {
    let url = `/backoffice/employees/settle`;
    let result = coreApi.POST(url, { employees_ids, date });
    return result;
  },
  getCompanyList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }
    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `/backoffice/companies${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getCompanyInfo: (company_id) => {
    let url = `/backoffice/companies/${company_id}`;
    let result = coreApi.GET(url);
    return result;
  },
  getEmployeeList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }
    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/employees${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getEmployeeInfo: (employee_id) => {
    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/employees/${employee_id}`;
    let result = coreApi.GET(url);
    return result;
  },
  getOverview: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }
    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `/backoffice/overview${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  inviteCompany: (data) => {
    let url = `/backoffice/companies/invite`;
    let result = coreApi.POST(url, data);
    return result;
  },
  updateCompany: ({ company_id, data }) => {
    let url = `/backoffice/companies/${company_id}`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  getExchangeList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `/backoffice/exchanges${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getExchangeInfo: (exchange_id) => {
    let url = `/backoffice/exchanges/${exchange_id}`;
    let result = coreApi.GET(url);
    return result;
  },
  createExchange: (data) => {
    let url = `/backoffice/exchanges`;
    let result = coreApi.POST(url, data);
    return result;
  },
  updateExchange: (data, exchange_id) => {
    let url = `/backoffice/exchanges/${exchange_id}`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  deleteExchange: (exchange_id) => {
    let url = `/backoffice/exchanges/${exchange_id}`;
    let result = coreApi.DELETE(url);
    return result;
  },
  getDeductionList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `/backoffice/deductions${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getDeductionInfo: (deduction_id) => {
    let url = `/backoffice/deductions/${deduction_id}`;
    let result = coreApi.GET(url);
    return result;
  },
  createDeduction: (data) => {
    let url = `/backoffice/deductions`;
    let result = coreApi.POST(url, data);
    return result;
  },
  updateDeduction: (data, deduction_id) => {
    let url = `/backoffice/deductions/${deduction_id}`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  deleteDeduction: (deduction_id) => {
    let url = `/backoffice/deductions/${deduction_id}`;
    let result = coreApi.DELETE(url);
    return result;
  },
  getTransactionHistory: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `${
      JSON.parse(localStorage.getItem("user"))?.is_super
        ? "/backoffice"
        : JSON.parse(localStorage.getItem("user"))?.is_admin
        ? "/admin"
        : ""
    }/transactions${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  getFeesList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }

    if (qp && qp.length && qp.length > 1) qp = qp.replace(qp.charAt(0), "?");

    let url = `/backoffice/fees${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  createFees: (data) => {
    let url = `/backoffice/fees`;
    let result = coreApi.POST(url, data);
    return result;
  },
  updateFees: (data) => {
    let url = `/backoffice/fees`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
};
