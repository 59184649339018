import React, { useState, useEffect, useCallback } from "react";
import { Card, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import {
  createCountryFeesAction,
  updateCountryFeesAction,
} from "../../containers/dashboard/action";
import CommonFormDropDown from "../formdropdown";
import CurrencyComponent from "./CurrencyComponent";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  modalPaper: {
    position: "absolute",
  },
  container: {
    width: "460px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "10px",
    padding: "40px 60px",
    maxHeight: "80vh",
    overflow: "auto",
    "&::-webkit-scrollbar-track": {
      marginLeft: "10px",
      background: theme.colors.Periwinkle,
      borderRadius: "0px 10px 10px 0px",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.DodgerBlue,
      borderRadius: "0px 10px 10px 0px",
      "& :hover": {
        background: theme.colors.Periwinkle,
      },
    },

    "&::-webkit-scrollbar": {
      width: 8,
    },
  },
  headerContainer: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  countryName: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  saveButton: {
    backgroundColor: theme.colors.DodgerBlue,
  },
  card: {
    boxShadow: "0px 2px 15px #D0CFDB99",
    color: theme.colors.DodgerBlue,
    height: "7vh",
    borderRadius: "10px",
  },
  countryLable: {
    float: "left",
    padding: "10px",
  },
  editCountryName: {
    float: "right",
    padding: "10px",
    fontWeight: "bold",
  },
  devider: {
    width: "100%",
    backgroundColor: theme.colors.Zumthor,
    height: "3px",
    marginTop: "50px",
    marginBottom: "50px",
  },
}));

// let countryData = [
//   {
//     id: "CA",
//     name: { label: "Canada", value: "CA" },
//     currencies: [
//       {
//         currency: { label: "Canadian Dollar", value: "CAD" },
//         feeRange: [{ from: "10", to: "20", withdrawFees: "100" }],
//         maxWithdrawal: "200",
//       },
//       {
//         currency: { label: "Lek", value: "ALL" },
//         feeRange: [{ from: "20", to: "40", withdrawFees: "200" }],
//         maxWithdrawal: "2000",
//       },
//     ],
//   },
//   {
//     id: "AE",
//     name: { label: "United Arab Emirates", value: "AE" },
//     currencies: [
//       {
//         currency: { label: "Canadian Dollar", value: "CAD" },
//         feeRange: [
//           { from: "20", to: "40", withdrawFees: "200" },
//           { from: "40", to: "80", withdrawFees: "500" },
//           { from: "80", to: "100", withdrawFees: "700" },
//         ],
//         maxWithdrawal: "200",
//       },
//       {
//         currency: { label: "Lek", value: "ALL" },
//         feeRange: [{ from: "10", to: "20", withdrawFees: "100" }],
//         maxWithdrawal: "2000",
//       },
//     ],
//   },
//   {
//     id: "CU",
//     name: { label: "Cuba", value: "CU" },
//     currencies: [
//       {
//         currency: { label: "Canadian Dollar", value: "CAD" },
//         feeRange: [
//           { from: "10", to: "20", withdrawFees: "100" },
//           { from: "20", to: "40", withdrawFees: "200" },
//         ],
//         maxWithdrawal: "200",
//       },
//       {
//         currency: { label: "Lek", value: "ALL" },
//         feeRange: [
//           { from: "40", to: "80", withdrawFees: "500" },
//           { from: "80", to: "100", withdrawFees: "700" },
//         ],
//         maxWithdrawal: "2000",
//       },
//     ],
//   },
//   {
//     id: "PH",
//     name: { label: "Philippines", value: "PH" },
//     currencies: [
//       {
//         currency: { label: "Canadian Dollar", value: "CAD" },
//         feeRange: [{ from: "10", to: "20", withdrawFees: "100" }],
//         maxWithdrawal: "200",
//       },
//       {
//         currency: { label: "Lek", value: "ALL" },
//         feeRange: [
//           { from: "20", to: "40", withdrawFees: "200" },
//           { from: "40", to: "80", withdrawFees: "500" },
//           { from: "80", to: "100", withdrawFees: "700" },
//         ],
//         maxWithdrawal: "2000",
//       },
//     ],
//   },
// ];
function FormModal({ openModal, editData, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);

  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  let [currencyTabNumber, setCurrencyTabNumber] = useState(1);
  let [editableData, setEditableData] = useState(null);
  let [currencyOptions, setCurrencyOptions] = useState([]);

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });
  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  // useEffect(() => {
  //   if (editId) getDeductionInfoMethod(editId);
  // }, [editId, getDeductionInfoMethod]);
  const onCloseModal = useCallback(() => {
    setCountry("");
    setCurrency([]);
    setCurrencyTabNumber(1);
    setEditableData(null);
    setIsValidated(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (editData) {
      if (editData.length > 1) {
        setCurrencyTabNumber(editData.length);
      }
      // eslint-disable-next-line
      let country = loginReducer?.countryOptions.find((item) => {
        if (item.id === editData[0].country) {
          return true;
        }
      });
      setCountry({ label: country.name, value: country.id });
      var result = editData.map(function (el) {
        var obj = Object.assign({}, el);
        obj.feeRangeTabs = obj.feesrange.length;
        obj.maxWithdrawal = obj.max_withdraw;
        obj.feeRange = obj.feesrange;
        return obj;
      });
      setCurrency(result);
      setEditableData(editData);
    }
  }, [editData, loginReducer]);

  useEffect(() => {
    const { currencyOptions } = loginReducer;
    if (currencyOptions) {
      setCurrencyOptions(currencyOptions);
    }
  }, [loginReducer]);

  useEffect(() => {
    currency.forEach((element) => {
      if (element.hasOwnProperty("currency")) {
        let currency = element.currency;
        if (typeof currency === "object") {
          currency = element.currency.value;
        }
        // eslint-disable-next-line
        let filteredOptions = currencyOptions.filter((item) => {
          if (item.id !== currency) {
            return true;
          }
        });
        setCurrencyOptions(filteredOptions);
      }
    });
    // eslint-disable-next-line
  }, [currency]);

  const createFeesRangeMethod = useCallback(
    (data) => dispatch(createCountryFeesAction(data)),
    [dispatch]
  );

  const updateFeesRangeMethod = useCallback(
    (data) => dispatch(updateCountryFeesAction(data)),
    [dispatch]
  );

  useEffect(() => {
    const { createCountryFeesSuccess, updateCountryFeesSuccess } =
      dashboardReducer;

    if (createCountryFeesSuccess || updateCountryFeesSuccess) {
      onCloseModal();
      // onClose();
      // setName("");
    }
  }, [dashboardReducer, onCloseModal]);

  useEffect(() => {
    if (
      dashboardReducer?.createDeductionError?.length ||
      dashboardReducer?.updateDeductionError?.length
    ) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer]);

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsValidated(true);
    let callApi = true;
    if (country && currency) {
      if (currency.length === currencyTabNumber) {
        currency.forEach((element) => {
          if (
            element.hasOwnProperty("currency") &&
            element.hasOwnProperty("feeRange") &&
            element.hasOwnProperty("maxWithdrawal")
          ) {
            if (element.feeRangeTabs === element.feeRange.length) {
              element.feeRange.forEach((feeRange) => {
                if (
                  feeRange.hasOwnProperty("start_range") &&
                  feeRange.hasOwnProperty("end_range") &&
                  feeRange.hasOwnProperty("fee")
                ) {
                } else {
                  callApi = false;
                }
              });
            } else {
              callApi = false;
            }
          } else {
            callApi = false;
          }
        });
      } else {
        callApi = false;
      }
    } else {
      callApi = false;
    }
    if (callApi) {
      // onCloseModal();
      let returnList = [];
      currency.forEach((element) => {
        let el = {};
        if (element.id) {
          el = {
            country: country.value,
            currency: element.currency.value,
            feesrange: element.feeRange,
            max_withdraw: element.maxWithdrawal,
            id: element.id,
          };
        } else {
          el = {
            country: country.value,
            currency: element.currency.value,
            feesrange: element.feeRange,
            max_withdraw: element.maxWithdrawal,
          };
        }
        returnList.push(el);
      });
      if (!editData) {
        createFeesRangeMethod(returnList);
      } else {
        updateFeesRangeMethod(returnList);
      }
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        onCloseModal();
        // setName("");
      }}
    >
      <div style={modalStyle} className={classes.modalPaper}>
        <div className={classes.container}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div>
              {!editData ? (
                <div className={classes.headerContainer}>Set Up Country</div>
              ) : (
                <div className={classes.headerContainer}>Edit Country</div>
              )}
            </div>
            {dashboardReducer?.createCountryFeesError?.length ||
            dashboardReducer?.updateCountryFeesError?.length ? (
              <div className={"error-container"}>
                {dashboardReducer?.createCountryFeesError?.length
                  ? dashboardReducer.createCountryFeesError.map(
                      (each, index) => <div key={index}>{each}</div>
                    )
                  : null}
                {dashboardReducer?.updateCountryFeesError?.length
                  ? dashboardReducer.updateCountryFeesError.map(
                      (each, index) => <div key={index}>{each}</div>
                    )
                  : null}
              </div>
            ) : null}

            <div className={classes.countryName}>
              {!editData ? (
                <CommonFormDropDown
                  label="Country"
                  placeholder={"Select Country"}
                  options={
                    loginReducer?.countryOptions?.length
                      ? loginReducer.countryOptions.map((each) => {
                          return { label: each.name, value: each.id };
                        })
                      : []
                  }
                  value={country}
                  onChange={(val) => {
                    setCountry(val);
                  }}
                  error={isValidated && !country}
                  helperText={
                    isValidated && !country && "Country may not be blank."
                  }
                />
              ) : (
                <Card variant="outlined" className={classes.card}>
                  {
                    <>
                      <span className={classes.countryLable}>Country</span>
                      <span className={classes.editCountryName}>
                        {country?.label}
                      </span>
                    </>
                  }
                </Card>
              )}
            </div>
            <div className={classes.countryName}>
              {[...Array(currencyTabNumber)].map((e, i) => (
                <div key={i}>
                  {i > 0 && <div className={classes.devider}></div>}
                  <CurrencyComponent
                    currency={currency}
                    setCurrency={setCurrency}
                    isValidated={isValidated}
                    currencyTabNumber={i}
                    editData={editableData}
                    currencyOptions={currencyOptions}
                  />
                </div>
              ))}
            </div>
            <div>
              <CommonButton
                text="Add Another Currency"
                stylevariant="secondary2"
                fontSize={"16px"}
                icon={"/images/add.svg"}
                onClick={() => {
                  setCurrencyTabNumber(currencyTabNumber + 1);
                }}
              />
            </div>

            <CommonButton
              type="submit"
              text={"Save"}
              className={classes.SaveButton}
              stylevariant={"secondary4"}
              fontSize={"18px"}
              // onClick={() => {
              //   onClose();
              // }}
              style={{
                marginTop: "50px",
                marginBottom: "20px",
                width: "80%",
                marginLeft: "10%",
              }}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default FormModal;
