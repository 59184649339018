import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import CommonInput from "../input";
import { Constants } from "../../lib/constant";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    padding: "45px 90px",
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    width: "350px",
    margin: "0 auto",
    "& .title": {
      color: theme.colors.DodgerBlue,
      fontSize: "24px",
      fontFamily: theme.fontFamily.MARKPROBOLD,
      marginBottom: "20px",
    },
  },
}));
function InviteCompany({ inviteCompanyMethod, dashboardReducer }) {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [methodCalled, setMethodCalled] = useState(false);

  return (
    <>
      {methodCalled && dashboardReducer?.inviteCompanyError?.length ? (
        <div className={"error-container"}>
          {dashboardReducer?.inviteCompanyError?.length
            ? dashboardReducer.inviteCompanyError.map((each, index) => (
                <div key={index}>{each}</div>
              ))
            : null}
        </div>
      ) : null}
      <div className={classes.root}>
        <div className="title">Invite New Company</div>
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsValidated(true);
            setMethodCalled(true);
            if (
              companyName &&
              firstName &&
              lastName &&
              email &&
              email.match(Constants.email_regex)
            ) {
              inviteCompanyMethod({
                name: companyName.trim(),
                admin: {
                  email: email.trim(),
                  first_name: firstName.trim(),
                  last_name: lastName.trim(),
                },
              });
            }
          }}
        >
          <CommonInput
            label="Company Name"
            placeholder="Enter company name"
            type="text"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            error={isValidated && !companyName}
            helperText={
              isValidated && !companyName && "Company Name may not be blank."
            }
          />
          <CommonInput
            label="First Name"
            placeholder="Enter first name"
            type="text"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            error={isValidated && !firstName}
            helperText={
              isValidated && !firstName && "First Name may not be blank."
            }
          />
          <CommonInput
            label="Last Name"
            placeholder="Enter last name"
            type="text"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            error={isValidated && !lastName}
            helperText={
              isValidated && !lastName && "Last Name may not be blank."
            }
          />
          <CommonInput
            label="Email"
            placeholder="Enter email"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim());
            }}
            error={
              isValidated &&
              (!email || (email && !email.match(Constants.email_regex)))
            }
            helperText={
              isValidated &&
              ((!email && "Email may not be blank.") ||
                (email &&
                  !email.match(Constants.email_regex) &&
                  "Email is invalid."))
            }
          />
          <CommonButton
            type="submit"
            text="Send Invitation"
            stylevariant="secondary1"
            style={{ marginTop: "30px" }}
            disabled={
              !companyName.trim() ||
              !firstName.trim() ||
              !lastName.trim() ||
              !email.trim() ||
              !email.match(Constants.email_regex)
            }
          />
        </form>
      </div>
    </>
  );
}

export default InviteCompany;
