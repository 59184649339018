import React from "react";
import Select from "react-select";
import theme from "../../theme";

function CommonDropDown(props) {
  const customStyle = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: theme.colors.DodgerBlue,
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: props.placeholderColor
          ? props.placeholderColor
          : theme.colors.DodgerBlueLight,
      };
    },
    container: (provided, state) => ({
      ...provided,
      width: props.width ? props.width : "auto",
    }),
    control: (provided, state) => ({
      ...provided,
      padding: "0px 5px",
      border: `1px solid ${
        state.hasValue ? theme.colors.DodgerBlue : theme.colors.Zumthor
      }`,
      borderColor: state.hasValue
        ? theme.colors.DodgerBlue
        : theme.colors.Zumthor,
      borderRadius: props.borderRadius ? props.borderRadius : "20px",
      backgroundColor: theme.colors.Zumthor,
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      boxShadow: 0,
      "&:hover": {
        border: `1px solid ${theme.colors.DodgerBlue}`,
        borderColor: theme.colors.DodgerBlue,
        boxShadow: 0,
      },
    }),
  };

  return (
    <Select
      {...props}
      // loadOptions={(inputValue, callback) => {
      //   setTimeout(() => {
      //     callback(
      //       props.options.filter((i) =>
      //         i.label.toLowerCase().includes(inputValue.toLowerCase())
      //       )
      //     );
      //   }, 1000);
      // }}
      // defaultOptions
      // cacheOptions
      isClearable={false}
      // onInputChange={(val) => {
      //   return val;
      // }}
      styles={customStyle}
    />
  );
}

export default CommonDropDown;
