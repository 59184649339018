import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import theme from "../../theme";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";

const useStyles = makeStyles(() => ({
  root: {
    padding: "50px",
    "& .box": {
      background: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "15px",
      padding: "30px 60px",
      "& .block": {
        marginBottom: "30px",
        "& .title": {
          fontSize: "21px",
          fontFamily: theme.fontFamily.MARKPROBOLD,
          color: theme.colors.DodgerBlue,
          marginBottom: "15px",
        },
        "& .flex": {
          display: "flex",
          alignItems: "center",
          "& .label": {
            flex: 0.3,
            fontSize: "16px",
            fontFamily: theme.fontFamily.MARKPROMEDIUM,
            marginBottom: "20px",
          },
          "& .field": { flex: 0.9 },
        },
      },
      "& .buttonContainer": { display: "flex", justifyContent: "center" },
    },
  },
}));

function EditEmployeeDetail({
  editEmployeeDetail,
  setEditEmployeeDetail,
  updateEmployeeMethod,
  deductionList,
}) {
  const classes = useStyles();
  const bank = editEmployeeDetail?.banks?.length ? editEmployeeDetail.banks[0] : {
    name: "",
    code: "",
    account_holder: "",
    recipient_code: "",
    number: "",
    iban: "",
    paystack_id: "",
  };

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [isValidated, setIsValidated] = useState(false);

  const [firstName, setFirstName] = useState(editEmployeeDetail?.first_name);
  const [lastName, setLastName] = useState(editEmployeeDetail?.last_name);
  const [emailAddress, setEmailAddress] = useState(editEmployeeDetail?.email);
  const [employeeID, setEmployeeID] = useState(editEmployeeDetail?.employee_id);
  const [branch, setBranch] = useState(editEmployeeDetail?.branch || "");
  const [phoneNumber, setPhoneNumber] = useState(
    editEmployeeDetail?.profile?.phone
  );
  const [accountHolderName, setAccountHolderName] = useState(bank.account_holder);
  const [bankName, setBankName] = useState(bank.name);
  const [bankCode, setBankCode] = useState(bank.code);
  const [recipientCode, setRecipientCode] = useState(bank.recipient_code);
  const [paystackID, setPaystackID] = useState(bank.paystack_id);
  const [accountNumber, setAccountNumber] = useState(bank.number);
  const [IBAN, setIBAN] = useState(bank.iban);
  const [jobTitle, setJobTitle] = useState(
    editEmployeeDetail?.profile?.position
  );
  const [monthlyPay, setMonthlyPay] = useState(
    editEmployeeDetail?.profile?.salary
  );
  const [deductions, setDeductions] = useState([]);

  useEffect(() => {
    if (companyDashboardReducer?.updateEmployeeSuccess) {
      setEditEmployeeDetail(null);
    }
  }, [companyDashboardReducer, setEditEmployeeDetail]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < deductionList.length; i++) {
      let deduction = deductionList[i];
      if (editEmployeeDetail?.deductions?.length) {
        deduction["amount"] =
          editEmployeeDetail.deductions.find((each) => each.id === deduction.id)
            ?.amount || 0;
      } else {
        deduction["amount"] = 0;
      }
      temp.push(deduction);
    }
    setDeductions(temp);
  }, [deductionList, editEmployeeDetail]);

  useEffect(() => {
    if (companyDashboardReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [companyDashboardReducer]);

  return (
    <div className={classes.root}>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setIsValidated(true);
          if (
            firstName &&
            firstName.trim() &&
            firstName.trim()?.length <= 24 &&
            lastName &&
            lastName.trim() &&
            lastName.trim()?.length <= 24 &&
            employeeID?.trim()?.length <= 10 &&
            branch?.trim()?.length <= 48 &&
            jobTitle &&
            jobTitle.trim() &&
            jobTitle.trim()?.length <= 24 &&
            phoneNumber &&
            phoneNumber.trim() &&
            emailAddress &&
            emailAddress.trim() &&
            monthlyPay &&
            monthlyPay >= 1 &&
            bankName &&
            bankName.trim() &&
            bankName.trim()?.length <= 24 &&
            bankCode &&
            bankCode.trim() &&
            bankCode.trim()?.length <= 24 &&
            accountNumber &&
            accountNumber.trim() &&
            accountNumber.trim()?.length <= 255 &&
            IBAN &&
            IBAN.trim() &&
            accountHolderName &&
            accountHolderName.trim() &&
            accountHolderName.trim()?.length <= 24
          )
            updateEmployeeMethod(
              {
                // email: editEmployeeDetail?.email,
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                employee_id: employeeID?.trim(),
                email: emailAddress.trim(),
                branch: branch?.trim(),
                // gender: editEmployeeDetail?.gender,
                // state: editEmployeeDetail?.state,
                // is_admin: editEmployeeDetail?.is_admin,
                // is_staff: editEmployeeDetail?.is_staff,
                // is_active: editEmployeeDetail?.is_active,
                profile: {
                  position: jobTitle.trim(),
                  phone: phoneNumber.trim(),
                  salary: monthlyPay,
                  // savings: editEmployeeDetail?.savings,
                  // limit: editEmployeeDetail?.limit,
                },
                banks: [
                  {
                    name: bankName.trim(),
                    code: bankCode.trim(),
                    recipient_code: recipientCode ? recipientCode.trim() : null,
                    paystack_id: paystackID ? paystackID.trim() : null,
                    number: accountNumber.trim(),
                    iban: IBAN.trim(),
                    account_holder: accountHolderName.trim(),
                  },
                ],
                deductions: deductions.filter((each) => each?.amount > 0),
              },
              editEmployeeDetail?.id
            );
        }}
      >
        <div className={"box"}>
          <div className={"block"}>
            {companyDashboardReducer?.error?.length ? (
              <div className={"error-container"}>
                {companyDashboardReducer.error.map((each, index) => (
                  <div key={index}>{each}</div>
                ))}
              </div>
            ) : null}
            <div className={"title"}>Personal Information</div>
            <div className={"flex"}>
              <div className={"label"}>First Name</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter First Name"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!firstName ||
                      !firstName.trim() ||
                      firstName.trim().length > 24)
                  }
                  helperText={
                    isValidated &&
                    (((!firstName || !firstName.trim()) &&
                      "First Name may not be blank.") ||
                      (firstName.trim()?.length > 24 &&
                        "Ensure First Name has no more than 24 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Last Name</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Last Name"
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!lastName ||
                      !lastName.trim() ||
                      lastName.trim().length > 24)
                  }
                  helperText={
                    isValidated &&
                    (((!lastName || !lastName.trim()) &&
                      "Last Name may not be blank.") ||
                      (lastName.trim()?.length > 24 &&
                        "Ensure Last Name has no more than 24 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Email Address</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter email address"
                  type="email"
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                  fullWidth
                  error={isValidated && (!emailAddress || !emailAddress.trim())}
                  helperText={
                    isValidated &&
                    (!emailAddress || !emailAddress.trim()) &&
                    "email address may not be blank."
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Employee ID</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter employee ID"
                  value={employeeID}
                  onChange={(e) => {
                    setEmployeeID(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!employeeID || !employeeID.trim() || employeeID?.trim()?.length > 10)
                  }
                  helperText={
                    (!employeeID || !employeeID.trim() || employeeID?.trim()?.length > 10) &&
                    "Employee ID may not be blank and must be less than 10 characters."
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Phone Number</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Phone Number"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  fullWidth
                  error={isValidated && (!phoneNumber || !phoneNumber.trim())}
                  helperText={
                    isValidated &&
                    (!phoneNumber || !phoneNumber.trim()) &&
                    "Phone Number may not be blank."
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Branch Name</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter employee branch"
                  type="text"
                  value={branch}
                  onChange={(e) => {
                    setBranch(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!branch || !branch.trim() || branch?.trim()?.length > 48)
                  }
                  helperText={
                    (!branch || !branch.trim() || branch?.trim()?.length > 48) &&
                    "Branch name may not be blank and must be less than 48 characters."
                  }
                />
              </div>
            </div>
          </div>
          <div className={"block"}>
            <div className={"title"}>Bank Account Information</div>
            <div className={"flex"}>
              <div className={"label"}>Account Holder Name</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Account Holder Name"
                  type="text"
                  value={accountHolderName}
                  onChange={(e) => {
                    setAccountHolderName(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!accountHolderName ||
                      !accountHolderName.trim() ||
                      accountHolderName.trim().length > 24)
                  }
                  helperText={
                    isValidated &&
                    (((!accountHolderName || !accountHolderName.trim()) &&
                      "Account Holder Name may not be blank.") ||
                      (accountHolderName.trim()?.length > 24 &&
                        "Ensure Account Holder Name has no more than 24 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Bank Name</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Bank Name"
                  type="text"
                  value={bankName}
                  onChange={(e) => {
                    setBankName(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!bankName ||
                      !bankName.trim() ||
                      bankName.trim().length > 24)
                  }
                  helperText={
                    isValidated &&
                    (((!bankName || !bankName.trim()) &&
                      "Bank Name may not be blank.") ||
                      (bankName.trim()?.length > 24 &&
                        "Ensure Bank Name has no more than 24 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Bank Code</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Bank Code"
                  type="text"
                  value={bankCode}
                  onChange={(e) => {
                    setBankCode(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!bankCode ||
                      !bankCode.trim() ||
                      bankCode.trim().length < 3 ||
                      bankCode.trim().length > 6)
                  }
                  helperText={
                    isValidated &&
                    (((!bankCode || !bankCode.trim()) &&
                      "Bank Code may not be blank.") ||
                      (bankCode.trim()?.length > 6 &&
                        "Ensure Bank Code has no more than 6 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Account Number</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Account Number"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!accountNumber ||
                      !accountNumber.trim() ||
                      accountNumber.trim().length > 255)
                  }
                  helperText={
                    isValidated &&
                    (((!accountNumber || !accountNumber.trim()) &&
                      "Account Number may not be blank.") ||
                      (accountNumber.trim()?.length > 255 &&
                        "Ensure Account Number has no more than 255 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>IBAN</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter IBAN"
                  type="text"
                  value={IBAN}
                  onChange={(e) => {
                    setIBAN(e.target.value);
                  }}
                  fullWidth
                  error={isValidated && (!IBAN || !IBAN.trim())}
                  helperText={
                    isValidated &&
                    (!IBAN || !IBAN.trim()) &&
                    "IBAN may not be blank."
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Paystack ID</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Paystack ID"
                  type="text"
                  value={paystackID}
                  onChange={(e) => {
                    setPaystackID(e.target.value || null);
                  }}
                  fullWidth
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Recipient Code</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Recipient Code"
                  type="text"
                  value={recipientCode}
                  onChange={(e) => {
                    setRecipientCode(e.target.value || null);
                  }}
                  fullWidth
                />
              </div>
            </div>

          </div>{" "}
          <div className={"block"}>
            <div className={"title"}>Job Information</div>
            <div className={"flex"}>
              <div className={"label"}>Job Title</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Job Title"
                  type="text"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                  fullWidth
                  error={
                    isValidated &&
                    (!jobTitle ||
                      !jobTitle.trim() ||
                      jobTitle.trim().length > 24)
                  }
                  helperText={
                    isValidated &&
                    (((!jobTitle || !jobTitle.trim()) &&
                      "Job Title may not be blank.") ||
                      (jobTitle.trim()?.length > 24 &&
                        "Ensure Job Title has no more than 24 characters."))
                  }
                />
              </div>
            </div>
            <div className={"flex"}>
              <div className={"label"}>Monthly Pay</div>
              <div className={"field"}>
                <CommonInput
                  placeholder="Enter Monthly Pay"
                  type="number"
                  value={monthlyPay}
                  allowDecimal={3}
                  onlyPositive={true}
                  onChange={(e) => {
                    setMonthlyPay(e.target.value);
                  }}
                  fullWidth
                  error={isValidated && (!monthlyPay || monthlyPay < 1)}
                  helperText={
                    isValidated &&
                    ((!monthlyPay && "Monthly Pay may not be blank.") ||
                      (monthlyPay < 1 &&
                        "Ensure that Monthly Pay is greater than or equal to 1."))
                  }
                />
              </div>
            </div>
            {/* {deductions?.length
              ? deductions.map((each) => (
                  <div className={"flex"} key={each.id}>
                    <div className={"label"}>{each.name}</div>
                    <div className={"field"}>
                      <CommonInput
                        placeholder={`Enter ${each.name}`}
                        type="number"
                        value={each.amount}
                        allowDecimal={3}
                        onlyPositive={true}
                        onChange={(e) => {
                          let temp = [...deductions];
                          for (let i = 0; i < temp.length; i++) {
                            if (temp[i].id === each.id) {
                              temp[i].amount = e.target.value;
                            }
                          }
                          setDeductions(temp);
                        }}
                        fullWidth
                      />
                    </div>
                  </div>
                ))
              : null} */}
          </div>
          <div className={"buttonContainer"}>
            <CommonButton
              type="submit"
              text="Save Changes"
              stylevariant="secondary1"
              style={{ width: "370px" }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditEmployeeDetail;
