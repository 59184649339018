import moment from "moment/moment";

export function moneyValue(value) {
  if (!value) {
    return 0;
  }

  const roundedValue = Math.round(value * 1000) / 1000;
  return roundedValue.toLocaleString();
}

export function getCleanMonth(date) {
  return moment(date).format('MMMM YYYY')
}

export function getIntentDisplay(intent) {
  switch (intent) {
    case "TRANSFER":
      return "Salary Transfer"
    case "WITHDRAW":
      return "Withdrawal"
    default:
      return intent
  }
}

export function downloadFile(data, filename)  {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.remove()
  }
