import { createGlobalStyle } from "styled-components";
import theme from "../theme";

export const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  background-color: ${theme.colors.White} !important;
  color: ${theme.colors.Violet};
  font-family: ${theme.fontFamily.MARKPRO};
}
.MuiFormHelperText-contained {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.MuiButton-contained {
  box-shadow: none !important;
}
.error-container {
  color: ${theme.colors.RadicalRed};
  padding: 10px;
  margin-top: 15px;
  text-align: left;
  font-family: ${theme.fontFamily.MARKPRO};
  border-radius: 5px;
  margin-bottom: 14px;
  background-color: ${theme.colors.Cinderella};
}
.success-container {
  color: ${theme.colors.Violet};
  padding: 10px;
  margin-top: 15px;
  text-align: left;
  font-family: ${theme.fontFamily.MARKPRO};
  border-radius: 5px;
  margin-bottom: 14px;
  background-color: ${theme.colors.MintGreen};
}
.pagination {
    display: flex;
    justify-content: center;
    aligncitems: center;
    margin-top: 35px;
},
.MuiPaginationItem-root {
  font-family: ${theme.fontFamily.MARKPROBOOK};
  font-size: 13px
}
.error-message{
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.tool_tip{
  top: -45px;
  color: ${theme.colors.Violet};
  padding: 5px 10px;
  position: absolute;
  font-size: 12px;
  font-family: MARKPROBOOK;
  border-radius: 8px;
  background-color: ${theme.colors.Zumthor};
}
.inactive-label{
  color:red;
}
.gray-text{
  color: lightslategray;
}
`;
