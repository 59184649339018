import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";
import theme from "../../theme";
var fileUploader;

const useStyles = makeStyles((innertheme) => ({
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px 30px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "70%",
    [innertheme.breakpoints.up("sm")]: {
      width: "400px",
      padding: "40px 60px",
    },
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  logoLabel: {
    color: theme.colors.Violet,
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
    marginBottom: "5px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
}));

function CompanyRegistrationStep1({
  setStep,
  companyName,
  setCompanyName,
  totalEmployees,
  setTotalEmployees,
  file,
  setFile,
  isValidated,
  setIsValidated,
  errors,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (errors && errors.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [errors]);

  return (
    <div className={classes.formContainer}>
      <div className={classes.formTitle}>Tell us about your company</div>
      {errors && errors.length ? (
        <div className={"error-container"}>
          <div>You have the following errors, please fix them.</div>
          <br />
          {errors.map((each, index) => (
            <div key={index}>
              {each === "Resource not found."
                ? "Token does not exist or has expired."
                : each}
            </div>
          ))}
        </div>
      ) : null}
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsValidated(true);
          if (companyName && totalEmployees && totalEmployees >= 1) {
            setStep(2);
            setIsValidated(false);
          }
        }}
      >
        <CommonInput
          label="Company Name"
          placeholder="Company Name"
          type="text"
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          error={isValidated && !companyName}
          helperText={
            isValidated && !companyName && "Company Name may not be blank."
          }
        />
        <CommonInput
          label="Total Number of Employees"
          placeholder="Enter Total Number of Employees"
          type="number"
          value={totalEmployees}
          onChange={(e) => {
            setTotalEmployees(e.target.value);
          }}
          error={isValidated && (!totalEmployees || totalEmployees < 1)}
          helperText={
            isValidated &&
            (!totalEmployees || totalEmployees < 1) &&
            "Total Number of Employees may not be blank."
          }
          min={1}
        />
        <div>
          <div className={classes.logoLabel}>Company Logo</div>
          <div style={{ marginBottom: "20px" }}>
            <CommonButton
              stylevariant="secondary2"
              type="button"
              text={"Upload Company Logo"}
              onClick={() => {
                fileUploader.click();
              }}
              icon={"/images/upload.png"}
            />
            <input
              type="file"
              ref={(ref) => (fileUploader = ref)}
              style={{ display: "none" }}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              accept="image/*"
            />
            {file?.name ? (
              <span className={classes.fileContainer}>
                <span className={classes.fileName}>{file.name}</span>
                <img
                  src={"/images/cross.png"}
                  alt="cross"
                  className={classes.crossIcon}
                  onClick={(e) => {
                    setFile(null);
                  }}
                />
              </span>
            ) : null}
          </div>
        </div>
        <CommonButton
          type="submit"
          text="Next"
          stylevariant="primary"
          style={{ marginTop: "30px" }}
        />
      </form>
    </div>
  );
}

export default CompanyRegistrationStep1;
