import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import CommonInput from "../input";
import {
  getOvertimeInfoAction,
  approveOvertimeAction,
} from "../../containers/companyDashboard/action";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  modalPaper: {
    position: "absolute",
  },
  container: {
    width: "360px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "40px 60px",
  },
  title: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subTitle: {
    color: theme.colors.DodgerBlue,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "20px",
  },
  secondTabtitle: {
    color: theme.colors.Violet,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
    marginBottom: "50px",
    textAlign: "center",
  },
  block: { marginBottom: "20px" },
  supportText: {
    fontSize: "12px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    marginTop: "15px",
    "& .label": { position: "relative", top: -4, marginLeft: "5px" },
  },
  label: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  value: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  currency: {
    fontSize: "12px",
    position: "relative",
    bottom: "4px",
    marginRight: "5px",
  },
  amountContainer: {
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
    backgroundColor: theme.colors.Zumthor,
    padding: "20px",
    borderRadius: "10px",
  },
  amountLabel: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  amount: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
  buttonContainer: {
    // marginTop: "50px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
  cancelLink: {
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    color: theme.colors.DodgerBlue,
    textAlign: "center",
    "& span": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

function ApproveOvertimeModal({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getOvertimeInfoMethod = useCallback(
    (overtime_id) => dispatch(getOvertimeInfoAction(overtime_id)),
    [dispatch]
  );

  const approveOvertimeMethod = useCallback(
    (overtime_id, data) => dispatch(approveOvertimeAction(overtime_id, data)),
    [dispatch]
  );

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  useEffect(() => {
    if (open) {
      getOvertimeInfoMethod(open);
    }
  }, [getOvertimeInfoMethod, open]);

  useEffect(() => {
    const { approveSuccess, approveError } = companyDashboardReducer;
    if (approveSuccess) {
      setStep(2);
    }
    if (approveError) {
      setStep(3);
    }
  }, [companyDashboardReducer]);

  const [modalStyle] = useState(getModalStyle);
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(null);

  return (
    <Modal
      open={Boolean(open)}
      onClose={() => {
        setOpen(null);
        setStep(1);
        setAmount(null);
      }}
    >
      <div style={modalStyle} className={classes.modalPaper}>
        {step === 1 ? (
          <div className={classes.container}>
            <div className={classes.subText}>
              Are you sure you want to approve the following employee’s overtime
              request?
            </div>
            <div className={classes.block}>
              <div className={classes.label}>Employee Name</div>
              <div className={classes.value}>
                {companyDashboardReducer?.overtimeInfo?.data?.employee?.name
                  ? companyDashboardReducer.overtimeInfo.data.employee.name
                  : "N/A"}{" "}
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.label}>Company Name</div>
              <div className={classes.value}>
                {loginReducer.companyInformation?.name}
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.label}>Date</div>
              <div className={classes.value}>
                {companyDashboardReducer?.overtimeInfo?.data?.date
                  ? companyDashboardReducer.overtimeInfo.data.date
                  : "N/A"}
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.label}>Hours Performed</div>
              <div className={classes.value}>
                {companyDashboardReducer?.overtimeInfo?.data?.duration
                  ? companyDashboardReducer.overtimeInfo.data.duration.substr(
                      0,
                      5
                    )
                  : "0"}
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.label}>Amount</div>
              <div className={classes.value}>
                <CommonInput
                  placeholder="Enter Amount"
                  type="number"
                  value={amount}
                  allowDecimal={3}
                  onlyPositive={true}
                  onChange={(e) => {
                    if (parseFloat(e.target.value, 10) >= 0 || !e.target.value)
                      setAmount(e.target.value);
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.buttonContainer}>
              <CommonButton
                type="button"
                text={"Approve"}
                stylevariant="secondary1"
                disabled={!amount || parseFloat(amount, 10) <= 0}
                onClick={() => {
                  approveOvertimeMethod(
                    companyDashboardReducer?.overtimeInfo?.data?.id,
                    {
                      amount: parseFloat(amount, 10),
                    }
                  );
                }}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
              <CommonButton
                type="button"
                text={"Cancel"}
                stylevariant="secondary2"
                disabled={false}
                onClick={() => {
                  setOpen(null);
                  setStep(1);
                  setAmount(null);
                }}
              />
            </div>
          </div>
        ) : step === 2 ? (
          <div className={classes.container}>
            <div className={classes.imageContainer}>
              <img src="/images/DoneIcon.svg" alt="DoneIcon" />
            </div>
            <div className={classes.secondTabtitle}>
              Overtime request approved
            </div>
            <div className={classes.buttonContainer}>
              <CommonButton
                type="button"
                text={"Done"}
                stylevariant="secondary1"
                disabled={false}
                onClick={() => {
                  setOpen(null);
                  setStep(1);
                  setAmount(null);
                }}
              />
            </div>
          </div>
        ) : step === 3 && companyDashboardReducer?.approveError?.length ? (
          <div className={classes.container}>
            <div className={classes.imageContainer}>
              <img src="/images/warning.svg" alt="warning" />
            </div>

            <div className={classes.secondTabtitle}>
              {companyDashboardReducer.approveError.map((each, index) => (
                <div key={index}>{each}</div>
              ))}
            </div>

            <div className={classes.buttonContainer}>
              <CommonButton
                type="button"
                text={"Done"}
                stylevariant="secondary1"
                disabled={!amount || parseFloat(amount, 10) <= 0}
                onClick={() => {
                  setOpen(null);
                  setStep(1);
                  setAmount(null);
                  // approveOvertimeMethod(
                  //   companyDashboardReducer?.overtimeInfo?.data?.id,
                  //   {
                  //     amount: parseFloat(amount, 10),
                  //   }
                  // );
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default ApproveOvertimeModal;
