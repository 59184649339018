import createReducer from "../../reducers/createReducer"
import {
    GET_REPORTS_SUCCESS,
    GET_REPORTS_LOADING,
    GET_REPORTS_ERROR,
    DOWNLOAD_REPORTS_LIST_START,
    DOWNLOAD_REPORTS_LIST_COMPLETE,
} from "./types"


let initialState = {
    loading: false,
    reportsList: [],
    success: null,
    error: null,
    filters: {
        employee_id: "",
        start_date: null,
        end_date: null,
    },
    isDownloadStarted: false,

};


export const reportsReducer = createReducer(initialState, {
    [GET_REPORTS_LOADING](state, action) {
        return Object.assign({}, state, {
            loading: true,
            reportsList: [],
            success: null,
            error: null,
            filters: action.payload.filters,

        });
    },
    [GET_REPORTS_SUCCESS](state, action) {
        return Object.assign({}, state, {
            loading: false,
            reportsList: action.payload,
            error: null,
        });
    },
    [GET_REPORTS_ERROR](state, action) {
        return Object.assign({}, state, {
            loading:false,
            reportsList: [],
            error: action.payload,
        });
    },
    [DOWNLOAD_REPORTS_LIST_START](state) {
        return Object.assign({}, state, {
            isDownloadStarted: true,
        });
    },
    [DOWNLOAD_REPORTS_LIST_COMPLETE](state) {
        return Object.assign({}, state, {
            isDownloadStarted: false,
        });
    },

});