import {
  PAYMENT_LIST_ACTION,
  GET_PAYMENT_ACTION,
  SETTLE_PAYMENT_ACTION,
  COMPANY_LIST_ACTION,
  GET_COMPANY_ACTION,
  EMPLOYEE_LIST_ACTION,
  GET_EMPLOYEE_ACTION,
  OVERVIEW_ACTION,
  INVITE_COMPANY_ACTION,
  UPDATE_COMPANY_ACTION,
  BULK_MONTHS_TO_SETTLE_ACTION,
  MONTHS_TO_SETTLE_ACTION,
  SETTLE_SALARY_ACTION,
  BULK_SETTLE_SALARY_ACTION,
  EXCHANGE_LIST_ACTION,
  GET_EXCHANGE_ACTION,
  CREATE_EXCHANGE_ACTION,
  UPDATE_EXCHANGE_ACTION,
  DELETE_EXCHANGE_ACTION,
  BACKOFFICE_DEDUCTION_LIST_ACTION,
  GET_DEDUCTION_ACTION,
  CREATE_DEDUCTION_ACTION,
  UPDATE_DEDUCTION_ACTION,
  DELETE_DEDUCTION_ACTION,
  OVERVIEW_FILTER_ACTION,
  TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_ACTION,
  TRANSACTIONS_OVERTIME_REQUESTS_FILTER_ACTION,
  TRANSACTION_HISTORY_ACTION,
  BACKOFFICE_COUNTRY_FEES_LIST_ACTION,
  CREATE_COUNTRY_FEES_ACTION,
  UPDATE_COUNTRY_FEES_ACTION, PAYMENT_DOWNLOAD_ACTION, PAYMENT_DOWNLOAD_RESET_ACTION,
} from "./types";

export const getPaymentListAction = (data) => ({
  type: PAYMENT_LIST_ACTION,
  payload: data,
});

export const getPaymentDownloadAction = (company_id) => ({
  type: PAYMENT_DOWNLOAD_ACTION,
  company_id,
});
export const resetPaymentDownloadAction = () => ({
  type: PAYMENT_DOWNLOAD_RESET_ACTION,
});

export const getPaymentInfoAction = (payment_id) => ({
  type: GET_PAYMENT_ACTION,
  payment_id: payment_id,
});

export const settlePaymentAction = (data, payment_id) => ({
  type: SETTLE_PAYMENT_ACTION,
  payload: data,
  payment_id: payment_id,
});

export const getBulkMonthsToSettleAction = (country) => ({
  type: BULK_MONTHS_TO_SETTLE_ACTION,
  country,
});

export const getMonthsToSettleAction = (employee_id) => ({
  type: MONTHS_TO_SETTLE_ACTION,
  employee_id,
});

export const settleSalaryAction = (employee_id, month = {}) => ({
  type: SETTLE_SALARY_ACTION,
  employee_id,
  month,
});

export const bulkSettleSalaryAction = (employees_ids, month) => ({
  type: BULK_SETTLE_SALARY_ACTION,
  employees_ids,
  month,
});

export const getCompanyListAction = (data) => ({
  type: COMPANY_LIST_ACTION,
  payload: data,
});

export const getCompanyInfoAction = (company_id) => ({
  type: GET_COMPANY_ACTION,
  company_id: company_id,
});

export const getEmployeeListAction = (data) => ({
  type: EMPLOYEE_LIST_ACTION,
  payload: data,
});

export const getEmployeeInfoAction = (employee_id) => ({
  type: GET_EMPLOYEE_ACTION,
  employee_id: employee_id,
});

export const getOverviewAction = (data) => ({
  type: OVERVIEW_ACTION,
  payload: data,
});

export const inviteCompanyAction = (data) => ({
  type: INVITE_COMPANY_ACTION,
  payload: data,
});

export const updateCompanyAction = (data) => ({
  type: UPDATE_COMPANY_ACTION,
  payload: data,
});

export const getExchangeListAction = (data) => ({
  type: EXCHANGE_LIST_ACTION,
  payload: data,
});

export const getExchangeInfoAction = (exchange_id) => ({
  type: GET_EXCHANGE_ACTION,
  exchange_id: exchange_id,
});

export const createExchangeAction = (data) => ({
  type: CREATE_EXCHANGE_ACTION,
  payload: data,
});

export const updateExchangeAction = (data, exchange_id) => ({
  type: UPDATE_EXCHANGE_ACTION,
  payload: data,
  exchange_id: exchange_id,
});

export const deleteExchangeAction = (exchange_id) => ({
  type: DELETE_EXCHANGE_ACTION,
  exchange_id: exchange_id,
});

export const getDeductionListAction = (data) => ({
  type: BACKOFFICE_DEDUCTION_LIST_ACTION,
  payload: data,
});

export const getDeductionInfoAction = (deduction_id) => ({
  type: GET_DEDUCTION_ACTION,
  deduction_id: deduction_id,
});

export const createDeductionAction = (data) => ({
  type: CREATE_DEDUCTION_ACTION,
  payload: data,
});

export const updateDeductionAction = (data, deduction_id) => ({
  type: UPDATE_DEDUCTION_ACTION,
  payload: data,
  deduction_id: deduction_id,
});

export const deleteDeductionAction = (deduction_id) => ({
  type: DELETE_DEDUCTION_ACTION,
  deduction_id: deduction_id,
});

export const setOverviewFilters = (data) => ({
  type: OVERVIEW_FILTER_ACTION,
  payload: data,
});

export const setWithdrawalRequestsFilters = (data) => ({
  type: TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_ACTION,
  payload: data,
});

export const setOvertimeRequestsFilters = (data) => ({
  type: TRANSACTIONS_OVERTIME_REQUESTS_FILTER_ACTION,
  payload: data,
});

export const getTransactionHistoryAction = (data) => ({
  type: TRANSACTION_HISTORY_ACTION,
  payload: data,
});

export const getCountriesFeesListAction = (data) => ({
  type: BACKOFFICE_COUNTRY_FEES_LIST_ACTION,
  payload: data,
});

export const createCountryFeesAction = (data) => ({
  type: CREATE_COUNTRY_FEES_ACTION,
  payload: data,
});

export const updateCountryFeesAction = (data) => ({
  type: UPDATE_COUNTRY_FEES_ACTION,
  payload: data,
});