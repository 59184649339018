import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import theme from "../../theme";

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: theme.colors.DodgerBlue,
    },
    "&$checked + $track": {
      backgroundColor: theme.colors.DodgerBlue,
    },
  },
  checked: {},
  track: {},
})(Switch);

function CommonSwitch(props) {
  return <CustomSwitch name="checkedA" {...props} />;
}

export default CommonSwitch;
