import { combineReducers } from "redux";
import * as loginReducer from "../containers/login/reducer";
import * as dashboardReducer from "../containers/dashboard/reducer";
import * as companyDashboardReducer from "../containers/companyDashboard/reducer";
import * as employeeRegistrationReducer from "../containers/employeeConfirmRegistration/reducer";
import * as onBoardCompanyReducer from "../containers/companyRegistration/reducer";
import * as reportsReducer from "../containers/reports/reducer";

export default combineReducers(
  Object.assign(
    loginReducer,
    dashboardReducer,
    companyDashboardReducer,
    employeeRegistrationReducer,
    onBoardCompanyReducer,
    reportsReducer
  )
);
