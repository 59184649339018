import { put, takeLatest, call } from "redux-saga/effects";
import Api from "./api";
import {
  ONBOARD_EMPLOYEES_ACTION,
  ONBOARD_EMPLOYEES_LOADING,
  ONBOARD_EMPLOYEES_SUCCESS,
  ONBOARD_EMPLOYEES_ERROR,
  INVITE_EMPLOYEES_ACTION,
  INVITE_EMPLOYEES_LOADING,
  INVITE_EMPLOYEES_SUCCESS,
  INVITE_EMPLOYEES_ERROR,
  UPDATE_EMPLOYEE_ACTION,
  UPDATE_EMPLOYEE_LOADING,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  OVERTIME_LIST_ACTION,
  OVERTIME_LIST_LOADING,
  OVERTIME_LIST_SUCCESS,
  OVERTIME_LIST_ERROR,
  GET_OVERTIME_ACTION,
  GET_OVERTIME_LOADING,
  GET_OVERTIME_SUCCESS,
  GET_OVERTIME_ERROR,
  APPROVE_OVERTIME_ACTION,
  APPROVE_OVERTIME_LOADING,
  APPROVE_OVERTIME_SUCCESS,
  APPROVE_OVERTIME_ERROR,
  REJECT_OVERTIME_ACTION,
  REJECT_OVERTIME_LOADING,
  REJECT_OVERTIME_SUCCESS,
  REJECT_OVERTIME_ERROR,
  LEAVE_LIST_ACTION,
  LEAVE_LIST_LOADING,
  LEAVE_LIST_SUCCESS,
  LEAVE_LIST_ERROR,
  CREATE_LEAVE_ACTION,
  CREATE_LEAVE_LOADING,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_ERROR,
  CREATE_PAYSTACK_PROFILE_LOADING,
  CREATE_PAYSTACK_PROFILE_ERROR,
  CREATE_PAYSTACK_PROFILE_SUCCESS,
  CREATE_PAYSTACK_PROFILE_ACTION,
} from "./types";

function* onBoardEmployeesSaga(data) {
  yield put({ type: ONBOARD_EMPLOYEES_LOADING });
  try {
    let res = yield call(Api.onBoardEmployees, data.payload);
    if (res.message) {
      yield put({ type: ONBOARD_EMPLOYEES_ERROR, payload: res.message });
    } else yield put({ type: ONBOARD_EMPLOYEES_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ONBOARD_EMPLOYEES_ERROR, payload: error.message });
  }
}

function* inviteEmployeesSaga(data) {
  yield put({ type: INVITE_EMPLOYEES_LOADING });
  try {
    let res = yield call(Api.inviteEmployees, data.payload);
    if (res.message) {
      yield put({ type: INVITE_EMPLOYEES_ERROR, payload: res.message });
    } else yield put({ type: INVITE_EMPLOYEES_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: INVITE_EMPLOYEES_ERROR, payload: error.message });
  }
}

function* updateEmployeeSaga(data) {
  yield put({ type: UPDATE_EMPLOYEE_LOADING });
  try {
    let res = yield call(Api.updateEmployee, data.payload, data.employee_id);
    if (res.message) {
      yield put({ type: UPDATE_EMPLOYEE_ERROR, payload: res.message });
    } else yield put({ type: UPDATE_EMPLOYEE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: UPDATE_EMPLOYEE_ERROR, payload: error.message });
  }
}

function* createPayStackProfileSaga(data) {
  yield put({ type: CREATE_PAYSTACK_PROFILE_LOADING });
  try {
    let res = yield call(Api.createPayStackProfile, data.payload.employee_id, {company_id: data.payload.company_id});
    if (res.message) {
      console.error(res)
      yield put({ type: CREATE_PAYSTACK_PROFILE_ERROR, payload: [res.details?.data] || res.message });
    } else yield put({ type: CREATE_PAYSTACK_PROFILE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CREATE_PAYSTACK_PROFILE_ERROR, payload: error.message });
  }
}

function* getOvertimeListSaga(data) {
  yield put({ type: OVERTIME_LIST_LOADING });
  try {
    let res = yield call(Api.getOvertimeList, data.payload);
    if (res.message) {
      yield put({ type: OVERTIME_LIST_ERROR, payload: res.message });
    } else yield put({ type: OVERTIME_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: OVERTIME_LIST_ERROR, payload: error.message });
  }
}

function* getOvertimeInfoSaga(data) {
  yield put({ type: GET_OVERTIME_LOADING });
  try {
    let res = yield call(Api.getOvertimeInfo, data.overtime_id);
    if (res.message) {
      yield put({ type: GET_OVERTIME_ERROR, payload: res.message });
    } else yield put({ type: GET_OVERTIME_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GET_OVERTIME_ERROR, payload: error.message });
  }
}

function* approveOvertimeSaga(data) {
  yield put({ type: APPROVE_OVERTIME_LOADING });
  try {
    let res = yield call(Api.approveOvertime, data.overtime_id, data.payload);
    if (res.message) {
      yield put({ type: APPROVE_OVERTIME_ERROR, payload: res.message });
    } else yield put({ type: APPROVE_OVERTIME_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: APPROVE_OVERTIME_ERROR, payload: error.message });
  }
}

function* rejectOvertimeSaga(data) {
  yield put({ type: REJECT_OVERTIME_LOADING });
  try {
    let res = yield call(Api.rejectOvertime, data.overtime_id, data.payload);
    if (res.message) {
      yield put({ type: REJECT_OVERTIME_ERROR, payload: res.message });
    } else yield put({ type: REJECT_OVERTIME_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: REJECT_OVERTIME_ERROR, payload: error.message });
  }
}

function* getLeaveListSaga(data) {
  yield put({ type: LEAVE_LIST_LOADING });
  try {
    let res = yield call(Api.getLeaveList, data.payload);
    if (res.message) {
      yield put({ type: LEAVE_LIST_ERROR, payload: res.message });
    } else yield put({ type: LEAVE_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: LEAVE_LIST_ERROR, payload: error.message });
  }
}

function* createLeaveSaga(data) {
  yield put({ type: CREATE_LEAVE_LOADING });
  try {
    let res = yield call(Api.createLeave, data.payload);
    if (res.message) {
      yield put({ type: CREATE_LEAVE_ERROR, payload: res.message });
    } else yield put({ type: CREATE_LEAVE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CREATE_LEAVE_ERROR, payload: error.message });
  }
}

function* watchCompanyDashboardAsync() {
  yield takeLatest(ONBOARD_EMPLOYEES_ACTION, onBoardEmployeesSaga);
  yield takeLatest(INVITE_EMPLOYEES_ACTION, inviteEmployeesSaga);
  yield takeLatest(UPDATE_EMPLOYEE_ACTION, updateEmployeeSaga);
  yield takeLatest(CREATE_PAYSTACK_PROFILE_ACTION, createPayStackProfileSaga);
  yield takeLatest(OVERTIME_LIST_ACTION, getOvertimeListSaga);
  yield takeLatest(GET_OVERTIME_ACTION, getOvertimeInfoSaga);
  yield takeLatest(APPROVE_OVERTIME_ACTION, approveOvertimeSaga);
  yield takeLatest(REJECT_OVERTIME_ACTION, rejectOvertimeSaga);
  yield takeLatest(LEAVE_LIST_ACTION, getLeaveListSaga);
  yield takeLatest(CREATE_LEAVE_ACTION, createLeaveSaga);
}

export default watchCompanyDashboardAsync;
