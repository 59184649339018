import {
  ONBOARD_COMPANY_INFO_ACTION,
  ONBOARD_COMPANY_VERIFY_ACTION,
  GET_FEES_SETTINGS_ACTION,
} from "./types";

export const onBoardCompanyInfoAction = (cid) => ({
  type: ONBOARD_COMPANY_INFO_ACTION,
  cid: cid,
});

export const onBoardCompanyVerifyAction = (data) => ({
  type: ONBOARD_COMPANY_VERIFY_ACTION,
  payload: data,
});

export const getFeesSettingsAction = (country) => ({
  type: GET_FEES_SETTINGS_ACTION,
  country: country,
});
