import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../theme";
import CommonDatePicker from "../datepicker";
import CommonTable from "../table";
import CommonSwitch from "../switch";
import AbsenseModal from "./absenseModal";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
    "& .dateContainer": {
      display: "flex",
      alignItems: "center",
      "& .datetitle": {
        color: theme.colors.DodgerBlue,
        fontFamily: theme.fontFamily.MARKPROMEDIUM,
        fontSize: "16px",
        marginRight: "10px",
      },
    },
  },
  tableContainer: {
    marginTop: "30px",
    "& .headerName": {
      fontSize: "13px",
      fontFamily: theme.fontFamily.MARKPROHEAVY,
      opacity: "0.3",
      color: theme.colors.Violet,
    },
  },
}));

function DailyAttendance() {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [tableData, setTableData] = useState([
    { id: 1, notes: "", isActive: true },
    { id: 2, notes: "", isActive: true },
    { id: 3, notes: "", isActive: true },
    { id: 4, notes: "", isActive: true },
    { id: 5, notes: "", isActive: true },
    { id: 6, notes: "", isActive: true },
    { id: 7, notes: "", isActive: true },
    { id: 8, notes: "", isActive: true },
    { id: 9, notes: "", isActive: false },
    { id: 10, notes: "", isActive: false },
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className="title">Daily Attendance</div>
        <div className="dateContainer">
          <div className="datetitle">Date</div>
          <CommonDatePicker
            noBorder
            startDate={startDate}
            setStartDate={(date) => {
              setStartDate(new Date(new Date(date).setHours(0, 0, 0, 0)));
            }}
          />
        </div>
      </div>
      <div className={classes.tableContainer}>
        <CommonTable
          fullRadius={true}
          columns={[
            {
              Header: "Employee Name",
              accessor: "id",
              width: "20%",
              HeaderCell: (data) => {
                return <span className={"headerName"}>{data}</span>;
              },
              Cell: (data, row, index) => {
                return "Employee Name " + row.id;
              },
            },
            {
              Header: "Date",
              accessor: "id",
              width: "10%",
              HeaderCell: (data) => {
                return <span className={"headerName"}>{data}</span>;
              },
              Cell: (data, row) => {
                return "01/07/2021";
              },
            },
            {
              Header: "Time",
              accessor: "id",
              width: "10%",
              HeaderCell: (data) => {
                return <span className={"headerName"}>{data}</span>;
              },
              Cell: (data, row) => {
                return "9:00 PM";
              },
            },
            {
              Header: "Notes",
              accessor: "id",
              width: "50%",
              HeaderCell: (data) => {
                return <span className={"headerName"}>{data}</span>;
              },
              Cell: (data, row, index) => {
                return row.isActive
                  ? ""
                  : row.notes
                  ? row.notes
                  : "Notes added if attendance is turned off";
              },
            },
            {
              Header: "Attendance",
              accessor: "id",
              width: "10%",
              HeaderCell: (data) => {
                return (
                  <div>
                    <span className={"headerName"}>{data}</span>
                    <CommonSwitch
                      name={data}
                      onChange={(e) => {
                        let temp = [...tableData];
                        for (let index = 0; index < temp.length; index++) {
                          temp[index].isActive = e.target.checked;
                        }
                        setTableData(temp);
                      }}
                    />
                  </div>
                );
              },
              Cell: (data, row, index) => {
                return (
                  <CommonSwitch
                    name={`Attendance${data}`}
                    checked={row.isActive}
                    onChange={() => {
                      if (row.isActive) setOpenModal(row.id);
                      else {
                        let temp = [...tableData];
                        temp[index].isActive = !temp[index].isActive;
                        setTableData(temp);
                      }
                    }}
                  />
                );
              },
            },
          ]}
          data={tableData}
        />
      </div>
      {tableData?.length ? (
        <div className={"pagination"}>
          <Pagination count={1} showFirstButton showLastButton />
        </div>
      ) : null}
      <AbsenseModal
        openModal={Boolean(openModal)}
        onClose={() => {
          setOpenModal(null);
        }}
        onSubmit={() => {
          let temp = [...tableData];
          temp = temp.map((each) => {
            if (each.id === openModal) {
              each.isActive = !each.isActive;
            }
            return each;
          });
          setTableData(temp);
        }}
        setTableData={setTableData}
      />
    </div>
  );
}

export default DailyAttendance;
