import React, { useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getCompanyAction,
  getCompaniesAction,
  getCountriesAction,
  getCurrenciesAction,
  getTimeZonesAction,
} from "../login/action";
import Header from "../../components/header";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  child: {
    backgroundColor: theme.colors.Porcelain,
    position: "relative",
    height: "calc(100vh - 85px)",
    overflow: "auto",
    marginTop: "85px",
    "&::-webkit-scrollbar-track": {
      background: theme.colors.Periwinkle,
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.DodgerBlue,
      "& :hover": {
        background: theme.colors.Periwinkle,
      },
    },

    "&::-webkit-scrollbar": {
      width: 8,
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contentRootEl = useRef(null);

  const getCompaniesMethod = useCallback(
    () => dispatch(getCompaniesAction()),
    [dispatch]
  );
  const getCountriesMethod = useCallback(
    () => dispatch(getCountriesAction()),
    [dispatch]
  );
  const getCurrenciesMethod = useCallback(
    () => dispatch(getCurrenciesAction()),
    [dispatch]
  );
  const getTimeZonesMethod = useCallback(
    () => dispatch(getTimeZonesAction()),
    [dispatch]
  );

  const getCompanyMethod = useCallback(
    () => dispatch(getCompanyAction()),
    [dispatch]
  );

  useEffect(() => {
    getCountriesMethod();
    getCurrenciesMethod();
    getTimeZonesMethod();
  }, [getCountriesMethod, getCurrenciesMethod, getTimeZonesMethod]);

  useEffect(() => {
    if (children && contentRootEl?.current) {
      contentRootEl.current.scrollTo(0, 0);
    }
  }, [contentRootEl, children]);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.is_super
    ) {
      getCompaniesMethod();
    }
  }, [getCompaniesMethod]);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.is_admin
    ) {
      getCompanyMethod();
    }
  }, [getCompanyMethod]);

  return (
    <div className="main-layout">
      <Header />
      <div className={classes.child} ref={contentRootEl}>
        <Route render={() => children} />
      </div>
    </div>
  );
};

export default MainLayout;
