/* eslint-disable import/no-anonymous-default-export */
import coreApi from "../../lib/coreApi";

export default {
  Login: (data) => {
    let url = `/auth/login`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getCompany: () => {
    let url = `/admin/company`;
    let result = coreApi.GET(url);
    return result;
  },
  refreshToken: (data) => {
    let url = `/auth/refresh-token`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getCompanies: () => {
    let url = `/backoffice/companies/list`;
    let result = coreApi.GET(url);
    return result;
  },
  getCountries: () => {
    let url = `/enumerates/countries`;
    let result = coreApi.GET(url);
    return result;
  },
  getCurrencies: () => {
    let url = `/enumerates/currencies`;
    let result = coreApi.GET(url);
    return result;
  },
  getTimeZones: () => {
    let url = `/enumerates/timezones`;
    let result = coreApi.GET(url);
    return result;
  },
  forgotPassword: (data) => {
    let url = `/auth/password/forgot`;
    let result = coreApi.POST(url, data);
    return result;
  },
  resetPassword: (data) => {
    let url = `/auth/password/confirm`;
    let result = coreApi.POST(url, data);
    return result;
  },
};
