import { put, takeLatest, call } from "redux-saga/effects";
import {
  ONBOARD_COMPANY_INFO_ACTION,
  ONBOARD_COMPANY_INFO_LOADING,
  ONBOARD_COMPANY_INFO_SUCCESS,
  ONBOARD_COMPANY_INFO_ERROR,
  ONBOARD_COMPANY_VERIFY_ACTION,
  ONBOARD_COMPANY_VERIFY_LOADING,
  ONBOARD_COMPANY_VERIFY_SUCCESS,
  ONBOARD_COMPANY_VERIFY_ERROR,
  GET_FEES_SETTINGS_ACTION,
  GET_FEES_SETTINGS_LOADING,
  GET_FEES_SETTINGS_SUCCESS,
  GET_FEES_SETTINGS_ERROR,
} from "./types";
import Api from "./api";
import { Constants } from "../../lib/constant";

function* onBoardCompanyInfoSaga(data) {
  yield put({ type: ONBOARD_COMPANY_INFO_LOADING });
  try {
    let res = yield call(Api.onBoardCompanyInfo, data.cid);
    if (res.message) {
      yield put({ type: ONBOARD_COMPANY_INFO_ERROR, payload: res.message });
    } else if (res && res.data) {
      yield put({ type: ONBOARD_COMPANY_INFO_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: ONBOARD_COMPANY_INFO_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: ONBOARD_COMPANY_INFO_ERROR, payload: error.message });
  }
}

function* onBoardCompanyVerifySaga(data) {
  yield put({ type: ONBOARD_COMPANY_VERIFY_LOADING });
  try {
    let res = yield call(Api.onBoardCompanyVerify, data.payload);

    if (res && res.message) {
      yield put({ type: ONBOARD_COMPANY_VERIFY_ERROR, payload: res.message });
    } else if (res && res.data) {
      yield put({ type: ONBOARD_COMPANY_VERIFY_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: ONBOARD_COMPANY_VERIFY_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: ONBOARD_COMPANY_VERIFY_ERROR, payload: error.message });
  }
}

function* getFeesSettingsSaga(data) {
  yield put({ type: GET_FEES_SETTINGS_LOADING });
  try {
    let res = yield call(Api.getFeesSettings, data.country);

    if (res && res.message) {
      yield put({ type: GET_FEES_SETTINGS_ERROR, payload: res.message });
    } else if (res && res.data) {
      yield put({ type: GET_FEES_SETTINGS_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: GET_FEES_SETTINGS_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: GET_FEES_SETTINGS_ERROR, payload: error.message });
  }
}

function* watchOnboradCompanyAsync() {
  yield takeLatest(ONBOARD_COMPANY_INFO_ACTION, onBoardCompanyInfoSaga);
  yield takeLatest(ONBOARD_COMPANY_VERIFY_ACTION, onBoardCompanyVerifySaga);
  yield takeLatest(GET_FEES_SETTINGS_ACTION, getFeesSettingsSaga);
}

export default watchOnboradCompanyAsync;
