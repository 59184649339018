import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import readXlsxFile from "read-excel-file";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import CommonButton from "../../components/button";
import { onBoardEmployeesAction } from "../../containers/companyDashboard/action";
import Loading from "../loading";

var fileDownloader, fileUploader;
const headers = [
    "First Name",
    "Last Name",
    "Gender",
    "Employee ID",
    "Email",
    "Phone number",
    "Title",
    "Full Salary",
    "Savings",
    "Account holder name",
    "Bank Name",
    "Bank Code",
    "Account number",
    "Branch",
    "IBAN",
  ],
  isString = new RegExp(/^[a-zA-Z\s]*$/g),
  isNumber = new RegExp(/((\d+)((\.\d{1,2})?))$/g),
  isEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0px",
  },
  container: {
    width: "350px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "50px 60px",
  },
  title: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "10px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
  bingoImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "40px",
  },
  modalPaper: {
    position: "absolute",
  },
}));

function InviteEmployees({ setInviteEmployees }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const { onBoardSuccess, error } = companyDashboardReducer;
    if (onBoardSuccess) {
      setOpenModal(true);
    }

    if (error) {
      if (file) {
        setFile(null);
      }
      if (Array.isArray(error)) {
        setErrors(error);
      } else {
        setErrors([error]);
      }
    } else {
      setErrors([]);
    }
  }, [companyDashboardReducer]);

  useEffect(() => {
    if (errors && errors.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [errors]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Sanaam - Invite Employees"}</title>
        <meta property="og:title" content={"Sanaam - Invite Employees"} />
      </Helmet>
      <Loading showLoader={companyDashboardReducer?.loading} />
      <div className={classes.container}>
        <div className={classes.title}>Onboarding Employees</div>
        <div className={classes.subText}>
          Download our template to import your
          <br /> employees and their data to Sanaam
        </div>
        <div style={{ marginBottom: "40px" }}>
          <CommonButton
            stylevariant="secondary2"
            type="button"
            text={"Download Template"}
            onClick={() => {
              fileDownloader.click();
            }}
            icon={"/images/download.png"}
          />
          <a
            ref={(ref) => (fileDownloader = ref)}
            href={"/Employees template list.xlsx"}
            download={`Employees template list`}
            style={{ display: "none" }}
          >
            download
          </a>
        </div>
        <div className={classes.subText}>
          After downloading and filling our
          <br /> template please upload it here
        </div>
        <div style={{ marginBottom: "40px" }}>
          <CommonButton
            stylevariant="secondary2"
            type="button"
            text={"Upload Employee Sheets"}
            onClick={() => {
              fileUploader.click();
            }}
            icon={"/images/upload.png"}
          />
          <input
            type="file"
            ref={(ref) => (fileUploader = ref)}
            style={{ display: "none" }}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={(e) => {
              setFile(e.target.files[0]);
              readXlsxFile(e.target.files[0]).then((rows) => {
                let array = [],
                  errorMessageArray = [];

                if (rows && rows.length > 1) {
                  for (let i = 1; i < rows.length; i++) {
                    let entry = {};
                    for (let j = 0; j < headers.length; j++) {
                      entry[headers[j]] = rows[i][j];
                    }
                    array.push(entry);
                  }

                  for (let i = 0; i < array.length; i++) {
                    const element = array[i];

                    if (
                      (!element["First Name"] ||
                        !element["First Name"].trim()) &&
                      errorMessageArray.indexOf(
                        "First Name may not be blank."
                      ) < 0
                    ) {
                      errorMessageArray.push("First Name may not be blank.");
                    } else if (
                      element["First Name"].trim() &&
                      element["First Name"].trim().length > 24 &&
                      errorMessageArray.indexOf(
                        "Ensure First Name has no more than 24 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure First Name has no more than 24 characters."
                      );
                    } else if (
                      element["First Name"].trim() &&
                      !element["First Name"]
                        .trim()
                        .toString()
                        .match(isString) &&
                      errorMessageArray.indexOf(
                        "First Name should be string only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "First Name should be string only."
                      );
                    }

                    if (
                      (!element["Last Name"] || !element["Last Name"].trim()) &&
                      errorMessageArray.indexOf("Last Name may not be blank.") <
                        0
                    ) {
                      errorMessageArray.push("Last Name may not be blank.");
                    } else if (
                      element["Last Name"].trim() &&
                      element["Last Name"].trim().length > 24 &&
                      errorMessageArray.indexOf(
                        "Ensure Last Name has no more than 24 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure Last Name has no more than 24 characters."
                      );
                    } else if (
                      element["Last Name"].trim() &&
                      !element["Last Name"].trim().toString().match(isString) &&
                      errorMessageArray.indexOf(
                        "Last Name should be string only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Last Name should be string only."
                      );
                    }

                    if (
                      (!element["Gender"] || !element["Gender"].trim()) &&
                      errorMessageArray.indexOf("Gender may not be blank.") < 0
                    ) {
                      errorMessageArray.push("Gender may not be blank.");
                    } else if (
                      element["Gender"].trim() &&
                      !(
                        element["Gender"].trim() === "M" ||
                        element["Gender"].trim() === "F"
                      )
                    ) {
                      errorMessageArray.push("Gender should be M/F only.");
                    }

                    if (
                      (!element["Email"] || !element["Email"].trim()) &&
                      errorMessageArray.indexOf("Email may not be blank.") < 0
                    ) {
                      errorMessageArray.push("Email may not be blank.");
                    } else if (
                      element["Email"].trim() &&
                      !element["Email"].trim().toString().match(isEmail) &&
                      errorMessageArray.indexOf(
                        "Email should be in proper format."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Email should be in proper format."
                      );
                    }

                    if (
                      (!element["Phone number"] ||
                        !element["Phone number"].trim()) &&
                      errorMessageArray.indexOf(
                        "Phone number may not be blank."
                      ) < 0
                    ) {
                      errorMessageArray.push("Phone number may not be blank.");
                    }

                    if (
                      (!element["Title"] || !element["Title"].trim()) &&
                      errorMessageArray.indexOf("Job Title may not be blank.") <
                        0
                    ) {
                      errorMessageArray.push("Job Title may not be blank.");
                    } else if (
                      element["Title"].trim() &&
                      element["Title"].trim().length > 24 &&
                      errorMessageArray.indexOf(
                        "Ensure Job Title has no more than 24 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure Job Title has no more than 24 characters."
                      );
                    } else if (
                      element["Title"].trim() &&
                      !element["Title"].trim().toString().match(isString) &&
                      errorMessageArray.indexOf(
                        "Job Title should be string only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Job Title should be string only."
                      );
                    }

                    if (
                      !element["Full Salary"] &&
                      errorMessageArray.indexOf(
                        "Full Salary may not be blank."
                      ) < 0
                    ) {
                      errorMessageArray.push("Full Salary may not be blank.");
                    } else if (
                      element["Full Salary"] &&
                      !element["Full Salary"].toString().match(isNumber) &&
                      errorMessageArray.indexOf(
                        "Full Salary should be number only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Full Salary should be number only."
                      );
                    }

                    if (
                      !element["Savings"] &&
                      errorMessageArray.indexOf("Savings may not be blank.") < 0
                    ) {
                      errorMessageArray.push("Savings may not be blank.");
                    } else if (
                      element["Savings"] &&
                      !element["Savings"].toString().match(isNumber) &&
                      errorMessageArray.indexOf(
                        "Savings should be number only."
                      ) < 0
                    ) {
                      errorMessageArray.push("Savings should be number only.");
                    } else if (
                      element["Full Salary"] &&
                      element["Savings"] &&
                      element["Savings"] >= element["Full Salary"] &&
                      errorMessageArray.indexOf(
                        "Savings should be less than Full Salary."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Savings should be less than Full Salary."
                      );
                    }

                    if (
                      (!element["Account holder name"] ||
                        !element["Account holder name"].trim()) &&
                      errorMessageArray.indexOf(
                        "Account Holder Name may not be blank."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Account Holder Name may not be blank."
                      );
                    } else if (
                      element["Account holder name"].trim() &&
                      element["Account holder name"].trim().length > 80 &&
                      errorMessageArray.indexOf(
                        "Ensure Account Holder Name has no more than 80 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure Account Holder Name has no more than 80 characters."
                      );
                    } else if (
                      element["Account holder name"].trim() &&
                      !element["Account holder name"]
                        .trim()
                        .toString()
                        .match(isString) &&
                      errorMessageArray.indexOf(
                        "Account holder name should be string only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Account holder name should be string only."
                      );
                    }

                    if (
                      (!element["Bank Name"] || !element["Bank Name"].trim()) &&
                      errorMessageArray.indexOf("Bank Name may not be blank.") <
                        0
                    ) {
                      errorMessageArray.push("Bank Name may not be blank.");
                    } else if (
                      element["Bank Name"].trim() &&
                      element["Bank Name"].trim().length > 48 &&
                      errorMessageArray.indexOf(
                        "Ensure Bank Name has no more than 48 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure Bank Name has no more than 48 characters."
                      );
                    } else if (
                      element["Bank Name"].trim() &&
                      !element["Bank Name"].trim().toString().match(isString) &&
                      errorMessageArray.indexOf(
                        "Bank Name should be string only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Bank Name should be string only."
                      );
                    }

                    if (
                      (!element["Bank Code"] || !element["Bank Code"].trim()) &&
                      errorMessageArray.indexOf("Bank Code may not be blank.") <
                        0
                    ) {
                      errorMessageArray.push("Bank Code may not be blank.");
                    } else if (
                      element["Bank Code"].trim() &&
                      element["Bank Code"].trim().length > 6 &&
                      errorMessageArray.indexOf(
                        "Ensure Bank Code has no more than 6 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure Bank Code has no more than 6 characters."
                      );
                    }

                    if (
                      !element["Account number"] &&
                      errorMessageArray.indexOf(
                        "Account Number may not be blank."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Account Number may not be blank."
                      );
                    } else if (
                      element["Account number"] &&
                      element["Account number"].length > 255 &&
                      errorMessageArray.indexOf(
                        "Ensure Account number has no more than 255 characters."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Ensure Account number has no more than 255 characters."
                      );
                    } else if (
                      element["Account number"] &&
                      !element["Account number"].toString().match(isNumber) &&
                      errorMessageArray.indexOf(
                        "Account number should be number only."
                      ) < 0
                    ) {
                      errorMessageArray.push(
                        "Account number should be number only."
                      );
                    }

                    if (
                      (!element["IBAN"] ||
                        !element["IBAN"].toString().trim()) &&
                      errorMessageArray.indexOf("IBAN may not be blank.") < 0
                    ) {
                      errorMessageArray.push("IBAN may not be blank.");
                    }
                  }
                } else {
                  errorMessageArray.push("No data found.");
                }

                setErrors(errorMessageArray);
                if (array && array.length && !errorMessageArray.length) {
                  setEmployees(
                    array.map((each) => {
                      return {
                        email: each["Email"] && each["Email"].trim(),
                        first_name:
                          each["First Name"] && each["First Name"].trim(),
                        last_name:
                          each["Last Name"] && each["Last Name"].trim(),
                        gender: each["Gender"] && each["Gender"].trim(),
                        branch: each["Branch"] && each["Branch"].trim(),
                        employee_id: each["Employee ID"] && each["Employee ID"].trim(),
                        bank: {
                          account_holder:
                            each["Account holder name"] &&
                            each["Account holder name"].trim(),
                          name: each["Bank Name"] && each["Bank Name"].trim(),
                          code: each["Bank Code"] && each["Bank Code"].trim(),
                          number: each["Account number"],
                          iban: each["IBAN"] && each["IBAN"].toString().trim(),
                        },
                        profile: {
                          position: each["Title"] && each["Title"].trim(),
                          phone: each["Phone number"],
                          salary: each["Full Salary"],
                          savings: each["Savings"],
                        },
                      };
                    })
                  );
                }
                if (errorMessageArray.length) {
                  setFile(null);
                }
              });
            }}
            accept=".xlsx"
          />
          {file?.name ? (
            <span className={classes.fileContainer}>
              <span className={classes.fileName}>{file.name}</span>
              <img
                src={"/images/cross.png"}
                alt="cross"
                className={classes.crossIcon}
                onClick={(e) => {
                  setFile(null);
                }}
              />
            </span>
          ) : null}
          {errors && errors.length ? (
            <div className={"error-container"}>
              <div>
                You have the following errors, please fix them and re-upload the
                sheet again.
              </div>
              <br />
              {errors.map((each, index) => (
                <div key={index}>{each}</div>
              ))}
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "70px" }}>
          <CommonButton
            type="button"
            text={"Submit"}
            stylevariant="secondary1"
            disabled={!file?.name}
            onClick={() => {
              dispatch(onBoardEmployeesAction(employees));
            }}
          />
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setInviteEmployees(false);
          setFile(null);
        }}
      >
        <div style={modalStyle} className={classes.modalPaper}>
          <div className={classes.container}>
            <div className={classes.bingoImage}>
              <img src={"/images/success_bingo.svg"} alt="success_bingo" />
            </div>
            <div className={classes.subText} style={{ textAlign: "center" }}>
              Onboarding successful!
              <br /> Sanaam invitations have been
              <br /> sent to your employees.
            </div>
            <div style={{ marginTop: "70px" }}>
              <CommonButton
                type="button"
                text={"Done"}
                stylevariant="secondary1"
                onClick={() => {
                  setOpenModal(false);
                  setInviteEmployees(false);
                  setFile(null);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InviteEmployees;
