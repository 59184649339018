import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonSwitch from "../switch";
import history from "../../history";
import ConfirmSwitchModal from "../confirmSwitchModal";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  listContainer: {
    marginTop: "30px",
    "& .block": {
      cursor: "pointer",
      background: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "15px",
      padding: "15px 30px",
      display: "flex",
      marginBottom: "15px",
      "& .left": {
        flex: "0.4",
        display: "flex",
        alignItems: "center",
        "& .logoContainer": {
          height: "85px",
          width: "85px",
          borderRadius: "50%",
          border: `1px solid ${theme.colors.Zumthor}`,
          backgroundColor: theme.colors.White,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          },
        },
        "& .comInfoContainer": {
          marginLeft: "25px",
          color: theme.colors.Violet,
          "& .comName": {
            fontSize: "22px",
            fontFamily: theme.fontFamily.MARKPROBOLD,
            marginBottom: "10px",
          },
          "& .comAdmin": {
            fontSize: "12px",
            fontFamily: theme.fontFamily.MARKPRO,
            "& .comAdminName": {
              fontFamily: theme.fontFamily.MARKPROBOLD,
            },
          },
        },
      },
      "& .right": {
        flex: "0.6",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .empNumberContainer": {
          color: theme.colors.Violet,
          "& .label": {
            fontSize: "12px",
            fontFamily: theme.fontFamily.MARKPRO,
          },
          "& .val": {
            fontSize: "16px",
            fontFamily: theme.fontFamily.MARKPROHEAVY,
            "& .currency": {
              fontSize: "10px",
              position: "relative",
              bottom: "4px",
              marginRight: "2px",
            },
          },
        },
        "& .borderContainer": {
          border: `1px solid ${theme.colors.Zumthor}`,
          height: "57px",
          margin: "0px 5px",
        },
      },
    },
  },
}));

function CompanyList({ data, updateCompanyMethod }) {
  const classes = useStyles();

  const [confirmSwitch, setConfirmSwitch] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [switchRecord, setSwitchRecord] = useState(null);
  const [switchMethod, setSwitchMethod] = useState(null);

  useEffect(() => {
    if (
      confirmation === "yes" &&
      switchRecord &&
      switchRecord.id &&
      switchMethod
    ) {
      if (switchMethod === "updateCompanyMethod")
        updateCompanyMethod({
          company_id: switchRecord.id,
          data: {
            is_active: !switchRecord.is_active,
          },
        });
      setSwitchRecord(null);
      setSwitchMethod(null);
      setConfirmation(null);
    }
  }, [confirmation]);

  return (
    <div className={classes.listContainer}>
      {data && data.length
        ? data.map((each, index) => {
            return (
              <div
                className="block"
                key={index}
                onClick={() => {
                  history.push(
                    `/backoffice/dashboard/Companies?companyId=${each?.id}`
                  );
                  window.scrollTo(0, 0);
                }}
              >
                <div className="left">
                  <div className="logoContainer">
                    <img
                      src={
                        each?.image ? each.image : "/images/no-image-found.png"
                      }
                      alt="comLogo"
                    ></img>
                  </div>
                  <div className="comInfoContainer">
                    <div className="comName">{each?.name}</div>
                    {each?.admins?.length ? (
                      <div className="comAdmin">
                        Admin:
                        {each.admins.map((rec, index) => (
                          <span className="comAdminName" key={index}>
                            {rec?.first_name} {rec?.last_name}
                            {index < each.admins.length - 1 ? "," : ""}
                          </span>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="right">
                  <div className="empNumberContainer">
                    <div className="label">
                      Total Number of Registered Employees
                    </div>
                    <div className="val" style={{ padding: "10px 0px" }}>
                      {each?.registered_employees}
                    </div>
                  </div>
                  <div className="borderContainer" />
                  <div className="empNumberContainer">
                    <div className="label">Total Number of Employees</div>
                    <div className="val" style={{ padding: "10px 0px" }}>
                      {each?.total_employees}
                    </div>
                  </div>
                  <div className="borderContainer" />
                  <div className="empNumberContainer">
                    <div className="label">Total Monthly Net Salaries</div>
                    <div className="val" style={{ padding: "10px 0px" }}>
                      <span className={"currency"}>{each?.currency}</span>{" "}
                      {moneyValue(each?.net_salaries)}
                    </div>
                  </div>
                  <div className="borderContainer" />
                  <div className="empNumberContainer">
                    <div className="label">total Withdrawal Amount</div>
                    <div className="val" style={{ padding: "10px 0px" }}>
                      <span className={"currency"}>{each?.currency}</span>{" "}
                      {moneyValue(each?.withdrawals)}
                    </div>
                  </div>
                  <div className="borderContainer" />
                  <div className="empNumberContainer">
                    <div className="label">Account Status</div>
                    <div className="val">
                      <span style={{ fontSize: "12px" }}>Activated</span>
                      <CommonSwitch
                        name={each.id}
                        checked={each.is_active}
                        disabled={!updateCompanyMethod}
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmSwitch(true);
                          setSwitchRecord(each);
                          setSwitchMethod("updateCompanyMethod");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      <ConfirmSwitchModal
        openModal={confirmSwitch}
        setConfirmation={(confirmation) => {
          setConfirmSwitch((temp) => !temp);
          setConfirmation(confirmation);
        }}
      />
    </div>
  );
}

export default CompanyList;
