import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CommonInput from "../input";

function FeeRange({
  feeRangeTabNumber,
  feeRange,
  setFeeRange,
  isValidated,
  editFeeRangeData,
}) {
  const [fee, setFee] = useState("");
  const [start_range, setStartRange] = useState("");
  const [end_range, setEndRange] = useState("");

  useEffect(() => {
    let returnData = [...feeRange];
    let k = feeRangeTabNumber;
    if (start_range !== "") {
      returnData[k] = {
        ...returnData[feeRangeTabNumber],
        start_range: start_range,
      };
      setFeeRange(returnData);
    }
    if (end_range !== "") {
      returnData[k] = {
        ...returnData[feeRangeTabNumber],
        end_range: end_range,
      };
      setFeeRange(returnData);
    }
    if (fee !== "") {
      returnData[feeRangeTabNumber] = {
        ...returnData[feeRangeTabNumber],
        fee: fee,
      };
      setFeeRange(returnData);
    }
    // eslint-disable-next-line
  }, [start_range, end_range, fee]);

  useEffect(() => {
    if (editFeeRangeData) {
      let editData = editFeeRangeData[feeRangeTabNumber];
      if (editData?.start_range) {
        setStartRange(editData?.start_range);
      }
      if (editData?.end_range) {
        setEndRange(editData?.end_range);
      }
      if (editData?.fee) {
        setFee(editData?.fee);
      }
    }
  }, [editFeeRangeData, feeRangeTabNumber]);

  const removeStartValue = () => {
    let returnData = [...feeRange];
    if (returnData?.[feeRangeTabNumber].start_range) {
      delete returnData?.[feeRangeTabNumber].start_range;
      setFeeRange(returnData);
    }
  };

  const removeEndValue = () => {
    let returnData = [...feeRange];
    if (returnData?.[feeRangeTabNumber].end_range) {
      delete returnData?.[feeRangeTabNumber].end_range;
      setFeeRange(returnData);
    }
  };

  const removeFees = () => {
    let returnData = [...feeRange];
    if (returnData?.[feeRangeTabNumber].fee) {
      delete returnData?.[feeRangeTabNumber].fee;
      setFeeRange(returnData);
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={4} sm={4}>
          <CommonInput
            label={feeRangeTabNumber === 0 ? `From` : ``}
            placeholder={`Enter From`}
            type="number"
            value={start_range}
            allowDecimal={3}
            onlyPositive={true}
            onChange={(e) => {
              setStartRange(e.target.value);
              if (e.target.value === "") {
                removeStartValue();
              }
            }}
            error={isValidated && start_range === ""}
            helperText={
              isValidated &&
              start_range === "" &&
              "From value may not be blank."
            }
            onWheelCapture={(e) => {
              e.target.blur();
            }}
          />
        </Grid>
        <Grid item md={4} sm={4}>
          <CommonInput
            label={feeRangeTabNumber === 0 ? `To` : ``}
            placeholder={`Enter To`}
            type="number"
            allowDecimal={3}
            onlyPositive={true}
            value={end_range}
            onChange={(e) => {
              setEndRange(e.target.value);
              if (e.target.value === "") {
                removeEndValue();
              }
            }}
            error={isValidated && end_range === ""}
            helperText={
              isValidated && end_range === "" && "To value may not be blank."
            }
            onWheelCapture={(e) => {
              e.target.blur();
            }}
          />
        </Grid>
        <Grid item md={4} sm={4}>
          <CommonInput
            label={feeRangeTabNumber === 0 ? `Withdraw Fee` : ``}
            placeholder={`Enter Fee`}
            type="number"
            value={fee}
            allowDecimal={3}
            onlyPositive={true}
            onChange={(e) => {
              setFee(e.target.value);
              if (e.target.value === "") {
                removeFees();
              }
            }}
            error={isValidated && fee === ""}
            helperText={
              isValidated && fee === "" && "Withdraw Fees may not be blank."
            }
            onWheelCapture={(e) => {
              e.target.blur();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default FeeRange;
