import React, { useState } from "react";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../../theme";
import history from "../../history";
import { LogoutAction } from "../../containers/login/action";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    padding: "15px",
    boxShadow: "0px 0px 15px #CFD9EA99",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
    zIndex: "1299",
    top: 0,
  },
  left: { display: "flex", alignItems: "center" },
  logoContainer: {
    height: "58px",
    width: "78px",
    background: `transparent url('/images/logo_name.svg') 0% 0% no-repeat padding-box`,
    marginLeft: "20px",
    cursor: "pointer",
  },
  tabContainer: { display: "none", alignItems: "center", margin: "0px 50px" },
  tab: { display: "flex", alignItems: "center", marginRight: "25px" },
  tabText: {
    color: theme.colors.DodgerBlue,
    marginLeft: 5,
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  soonText: {
    textTransform: "uppercase",
    position: "absolute",
    backgroundColor: theme.colors.Violet,
    color: theme.colors.MintGreen,
    padding: "1px 2px",
    right: -12,
    top: 8,
    fontSize: "7px",
    opacity: "0.5",
    borderRadius: "2px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  userInfoContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  label: {
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    color: theme.colors.DodgerBlue,
  },
  value: {
    fontSize: "12px",
    fontFamily: theme.fontFamily.MARKPRO,
    color: theme.colors.DodgerBlue,
  },
  profilePic: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "Center",
  },
  menuText: {
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
    color: theme.colors.Violet,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: "5px 10px",
    borderRadius: "15px",
    marginTop: "15px",
    marginLeft: "-5px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: "5px",
  },
}))(MenuItem);

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const LogoutMethod = () => dispatch(LogoutAction());

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSetting, setOpenSetting] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div
          className={classes.logoContainer}
          onClick={() => {
            history.push("/");
          }}
        />
        <div className={classes.tabContainer}>
          <div className={classes.tab}>
            <div>
              <img src="/images/Employees Icon.svg" alt="Employees Icon" />
            </div>
            <div className={classes.tabText}>Employees</div>
          </div>
          <div className={classes.tab} style={{ position: "relative" }}>
            <div>
              <img src="/images/statistics.png" alt="employees" />
            </div>
            <div className={classes.tabText} style={{ opacity: "0.24" }}>
              Statistics
            </div>
            <div className={classes.soonText}>Soon</div>
          </div>
        </div>
      </div>
      <div className={classes.userInfoContainer}>
        <div style={{ marginRight: "20px" }}>
          <div className={classes.label}>Today’s Date</div>
          <div className={classes.value}>{moment().format("DD/MM/YYYY")}</div>
        </div>
        <div style={{ marginRight: "20px" }}>
          <img src="/images/bell_active.png" alt="bell" />
        </div>
        <div className={classes.userInfoContainer}>
          <div className={classes.profilePic}>
            <img src="/images/default_user.svg" alt="default_user" />
          </div>
          <div>
            <img
              src={"/images/chevron_dropdown_date.png"}
              alt="bell"
              height={25}
              width={25}
              onClick={handleClick}
              style={{
                cursor: "pointer",
                transform: anchorEl ? "rotate(180deg)" : "",
              }}
            />
          </div>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div
              style={{
                borderBottom: "1px solid #EBF2FE",
                padding: "0px 5px",
                paddingBottom: "10px",
              }}
            >
              <div
                className={classes.label}
                style={{ color: theme.colors.Violet }}
              >
                {JSON.parse(localStorage.getItem("user"))?.first_name}
                {JSON.parse(localStorage.getItem("user"))?.last_name}
              </div>
              <div
                className={classes.value}
                style={{ color: theme.colors.Violet }}
              >
                {JSON.parse(localStorage.getItem("user"))?.email}
              </div>
            </div>
            {JSON.parse(localStorage.getItem("user"))?.is_super ? (
              <StyledMenuItem
                onClick={() => {
                  setOpenSetting((temp) => !temp);
                }}
              >
                <img
                  src="/images/setting_dark.png"
                  alt="setting"
                  style={{
                    marginRight: 5,
                  }}
                />
                <span className={classes.menuText}>Settings</span>
              </StyledMenuItem>
            ) : null}
            {openSetting ? (
              <>
                <StyledMenuItem
                  onClick={() => {
                    history.push("/backoffice/dashboard/Exchanges");
                    setOpenSetting((temp) => !temp);
                    handleClose();
                  }}
                >
                  <span
                    className={classes.menuText}
                    style={{ paddingLeft: "30px", fontSize: "14px" }}
                  >
                    Exchanges
                  </span>
                </StyledMenuItem>
                {/* <StyledMenuItem
                  onClick={() => {
                    history.push("/backoffice/dashboard/Deductions");
                    setOpenSetting((temp) => !temp);
                    handleClose();
                  }}
                >
                  <span
                    className={classes.menuText}
                    style={{ paddingLeft: "30px", fontSize: "14px" }}
                  >
                    Deductions
                  </span>
                </StyledMenuItem> */}
                <StyledMenuItem
                  onClick={() => {
                    history.push("/backoffice/dashboard/Countries");
                    setOpenSetting((temp) => !temp);
                    handleClose();
                  }}
                >
                  <span
                    className={classes.menuText}
                    style={{ paddingLeft: "30px", fontSize: "14px" }}
                  >
                    Countries
                  </span>
                </StyledMenuItem>
              </>
            ) : null}
            <StyledMenuItem
              onClick={() => {
                LogoutMethod();
                localStorage.clear();
                history.push("/");
              }}
            >
              <img
                src="/images/logout.png"
                alt="setting"
                style={{ marginRight: 5 }}
              />
              <span className={classes.menuText}>Log out</span>
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </div>
    </div>
  );
}

export default Header;
