import createReducer from "../../reducers/createReducer";
import {
  SIGNING_LOADING,
  SIGNING_SUCCESS,
  SIGNING_ERROR,
  SIGN_OUT_SUCCESS,
  COMPANY_INFORMATION_LOADING,
  COMPANY_INFORMATION_SUCCESS,
  COMPANY_INFORMATION_ERROR,
  REFRESH_TOKEN_LOADING,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  COMPANY_ENUMERATE_LOADING,
  COMPANY_ENUMERATE_SUCCESS,
  COMPANY_ENUMERATE_ERROR,
  COUNTRY_ENUMERATE_LOADING,
  COUNTRY_ENUMERATE_SUCCESS,
  COUNTRY_ENUMERATE_ERROR,
  CURRENCY_ENUMERATE_LOADING,
  CURRENCY_ENUMERATE_SUCCESS,
  CURRENCY_ENUMERATE_ERROR,
  TIMEZONE_ENUMERATE_LOADING,
  TIMEZONE_ENUMERATE_SUCCESS,
  TIMEZONE_ENUMERATE_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./types";

let initialState = {
  loading: false,
  error: null,
  success: null,
  loginError: null,
  loginSuccess: null,
  forgotPassSuccess: null,
  resetPassSuccess: null,
  companyInformation: null,
  companyOptions: [],
  countryOptions: [],
  currencyOptions: [],
  timeZoneOptions: [],
};

export const loginReducer = createReducer(initialState, {
  [SIGNING_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      loginSuccess: null,
      forgotPassSuccess: null,
      resetPassSuccess: null,
      loginError: null,
    });
  },
  [SIGNING_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      loginSuccess: action.payload,
      loginError: null,
    });
  },
  [SIGNING_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      loginSuccess: null,
      loginError: action.payload,
    });
  },
  [SIGN_OUT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      companyOptions: [],
      countryOptions: [],
      currencyOptions: [],
      timeZoneOptions: [],
    });
  },
  [COMPANY_INFORMATION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      companyInformation: null,
      error: null,
    });
  },
  [COMPANY_INFORMATION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyInformation: action.payload,
      error: null,
    });
  },
  [COMPANY_INFORMATION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyInformation: null,
      error: action.payload,
    });
  },
  [REFRESH_TOKEN_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
    });
  },
  [REFRESH_TOKEN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [REFRESH_TOKEN_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [COMPANY_ENUMERATE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      companyOptions: [],
      error: null,
    });
  },
  [COMPANY_ENUMERATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyOptions: action.payload,
      error: null,
    });
  },
  [COMPANY_ENUMERATE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyOptions: [],
      error: action.payload,
    });
  },
  [COUNTRY_ENUMERATE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      countryOptions: null,
      error: null,
    });
  },
  [COUNTRY_ENUMERATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      countryOptions: action.payload,
      error: null,
    });
  },
  [COUNTRY_ENUMERATE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      countryOptions: null,
      error: action.payload,
    });
  },
  [CURRENCY_ENUMERATE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      currencyOptions: null,
      error: null,
    });
  },
  [CURRENCY_ENUMERATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      currencyOptions: action.payload,
      error: null,
    });
  },
  [CURRENCY_ENUMERATE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      currencyOptions: null,
      error: action.payload,
    });
  },
  [TIMEZONE_ENUMERATE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      timeZoneOptions: null,
      error: null,
    });
  },
  [TIMEZONE_ENUMERATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      timeZoneOptions: action.payload,
      error: null,
    });
  },
  [TIMEZONE_ENUMERATE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      timeZoneOptions: null,
      error: action.payload,
    });
  },
  [FORGOT_PASSWORD_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      forgotPassSuccess: null,
      resetPassSuccess: null,
      error: null,
    });
  },
  [FORGOT_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      forgotPassSuccess: action.payload,
      error: null,
    });
  },
  [FORGOT_PASSWORD_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      forgotPassSuccess: null,
      error: action.payload,
    });
  },
  [RESET_PASSWORD_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      resetPassSuccess: null,
      error: null,
    });
  },
  [RESET_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      resetPassSuccess: action.payload,
      error: null,
    });
  },
  [RESET_PASSWORD_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      resetPassSuccess: null,
      error: action.payload,
    });
  },
});
