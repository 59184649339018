export const SIGNING_ACTION = "SIGNING_ACTION";
export const SIGNING_LOADING = "SIGNING_LOADING";
export const SIGNING_SUCCESS = "SIGNING_SUCCESS";
export const SIGNING_ERROR = "SIGNING_ERROR";

export const SIGN_OUT_ACTION = "SIGN_OUT_ACTION";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

export const COMPANY_INFORMATION_ACTION = "COMPANY_INFORMATION_ACTION";
export const COMPANY_INFORMATION_LOADING = "COMPANY_INFORMATION_LOADING";
export const COMPANY_INFORMATION_SUCCESS = "COMPANY_INFORMATION_SUCCESS";
export const COMPANY_INFORMATION_ERROR = "COMPANY_INFORMATION_ERROR";

export const REFRESH_TOKEN_ACTION = "REFRESH_TOKEN_ACTION";
export const REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const COMPANY_ENUMERATE_ACTION = "COMPANY_ENUMERATE_ACTION";
export const COMPANY_ENUMERATE_LOADING = "COMPANY_ENUMERATE_LOADING";
export const COMPANY_ENUMERATE_SUCCESS = "COMPANY_ENUMERATE_SUCCESS";
export const COMPANY_ENUMERATE_ERROR = "COMPANY_ENUMERATE_ERROR";

export const COUNTRY_ENUMERATE_ACTION = "COUNTRY_ENUMERATE_ACTION";
export const COUNTRY_ENUMERATE_LOADING = "COUNTRY_ENUMERATE_LOADING";
export const COUNTRY_ENUMERATE_SUCCESS = "COUNTRY_ENUMERATE_SUCCESS";
export const COUNTRY_ENUMERATE_ERROR = "COUNTRY_ENUMERATE_ERROR";

export const CURRENCY_ENUMERATE_ACTION = "CURRENCY_ENUMERATE_ACTION";
export const CURRENCY_ENUMERATE_LOADING = "CURRENCY_ENUMERATE_LOADING";
export const CURRENCY_ENUMERATE_SUCCESS = "CURRENCY_ENUMERATE_SUCCESS";
export const CURRENCY_ENUMERATE_ERROR = "CURRENCY_ENUMERATE_ERROR";

export const TIMEZONE_ENUMERATE_ACTION = "TIMEZONE_ENUMERATE_ACTION";
export const TIMEZONE_ENUMERATE_LOADING = "TIMEZONE_ENUMERATE_LOADING";
export const TIMEZONE_ENUMERATE_SUCCESS = "TIMEZONE_ENUMERATE_SUCCESS";
export const TIMEZONE_ENUMERATE_ERROR = "TIMEZONE_ENUMERATE_ERROR";

export const FORGOT_PASSWORD_ACTION = "FORGOT_PASSWORD_ACTION";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_ACTION = "RESET_PASSWORD_ACTION";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
