import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, TextareaAutosize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import {
  getOvertimeInfoAction,
  rejectOvertimeAction,
} from "../../containers/companyDashboard/action";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  modalPaper: {
    position: "absolute",
  },
  container: {
    width: "360px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "10px",
    padding: "40px 60px",
    maxHeight: "80vh",
    overflow: "auto",
    "&::-webkit-scrollbar-track": {
      marginLeft: "10px",
      background: theme.colors.Periwinkle,
      borderRadius: "0px 10px 10px 0px",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.DodgerBlue,
      borderRadius: "0px 10px 10px 0px",
      "& :hover": {
        background: theme.colors.Periwinkle,
      },
    },

    "&::-webkit-scrollbar": {
      width: 8,
    },
  },
  title: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subTitle: {
    color: theme.colors.DodgerBlue,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "20px",
  },
  secondTabtitle: {
    color: theme.colors.Violet,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
    marginBottom: "50px",
    textAlign: "center",
  },
  block: { marginBottom: "20px" },
  supportText: {
    fontSize: "12px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    marginTop: "15px",
    "& .label": { position: "relative", top: -4, marginLeft: "5px" },
  },
  label: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  value: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  currency: {
    fontSize: "12px",
    position: "relative",
    bottom: "4px",
    marginRight: "5px",
  },
  amountContainer: {
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
    backgroundColor: theme.colors.Zumthor,
    padding: "20px",
    borderRadius: "10px",
  },
  amountLabel: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  amount: {
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
  },
  buttonContainer: {
    // marginTop: "50px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
  cancelLink: {
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    color: theme.colors.DodgerBlue,
    textAlign: "center",
    "& span": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  textArea: {
    width: "94%",
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
    borderRadius: "5px",
    padding: "10px",
    "&:focus-visible": {
      borderColor: theme.colors.DodgerBlue,
      outline: "none",
    },
  },
}));

function RejectOvertimeModal({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getOvertimeInfoMethod = useCallback(
    (overtime_id) => dispatch(getOvertimeInfoAction(overtime_id)),
    [dispatch]
  );

  const rejectOvertimeMethod = useCallback(
    (overtime_id, data) => dispatch(rejectOvertimeAction(overtime_id, data)),
    [dispatch]
  );

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  useEffect(() => {
    if (open) {
      getOvertimeInfoMethod(open);
    }
  }, [getOvertimeInfoMethod, open]);

  useEffect(() => {
    const { rejectSuccess, rejectError } = companyDashboardReducer;
    if (rejectSuccess) {
      setStep(2);
    }
    if (rejectError) {
      setStep(3);
    }
  }, [companyDashboardReducer]);

  const [modalStyle] = useState(getModalStyle);
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState(null);

  return (
    <Modal
      open={Boolean(open)}
      onClose={() => {
        setOpen(null);
        setStep(1);
        setReason(null);
      }}
    >
      <div style={modalStyle} className={classes.modalPaper}>
        {step === 1 ? (
          <div className={classes.container}>
            <div className={classes.subText}>
              {`Are you sure you want to reject ${
                companyDashboardReducer?.overtimeInfo?.data?.employee?.name
                  ? `${companyDashboardReducer.overtimeInfo.data.employee.name}’s`
                  : ""
              } overtime
              request?`}
            </div>
            <div className={classes.block}>
              <div className={classes.value}>
                <TextareaAutosize
                  className={classes.textArea}
                  minRows={3}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  style={{
                    borderColor:
                      !reason?.length || reason?.length <= 96
                        ? "#C5DAFE"
                        : "red",
                  }}
                  placeholder="Enter the reason of rejection"
                  fullWidth
                />
                {reason?.length > 96 ? (
                  <div className="error-message">
                    Maximum 96 characters allowed, exceding by{" "}
                    {reason?.length - 96}{" "}
                    {reason?.length - 96 > 1 ? "characters" : "character"}.
                  </div>
                ) : null}
              </div>
            </div>

            <div className={classes.buttonContainer}>
              <CommonButton
                type="button"
                text={"Reject"}
                stylevariant="secondary1"
                disabled={
                  !reason ||
                  (reason &&
                    (!reason.trim()?.length || reason.trim()?.length > 96))
                }
                onClick={() => {
                  rejectOvertimeMethod(
                    companyDashboardReducer?.overtimeInfo?.data?.id,
                    {
                      notes: reason,
                    }
                  );
                }}
                style={{ marginBottom: "20px" }}
              />
              <CommonButton
                type="button"
                text={"Cancel"}
                stylevariant="secondary2"
                disabled={false}
                onClick={() => {
                  setOpen(null);
                  setStep(1);
                  setReason(null);
                }}
              />
            </div>
          </div>
        ) : step === 2 ? (
          <div className={classes.container}>
            <div className={classes.imageContainer}>
              <img src="/images/DoneIcon.svg" alt="DoneIcon" />
            </div>
            <div className={classes.secondTabtitle}>
              Overtime request rejected
            </div>
            <div className={classes.buttonContainer}>
              <CommonButton
                type="button"
                text={"Done"}
                stylevariant="secondary1"
                disabled={false}
                onClick={() => {
                  setOpen(null);
                  setStep(1);
                  setReason(null);
                }}
              />
            </div>
          </div>
        ) : step === 3 && companyDashboardReducer?.rejectError?.length ? (
          <div className={classes.container}>
            <div className={classes.imageContainer}>
              <img src="/images/warning.svg" alt="warning" />
            </div>

            <div className={classes.secondTabtitle}>
              {companyDashboardReducer.rejectError.map((each, index) => (
                <div key={index}>{each}</div>
              ))}
            </div>

            <div className={classes.buttonContainer}>
              <CommonButton
                type="button"
                text={"Done"}
                stylevariant="secondary1"
                onClick={() => {
                  setOpen(null);
                  setStep(1);
                  setReason(null);
                  // rejectOvertimeMethod(
                  //   companyDashboardReducer?.overtimeInfo?.data?.id,
                  //   {
                  //     notes: reason,
                  //   }
                  // );
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default RejectOvertimeModal;
