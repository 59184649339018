import createReducer from "../../reducers/createReducer";
import {
  ONBOARD_EMPLOYEES_LOADING,
  ONBOARD_EMPLOYEES_SUCCESS,
  ONBOARD_EMPLOYEES_ERROR,
  INVITE_EMPLOYEES_LOADING,
  INVITE_EMPLOYEES_SUCCESS,
  INVITE_EMPLOYEES_ERROR,
  UPDATE_EMPLOYEE_LOADING,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  CREATE_PAYSTACK_PROFILE_LOADING,
  CREATE_PAYSTACK_PROFILE_SUCCESS,
  CREATE_PAYSTACK_PROFILE_ERROR,
  OVERTIME_LIST_LOADING,
  OVERTIME_LIST_SUCCESS,
  OVERTIME_LIST_ERROR,
  GET_OVERTIME_LOADING,
  GET_OVERTIME_SUCCESS,
  GET_OVERTIME_ERROR,
  APPROVE_OVERTIME_LOADING,
  APPROVE_OVERTIME_SUCCESS,
  APPROVE_OVERTIME_ERROR,
  REJECT_OVERTIME_LOADING,
  REJECT_OVERTIME_SUCCESS,
  REJECT_OVERTIME_ERROR,
  LEAVE_LIST_LOADING,
  LEAVE_LIST_SUCCESS,
  LEAVE_LIST_ERROR,
  CREATE_LEAVE_LOADING,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_ERROR,
} from "./types";
import {
  GET_EMPLOYEE_SUCCESS,
  EMPLOYEE_LIST_SUCCESS,
} from "../dashboard/types";

let initialState = {
  loading: false,
  error: null,
  approveSuccess: null,
  approveError: null,
  inviteSuccess: null,
  rejectSuccess: null,
  rejectError: null,
  onBoardSuccess: null,
  updateEmployeeSuccess: null,
  overtimeList: null,
  overtimeInfo: null,
  leaveList: null,
  createLeaveSuccess: null,
  createLeaveError: null,
};

export const companyDashboardReducer = createReducer(initialState, {
  [ONBOARD_EMPLOYEES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      onBoardSuccess: null,
      error: null,
    });
  },
  [ONBOARD_EMPLOYEES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardSuccess: action.payload,
      error: null,
    });
  },
  [ONBOARD_EMPLOYEES_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardSuccess: null,
      error: action.payload,
    });
  },
  [INVITE_EMPLOYEES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      inviteSuccess: null,
      error: null,
    });
  },
  [INVITE_EMPLOYEES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      inviteSuccess: action.payload,
      error: null,
    });
  },
  [INVITE_EMPLOYEES_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      inviteSuccess: null,
      error: action.payload,
    });
  },
  [GET_EMPLOYEE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateEmployeeSuccess: null,
      error: null,
    });
  },
  [EMPLOYEE_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardSuccess: null,
      updateEmployeeSuccess: null,
      error: null,
    });
  },
  [UPDATE_EMPLOYEE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      updateEmployeeSuccess: null,
      error: null,
    });
  },
  [UPDATE_EMPLOYEE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateEmployeeSuccess: action.payload,
      error: null,
    });
  },
  [UPDATE_EMPLOYEE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateEmployeeSuccess: null,
      error: action.payload,
    });
  },
  [CREATE_PAYSTACK_PROFILE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      updateEmployeeSuccess: null,
      error: null,
    });
  },
  [CREATE_PAYSTACK_PROFILE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateEmployeeSuccess: action.payload,
      error: null,
    });
  },
  [CREATE_PAYSTACK_PROFILE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateEmployeeSuccess: null,
      error: action.payload,
    });
  },
  [OVERTIME_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      overtimeList: null,
      approveSuccess: null,
      approveError: null,
      rejectSuccess: null,
      rejectError: null,
      error: null,
    });
  },
  [OVERTIME_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      overtimeList: action.payload,
      error: null,
    });
  },
  [OVERTIME_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      overtimeList: null,
      error: action.payload,
    });
  },
  [GET_OVERTIME_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      overtimeInfo: null,
      approveSuccess: null,
      approveError: null,
      rejectSuccess: null,
      rejectError: null,
      error: null,
    });
  },
  [GET_OVERTIME_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      overtimeInfo: action.payload,
      error: null,
    });
  },
  [GET_OVERTIME_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      overtimeInfo: null,
      error: action.payload,
    });
  },
  [APPROVE_OVERTIME_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      approveSuccess: null,
      approveError: null,
    });
  },
  [APPROVE_OVERTIME_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      approveSuccess: action.payload,
      approveError: null,
    });
  },
  [APPROVE_OVERTIME_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      approveSuccess: null,
      approveError: action.payload,
    });
  },
  [REJECT_OVERTIME_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      rejectSuccess: null,
      rejectError: null,
    });
  },
  [REJECT_OVERTIME_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      rejectSuccess: action.payload,
      rejectError: null,
    });
  },
  [REJECT_OVERTIME_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      rejectSuccess: null,
      rejectError: action.payload,
    });
  },
  [LEAVE_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      leaveList: null,
      createLeaveSuccess: null,
      createLeaveError: null,
      error: null,
    });
  },
  [LEAVE_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      leaveList: action.payload,
      error: null,
    });
  },
  [LEAVE_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      leaveList: null,
      error: action.payload,
    });
  },
  [CREATE_LEAVE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      createLeaveSuccess: null,
      createLeaveError: null,
    });
  },
  [CREATE_LEAVE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createLeaveSuccess: action.payload,
      createLeaveError: null,
    });
  },
  [CREATE_LEAVE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createLeaveSuccess: null,
      createLeaveError: action.payload,
    });
  },
});
