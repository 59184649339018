import createReducer from "../../reducers/createReducer";
import {
  ONBOARD_EMPLOYEE_INFO_LOADING,
  ONBOARD_EMPLOYEE_INFO_SUCCESS,
  ONBOARD_EMPLOYEE_INFO_ERROR,
  ONBOARD_EMPLOYEE_VERIFY_LOADING,
  ONBOARD_EMPLOYEE_VERIFY_SUCCESS,
  ONBOARD_EMPLOYEE_VERIFY_ERROR,
  DEDUCTION_LIST_LOADING,
  DEDUCTION_LIST_SUCCESS,
  DEDUCTION_LIST_ERROR,
} from "./types";

let initialState = {
  loading: false,
  error: null,
  onBoardEmploeeinfo: null,
  success: null,
  deductionList: [],
};

export const employeeRegistrationReducer = createReducer(initialState, {
  [ONBOARD_EMPLOYEE_INFO_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      onBoardEmploeeinfo: null,
      success: null,
      error: null,
    });
  },
  [ONBOARD_EMPLOYEE_INFO_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardEmploeeinfo: action.payload,
      error: null,
    });
  },
  [ONBOARD_EMPLOYEE_INFO_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      onBoardEmploeeinfo: null,
      error: action.payload,
    });
  },
  [ONBOARD_EMPLOYEE_VERIFY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [ONBOARD_EMPLOYEE_VERIFY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [ONBOARD_EMPLOYEE_VERIFY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [DEDUCTION_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      deductionList: [],
      error: null,
    });
  },
  [DEDUCTION_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deductionList: action.payload,
      error: null,
    });
  },
  [DEDUCTION_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deductionList: [],
      error: action.payload,
    });
  },
});
