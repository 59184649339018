import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import theme from "../../theme";
import CommonTable from "../table";
import CommonButton from "../button";
import ApproveOvertimeModal from "./approveModal";
import RejectOvertimeModal from "./rejectModal";
import { getOvertimeListAction } from "../../containers/companyDashboard/action";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  Tab: {
    position: "relative",
    top: "10px",
    left: "-22px",
    cursor: "pointer",
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px 25px",
      top: `50%`,
      left: `15%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
    },
    "& .count": {
      background: theme.colors.DodgerBlue,
      color: theme.colors.White,
      fontSize: "14px",
      borderRadius: "20px",
      textAlign: "center",
      height: "20px",
      lineHeight: "22px",
      padding: "2px 10px",
      marginLeft: "10px",
    },
  },
  tableContainer: {
    marginTop: "-20px",
  },
  currency: {
    fontSize: "8px",
    position: "relative",
    bottom: "3px",
    marginRight: "2px",
  },
  successTube: {
    color: "#22D559",
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    backgroundColor: "#E4FDEC",
    textAlign: "center",
    padding: "2px 8px",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

function OvertimeRequests() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getOvertimeListMethod = useCallback(
    (data) => dispatch(getOvertimeListAction(data)),
    [dispatch]
  );

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [openApprove, setOpenApprove] = useState(null);
  const [openReject, setOpenReject] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  useEffect(() => {
    getOvertimeListMethod({ page, page_size, state: "PENDING" });
  }, [getOvertimeListMethod, page, page_size]);

  useEffect(() => {
    const { overtimeList, rejectSuccess, approveSuccess } =
      companyDashboardReducer;
    if (rejectSuccess || approveSuccess) {
      getOvertimeListMethod({ page, page_size, state: "PENDING" });
    }
    if (overtimeList?.count) {
      setTotalPage(Math.ceil(overtimeList.count / page_size));
    }
  }, [companyDashboardReducer, page, page_size, getOvertimeListMethod]);

  useEffect(() => {
    if (companyDashboardReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [companyDashboardReducer]);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className="title">Requests</div>
      </div>
      {!openApprove && !openReject && companyDashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {companyDashboardReducer.error.map((each, index) => (
            <div key={index}>{each}</div>
          ))}
        </div>
      ) : null}
      {!companyDashboardReducer?.loading ? (
        <>
          <div className={classes.Tab}>
            <img src={"/images/Tab2.svg"} alt="withDrawTab"></img>

            <div className="block">
              <span className="tableTitle">
                Overtime Requests{" "}
                {companyDashboardReducer?.overtimeList?.count ? (
                  <span className="count">
                    {companyDashboardReducer?.overtimeList?.count}
                  </span>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <CommonTable
              columns={[
                {
                  Header: "Employee Name",
                  accessor: "employee",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    return (
                      <div>
                        {data?.name}{" "}
                        <span className="inactive-label">
                          {data?.is_active ? "" : " (Inactive)"}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  Header: "Time of request",
                  accessor: "created_at",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    return data ? moment(data).format("YYYY-DD-MM LT") : "N/A";
                  },
                },
                {
                  Header: "Date",
                  accessor: "date",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                },
                {
                  Header: "Hours Performed",
                  accessor: "duration",
                  HeaderCell: (data) => {
                    return (
                      <div className={classes.headerName}>
                        {data.substr(0, 5)}
                      </div>
                    );
                  },
                  Cell: (data) => {
                    return (
                      <div>
                        {data?.length
                          ? `${
                              data.substr(0, 2)?.length
                                ? `${data.substr(0, 2)}hr`
                                : ""
                            } ${
                              data.substr(3, 2)?.length
                                ? `${data.substr(3, 2)}min`
                                : ""
                            }`
                          : ""}
                      </div>
                    );
                  },
                },
                {
                  Header: "Action",
                  accessor: "id",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data) => {
                    return (
                      <div className={classes.flexContainer}>
                        <div style={{ margin: "0px 10px 0px 0px" }}>
                          <CommonButton
                            type="button"
                            text="Approve"
                            stylevariant="secondary3"
                            style={{ padding: "2px 15px" }}
                            fontSize="10px"
                            fontFamily={theme.fontFamily.MARKPROHEAVY}
                            onClick={() => {
                              setOpenApprove(data);
                            }}
                          />
                        </div>

                        <CommonButton
                          type="button"
                          text="Decline"
                          stylevariant="danger"
                          style={{ padding: "2px 15px" }}
                          fontSize="10px"
                          fontFamily={theme.fontFamily.MARKPROHEAVY}
                          onClick={() => {
                            setOpenReject(data);
                          }}
                        />
                      </div>
                    );
                  },
                },
              ]}
              data={companyDashboardReducer?.overtimeList?.data}
            />

            {companyDashboardReducer?.overtimeList?.data?.length ? (
              <div className={"pagination"}>
                <Pagination
                  page={page}
                  count={totalPage}
                  showFirstButton
                  showLastButton
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <ApproveOvertimeModal open={openApprove} setOpen={setOpenApprove} />
      <RejectOvertimeModal open={openReject} setOpen={setOpenReject} />
    </div>
  );
}

export default OvertimeRequests;
