import {
  SIGNING_ACTION,
  SIGN_OUT_ACTION,
  COMPANY_INFORMATION_ACTION,
  REFRESH_TOKEN_ACTION,
  COMPANY_ENUMERATE_ACTION,
  COUNTRY_ENUMERATE_ACTION,
  CURRENCY_ENUMERATE_ACTION,
  TIMEZONE_ENUMERATE_ACTION,
  FORGOT_PASSWORD_ACTION,
  RESET_PASSWORD_ACTION,
} from "./types";

export const LoginAction = (data, redirectURI) => ({
  type: SIGNING_ACTION,
  payload: data,
  redirectURI,
});

export const LogoutAction = () => ({
  type: SIGN_OUT_ACTION,
});

export const getCompanyAction = () => ({
  type: COMPANY_INFORMATION_ACTION,
});

export const refreshTokenAction = (data) => ({
  type: REFRESH_TOKEN_ACTION,
  payload: data,
});

export const getCompaniesAction = () => ({
  type: COMPANY_ENUMERATE_ACTION,
});

export const getCountriesAction = () => ({
  type: COUNTRY_ENUMERATE_ACTION,
});

export const getCurrenciesAction = () => ({
  type: CURRENCY_ENUMERATE_ACTION,
});

export const getTimeZonesAction = () => ({
  type: TIMEZONE_ENUMERATE_ACTION,
});

export const forgotPasswordAction = (data) => ({
  type: FORGOT_PASSWORD_ACTION,
  payload: data,
});

export const resetPasswordAction = (data) => ({
  type: RESET_PASSWORD_ACTION,
  payload: data,
});
