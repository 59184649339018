import createReducer from "../../reducers/createReducer";
import {
  PAYMENT_LIST_LOADING,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_ERROR,
  GET_PAYMENT_LOADING,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  MONTHS_TO_SETTLE_LOADING,
  MONTHS_TO_SETTLE_SUCCESS,
  MONTHS_TO_SETTLE_ERROR,
  BULK_MONTHS_TO_SETTLE_LOADING,
  BULK_MONTHS_TO_SETTLE_SUCCESS,
  BULK_MONTHS_TO_SETTLE_ERROR,
  SETTLE_PAYMENT_LOADING,
  SETTLE_PAYMENT_SUCCESS,
  SETTLE_PAYMENT_ERROR,
  SETTLE_SALARY_LOADING,
  SETTLE_SALARY_SUCCESS,
  SETTLE_SALARY_ERROR,
  BULK_SETTLE_SALARY_LOADING,
  BULK_SETTLE_SALARY_SUCCESS,
  BULK_SETTLE_SALARY_ERROR,
  COMPANY_LIST_LOADING,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_ERROR,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  EMPLOYEE_LIST_LOADING,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_ERROR,
  GET_EMPLOYEE_LOADING,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
  OVERVIEW_LOADING,
  OVERVIEW_SUCCESS,
  OVERVIEW_ERROR,
  INVITE_COMPANY_LOADING,
  INVITE_COMPANY_SUCCESS,
  INVITE_COMPANY_ERROR,
  UPDATE_COMPANY_LOADING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  EXCHANGE_LIST_LOADING,
  EXCHANGE_LIST_SUCCESS,
  EXCHANGE_LIST_ERROR,
  GET_EXCHANGE_LOADING,
  GET_EXCHANGE_SUCCESS,
  GET_EXCHANGE_ERROR,
  CREATE_EXCHANGE_LOADING,
  CREATE_EXCHANGE_SUCCESS,
  CREATE_EXCHANGE_ERROR,
  UPDATE_EXCHANGE_LOADING,
  UPDATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_ERROR,
  DELETE_EXCHANGE_LOADING,
  DELETE_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_ERROR,
  BACKOFFICE_DEDUCTION_LIST_LOADING,
  BACKOFFICE_DEDUCTION_LIST_SUCCESS,
  BACKOFFICE_DEDUCTION_LIST_ERROR,
  GET_DEDUCTION_LOADING,
  GET_DEDUCTION_SUCCESS,
  GET_DEDUCTION_ERROR,
  CREATE_DEDUCTION_LOADING,
  CREATE_DEDUCTION_SUCCESS,
  CREATE_DEDUCTION_ERROR,
  UPDATE_DEDUCTION_LOADING,
  UPDATE_DEDUCTION_SUCCESS,
  UPDATE_DEDUCTION_ERROR,
  DELETE_DEDUCTION_LOADING,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_ERROR,
  OVERVIEW_FILTER_SUCCESS,
  TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_SUCCESS,
  TRANSACTIONS_OVERTIME_REQUESTS_FILTER_SUCCESS,
  TRANSACTION_HISTORY_LOADING,
  TRANSACTION_HISTORY_SUCCESS,
  TRANSACTION_HISTORY_ERROR,
  BACKOFFICE_COUNTRY_FEES_LIST_LOADING,
  BACKOFFICE_COUNTRY_FEES_LIST_SUCCESS,
  BACKOFFICE_COUNTRY_FEES_LIST_ERROR,
  CREATE_COUNTRY_FEES_LOADING,
  CREATE_COUNTRY_FEES_SUCCESS,
  CREATE_COUNTRY_FEES_ERROR,
  UPDATE_COUNTRY_FEES_LOADING,
  UPDATE_COUNTRY_FEES_SUCCESS,
  UPDATE_COUNTRY_FEES_ERROR, PAYMENT_DOWNLOAD_LOADING, PAYMENT_DOWNLOAD_SUCCESS, PAYMENT_DOWNLOAD_ERROR,
} from "./types";
import { SIGN_OUT_SUCCESS } from "../login/types";
let initialState = {
  loading: false,
  error: null,
  success: null,
  inviteCompanyError: null,
  paymentList: [],
  paymentInfo: null,
  companyList: null,
  companyInfo: null,
  employeeList: [],
  employeeInfo: null,
  companyOverview: null,
  exchangeList: [],
  exchangeInfo: null,
  bulkMonthsToSettle: [],
  monthsToSettle: [],
  createExchangeSuccess: null,
  createExchangeError: null,
  updateExchangeSuccess: null,
  updateExchangeError: null,
  deleteExchangeSuccess: null,
  deleteExchangeError: null,
  deductionList: [],
  deductionInfo: null,
  createDeductionSuccess: null,
  createDeductionError: null,
  updateDeductionSuccess: null,
  updateDeductionError: null,
  deleteDeductionSuccess: null,
  deleteDeductionError: null,
  overviewFilters: null,
  withdrawalRequestsFilters: null,
  overtimeRequestsFilters: null,
  transactionHistory: [],
  countryFeesList: [],
  createCountryFeesSuccess: null,
  createCountryFeesError: null,
  updateCountryFeesSuccess: null,
  updateCountryFeesError: null,
};

export const dashboardReducer = createReducer(initialState, {
  [SIGN_OUT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      overviewFilters: null,
      withdrawalRequestsFilters: null,
      overtimeRequestsFilters: null,
    });
  },
  [PAYMENT_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      paymentList: [],
      error: null,
    });
  },
  [PAYMENT_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      paymentList: action.payload,
      error: null,
    });
  },
  [PAYMENT_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      paymentList: [],
      error: action.payload,
    });
  },
  [PAYMENT_DOWNLOAD_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      csv: null,
      filename: null,
      error: null,
    });
  },
  [PAYMENT_DOWNLOAD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      csv: action.payload,
      filename: action.filename,
      error: null,
    });
  },
  [PAYMENT_DOWNLOAD_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      csv: null,
      filename: null,
      error: action.payload,
    });
  },
  [GET_PAYMENT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      paymentInfo: null,
      error: null,
    });
  },
  [GET_PAYMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      paymentInfo: action.payload,
      error: null,
    });
  },
  [GET_PAYMENT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      paymentInfo: null,
      error: action.payload,
    });
  },
  [SETTLE_PAYMENT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [SETTLE_PAYMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [SETTLE_PAYMENT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [SETTLE_SALARY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [SETTLE_SALARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [SETTLE_SALARY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [BULK_SETTLE_SALARY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [BULK_SETTLE_SALARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [BULK_SETTLE_SALARY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [MONTHS_TO_SETTLE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      monthsToSettle: [],
      error: null,
    });
  },
  [MONTHS_TO_SETTLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      monthsToSettle: action.payload,
      error: null,
    });
  },
  [MONTHS_TO_SETTLE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      monthsToSettle: [],
      error: action.payload,
    });
  },
  [BULK_MONTHS_TO_SETTLE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      bulkMonthsToSettle: [],
      error: null,
    });
  },
  [BULK_MONTHS_TO_SETTLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      bulkMonthsToSettle: action.payload,
      error: null,
    });
  },
  [BULK_MONTHS_TO_SETTLE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      bulkMonthsToSettle: [],
      error: action.payload,
    });
  },
  [COMPANY_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      companyList: null,
      error: null,
    });
  },
  [COMPANY_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyList: action.payload,
      error: null,
    });
  },
  [COMPANY_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyList: null,
      error: action.payload,
    });
  },
  [GET_COMPANY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      companyInfo: null,
      error: null,
    });
  },
  [GET_COMPANY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyInfo: action.payload,
      error: null,
    });
  },
  [GET_COMPANY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyInfo: null,
      error: action.payload,
    });
  },
  [EMPLOYEE_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      employeeList: [],
      error: null,
    });
  },
  [EMPLOYEE_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      employeeList: action.payload,
      error: null,
    });
  },
  [EMPLOYEE_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      employeeList: [],
      error: action.payload,
    });
  },
  [GET_EMPLOYEE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      employeeInfo: null,
      error: null,
    });
  },
  [GET_EMPLOYEE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      employeeInfo: action.payload,
      error: null,
    });
  },
  [GET_EMPLOYEE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      employeeInfo: null,
      error: action.payload,
    });
  },
  [OVERVIEW_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      companyOverview: null,
      error: null,
    });
  },
  [OVERVIEW_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyOverview: action.payload,
      error: null,
    });
  },
  [OVERVIEW_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      companyOverview: null,
      error: action.payload,
    });
  },
  [INVITE_COMPANY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      inviteCompanyError: null,
    });
  },
  [INVITE_COMPANY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      inviteCompanyError: null,
    });
  },
  [INVITE_COMPANY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      inviteCompanyError: action.payload,
    });
  },
  [UPDATE_COMPANY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [UPDATE_COMPANY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [UPDATE_COMPANY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [EXCHANGE_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      exchangeList: [],
      exchangeInfo: null,
      createExchangeSuccess: null,
      createExchangeError: null,
      updateExchangeSuccess: null,
      updateExchangeError: null,
      deleteExchangeSuccess: null,
      deleteExchangeError: null,
      error: null,
    });
  },
  [EXCHANGE_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      exchangeList: action.payload,
      error: null,
    });
  },
  [EXCHANGE_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      exchangeList: [],
      error: action.payload,
    });
  },
  [GET_EXCHANGE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      exchangeInfo: null,
      error: null,
    });
  },
  [GET_EXCHANGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      exchangeInfo: action.payload,
      error: null,
    });
  },
  [GET_EXCHANGE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      exchangeInfo: null,
      error: action.payload,
    });
  },
  [CREATE_EXCHANGE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      createExchangeSuccess: null,
      createExchangeError: null,
    });
  },
  [CREATE_EXCHANGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createExchangeSuccess: action.payload,
      createExchangeError: null,
    });
  },
  [CREATE_EXCHANGE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createExchangeSuccess: null,
      createExchangeError: action.payload,
    });
  },
  [UPDATE_EXCHANGE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      updateExchangeSuccess: null,
      updateExchangeError: null,
    });
  },
  [UPDATE_EXCHANGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateExchangeSuccess: action.payload,
      updateExchangeError: null,
    });
  },
  [UPDATE_EXCHANGE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateExchangeSuccess: null,
      updateExchangeError: action.payload,
    });
  },
  [DELETE_EXCHANGE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      deleteExchangeSuccess: null,
      deleteExchangeError: null,
    });
  },
  [DELETE_EXCHANGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deleteExchangeSuccess: action.payload,
      deleteExchangeError: null,
    });
  },
  [DELETE_EXCHANGE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deleteExchangeSuccess: null,
      deleteExchangeError: action.payload,
    });
  },
  [BACKOFFICE_DEDUCTION_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      deductionList: [],
      deductionInfo: null,
      createDeductionSuccess: null,
      createDeductionError: null,
      updateDeductionSuccess: null,
      updateDeductionError: null,
      deleteDeductionSuccess: null,
      deleteDeductionError: null,
      error: null,
    });
  },
  [BACKOFFICE_DEDUCTION_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deductionList: action.payload,
      error: null,
    });
  },
  [BACKOFFICE_DEDUCTION_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deductionList: [],
      error: action.payload,
    });
  },
  [GET_DEDUCTION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      deductionInfo: null,
      error: null,
    });
  },
  [GET_DEDUCTION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deductionInfo: action.payload,
      error: null,
    });
  },
  [GET_DEDUCTION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deductionInfo: null,
      error: action.payload,
    });
  },
  [CREATE_DEDUCTION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      createDeductionSuccess: null,
      createDeductionError: null,
    });
  },
  [CREATE_DEDUCTION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createDeductionSuccess: action.payload,
      createDeductionError: null,
    });
  },
  [CREATE_DEDUCTION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createDeductionSuccess: null,
      createDeductionError: action.payload,
    });
  },
  [UPDATE_DEDUCTION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      updateDeductionSuccess: null,
      updateDeductionError: null,
    });
  },
  [UPDATE_DEDUCTION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateDeductionSuccess: action.payload,
      updateDeductionError: null,
    });
  },
  [UPDATE_DEDUCTION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateDeductionSuccess: null,
      updateDeductionError: action.payload,
    });
  },
  [DELETE_DEDUCTION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      deleteDeductionSuccess: null,
      deleteDeductionError: null,
    });
  },
  [DELETE_DEDUCTION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deleteDeductionSuccess: action.payload,
      deleteDeductionError: null,
    });
  },
  [DELETE_DEDUCTION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      deleteDeductionSuccess: null,
      deleteDeductionError: action.payload,
    });
  },
  [OVERVIEW_FILTER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      overviewFilters: action.payload,
    });
  },
  [TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      withdrawalRequestsFilters: action.payload,
    });
  },
  [TRANSACTIONS_OVERTIME_REQUESTS_FILTER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      overtimeRequestsFilters: action.payload,
    });
  },
  [TRANSACTION_HISTORY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      transactionHistory: [],
      error: null,
    });
  },
  [TRANSACTION_HISTORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      transactionHistory: action.payload,
      error: null,
    });
  },
  [TRANSACTION_HISTORY_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      transactionHistory: [],
      error: action.payload,
    });
  },
  [BACKOFFICE_COUNTRY_FEES_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      countryFeesList: [],
      createCountryFeesSuccess: null,
      createCountryFeesError: null,
      updateCountryFeesSuccess: null,
      updateCountryFeesError: null,
      error: null,
    });
  },
  [BACKOFFICE_COUNTRY_FEES_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      countryFeesList: action.payload,
      error: null,
    });
  },
  [BACKOFFICE_COUNTRY_FEES_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      countryFeesList: [],
      error: action.payload,
    });
  },
  [CREATE_COUNTRY_FEES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      createCountryFeesSuccess: null,
      createCountryFeesError: null,
    });
  },
  [CREATE_COUNTRY_FEES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createCountryFeesSuccess: action.payload,
      createCountryFeesError: null,
    });
  },
  [CREATE_COUNTRY_FEES_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      createCountryFeesSuccess: null,
      createCountryFeesError: action.payload,
    });
  },
  [UPDATE_COUNTRY_FEES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      updateCountryFeesSuccess: null,
      updateCountryFeesError: null,
    });
  },
  [UPDATE_COUNTRY_FEES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateCountryFeesSuccess: action.payload,
      updateCountryFeesError: null,
    });
  },
  [UPDATE_COUNTRY_FEES_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      updateCountryFeesSuccess: null,
      updateCountryFeesError: action.payload,
    });
  },
});
