import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import CommonButton from "../../components/button";
import CommonFormDropDown from "../../components/formdropdown";
import theme from "../../theme";

const useStyles = makeStyles((innertheme) => ({
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px 30px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "70%",
    [innertheme.breakpoints.up("sm")]: {
      width: "400px",
      padding: "40px 60px",
    },
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
}));

function CompanyRegistrationStep2({
  getFeesSettingsMethod,
  setStep,
  salaryDays,
  setSalaryDays,
  // daysDesignated,
  // setDaysDesignated,
  timeZone,
  setTimeZone,
  currency,
  setCurrency,
  country,
  setCountry,
  workingDays,
  setWorkingDays,
  isValidated,
  setIsValidated,
  errors,
}) {
  const classes = useStyles();

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const onBoardCompanyReducer = useSelector((state) => {
    return state.onBoardCompanyReducer;
  });

  useEffect(() => {
    if (errors && errors.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [errors]);

  useEffect(() => {
    if (country?.value) getFeesSettingsMethod(country?.value);
  }, [country]);

  return (
    <div className={classes.formContainer}>
      <div className={classes.formTitle}>Set up your working days</div>
      {errors && errors.length ? (
        <div className={"error-container"}>
          <div>You have the following errors, please fix them.</div>
          <br />
          {errors.map((each, index) => (
            <div key={index}>
              {each === "Resource not found."
                ? "Token does not exist or has expired."
                : each}
            </div>
          ))}
        </div>
      ) : null}
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsValidated(true);
          if (
            salaryDays &&
            ((salaryDays?.value === "BUSINESS-DAYS" && workingDays) ||
              salaryDays?.value !== "BUSINESS-DAYS") &&
            // daysDesignated &&
            timeZone &&
            currency &&
            country
          ) {
            setStep(3);
            setIsValidated(false);
          }
        }}
      >
        <CommonFormDropDown
          name="Salary Calculation Days"
          label="Salary Calculation Days"
          placeholder={"Select Salary Calculation Days"}
          options={[
            { value: "CALENDAR-DAYS", label: "Calendar Days" },
            { value: "BUSINESS-DAYS", label: "Working Days" },
          ]}
          value={salaryDays}
          onChange={(val) => {
            setSalaryDays(val);
            setWorkingDays(null);
          }}
          error={isValidated && !salaryDays}
          helperText={
            isValidated &&
            !salaryDays &&
            "Salary Calculation Days may not be blank."
          }
        />

        {salaryDays?.value === "BUSINESS-DAYS" ? (
          <CommonFormDropDown
            name="Working Days"
            label="Working Days"
            placeholder={"Select Working Days"}
            options={[
              { value: "SUN", label: "Sunday" },
              { value: "MON", label: "Monday" },
              { value: "TUE", label: "Tuesday" },
              { value: "WED", label: "Wednesday" },
              { value: "THU", label: "Thursday" },
              { value: "FRI", label: "Friday" },
              { value: "SAT", label: "Saturday" },
            ]}
            value={workingDays}
            onChange={(val) => {
              setWorkingDays(val);
            }}
            isMulti={true}
            error={
              isValidated &&
              (!workingDays || (workingDays && !workingDays.length))
            }
            helperText={
              isValidated &&
              (!workingDays || (workingDays && !workingDays.length)) &&
              "Working Days may not be blank."
            }
          />
        ) : null}

        {/* <CommonInput
            label="Days designated as Work Days"
            placeholder="Enter Days designated as Work Days"
            type="number"
            value={daysDesignated}
            onChange={(e) => {
              setDaysDesignated(e.target.value);
            }}
            error={isValidated && !daysDesignated}
            helperText={
              isValidated &&
              !daysDesignated &&
              "Days designated as Work Days may not be blank."
            }
          /> */}

        <CommonFormDropDown
          name="Timezone"
          label="Timezone"
          placeholder={"Select Timezone"}
          options={
            loginReducer?.timeZoneOptions?.length
              ? loginReducer.timeZoneOptions.map((each) => {
                  return { label: each.name, value: each.id };
                })
              : []
          }
          value={timeZone}
          onChange={(val) => {
            setTimeZone(val);
          }}
          error={isValidated && !timeZone}
          helperText={isValidated && !timeZone && "Timezone may not be blank."}
        />
        <CommonFormDropDown
          name="Country"
          label="Country"
          placeholder={"Select Country"}
          options={
            loginReducer?.countryOptions?.length
              ? loginReducer.countryOptions.map((each) => {
                  return { label: each.name, value: each.id };
                })
              : []
          }
          value={country}
          onChange={(val) => {
            setCountry(val);
            setCurrency(null);
          }}
          error={isValidated && !country}
          helperText={isValidated && !country && "Country may not be blank."}
        />
        <CommonFormDropDown
          name="Currency"
          label="Currency"
          placeholder={"Select Currency"}
          options={
            onBoardCompanyReducer?.feesSettings?.length
              ? onBoardCompanyReducer.feesSettings.map((each) => {
                  return {
                    id: each.id,
                    country: each.country,
                    label: loginReducer.currencyOptions.find(
                      (rec) => rec.id === each.currency
                    )?.name,
                    value: each.currency,
                  };
                })
              : []
          }
          value={currency}
          onChange={(val) => {
            setCurrency(val);
          }}
          error={isValidated && !currency}
          helperText={isValidated && !currency && "Currency may not be blank."}
          isDisabled={!country?.value}
        />
        <CommonButton
          type="submit"
          text="Next"
          stylevariant="primary"
          style={{ marginTop: "30px" }}
        />
        <CommonButton
          type="button"
          text="Back"
          stylevariant="secondary2"
          style={{ marginTop: "10px", border: "none" }}
          onClick={() => {
            setStep(1);
          }}
        />
      </form>
    </div>
  );
}

export default CompanyRegistrationStep2;
