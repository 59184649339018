import {
    GET_REPORTS_LIST_ACTION,
    DOWNLOAD_REPORTS_LIST_ACTION,
    DOWNLOAD_REPORTS_LIST_START,
    DOWNLOAD_REPORTS_LIST_COMPLETE
} from "./types";

export const getReportsListAction = (filters) => ({
    type: GET_REPORTS_LIST_ACTION,
    payload: { filters }
})

export const downloadReportsListAction = (data, fileName) => ({
    type: DOWNLOAD_REPORTS_LIST_ACTION,
    payload: { data, fileName },
});

export const downloadReportsListStart = () => ({
    type: DOWNLOAD_REPORTS_LIST_START,
});

export const downloadReportsListComplete = () => ({
    type: DOWNLOAD_REPORTS_LIST_COMPLETE,
});