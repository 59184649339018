import { fork } from "redux-saga/effects";

import watchLoginAsync from "../containers/login/saga";
import watchDashboardAsync from "../containers/dashboard/saga";
import watchCompanyDashboardAsync from "../containers/companyDashboard/saga";
import watchEmployeeRegistrationAsync from "../containers/employeeConfirmRegistration/saga";
import watchOnboradCompanyAsync from "../containers/companyRegistration/saga";
import watchReportsAsync from "../containers/reports/saga";

export function* rootSaga() {
  yield fork(watchLoginAsync);
  yield fork(watchDashboardAsync);
  yield fork(watchCompanyDashboardAsync);
  yield fork(watchEmployeeRegistrationAsync);
  yield fork(watchOnboradCompanyAsync);
  yield fork(watchReportsAsync);
}
